import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon } from '../../models/icon';
import { Style } from '../../models/style';

@Component({
  selector: 'gep-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input()
  iconType: Icon | null = null;

  @Input()
  style: Style = Style.Standard;

  @Input()
  size: 'normal' | 'small' = 'normal';

  constructor() {}
}
