import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ProductConfigModel } from 'src/app/models/product-config.model';
import { TaskService } from 'src/app/services/task.service';
import { ProductKpiModel } from 'src/app/models/product-kpi.model';
import { TaskModel } from 'src/app/models/task.model';
import { KpiService } from '../../services/kpi.service';
import { MsalService } from '@azure/msal-angular';
import { DeliveryYearService } from 'src/app/services/delivery-year.service';
import { BehaviorSubject, config, mergeAll, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ProductService } from '../../services/product.service';
import { year } from 'ag-grid-enterprise/dist/lib/ag-charts-community/util/time';

@Component({
  selector: 'gep-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  productConfig$: Observable<string[]> = this.getProductConfig$();
  deliveryYears: Observable<string[]> = this.getDeliveryYears();
  openTasks: TaskModel[] = [];

  selectedYear: number = 2021;
  selectedProductKey: string = 'BASIC';
  lastKpiUpdate: Date = new Date('01.01.1970');

  selectedProductKey$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'BASIC'
  );

  _destroy$: Subject<void> = new Subject();

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private productService: ProductService,
    private kpiService: KpiService,
    private tasksService: TaskService,
    private authService: MsalService,
    private deliveryYearService: DeliveryYearService
  ) {}

  ngOnInit(): void {
    this.getOpenTask();
  }

  productKpis$ = this.selectedProductKey$.pipe(
    map((productKey: string) => this.getProductKpis$(productKey)),
    mergeAll()
  );

  onYearSelected(value: any) {
    if (Number(value)) {
      this.selectedYear = Number(value);
    }
  }

  onProductSelected(value: any) {
    this.selectedProductKey$.next(value);
  }

  getProductConfig$(): Observable<string[]> {
    return this.productService.getProductConfigurations$().pipe(
      map((productConfigModels: ProductConfigModel[]) => {
        return productConfigModels.map(config => config.key);
      })
    );
  }

  getProductKpis$(productKey: string): Observable<ProductKpiModel[]> {
    return this.kpiService
      .getProductKpis$(productKey)
      .pipe(tap(productKpiModels => this.calcLastKpiUpdate(productKpiModels)));
  }

  calcLastKpiUpdate(productKpis: ProductKpiModel[]) {
    productKpis.forEach(k => {
      const currentDate = new Date(k.kpi_date);
      if (currentDate.getTime() > this.lastKpiUpdate.getTime()) {
        this.lastKpiUpdate = currentDate;
      }
    });
  }

  getOpenTask(): void {
    this.tasksService
      .getTasks()
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        this.openTasks = result.data;
      });
  }

  hasRole(): boolean {
    const allowedRoles = ['SYSADMIN', 'PRODUCER', 'SALES', 'EXTERNAL_PARTY'];
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    const roles = activeAccount?.idTokenClaims?.roles;
    if (roles !== undefined) {
      return allowedRoles
        .map(role => allowedRoles.includes(role))
        .some(item => item);
    }

    return false;
  }

  getDeliveryYears(): Observable<string[]> {
    return this.deliveryYearService
      .getAllDeliveryYears()
      .pipe(map((years: number[]) => years.map(year => year.toString())));
  }

  stringOptionValueTransformer(option: number) {
    return option.toString();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
