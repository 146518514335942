import { Injectable, OnDestroy } from '@angular/core';
import { DistinctPropertiesService } from '../distinct-properties.service';
import { FilterCreationService } from './filter-creation.service';
import { Subscription } from 'rxjs';
import { GenericFilterService } from './generic-filter.service';
import { IFilterService } from './filter-service.interface';
import { DealSearchFilter } from '../../modules/deal-search/model/deal-search-filter';

@Injectable({
  providedIn: 'root',
})
export class InvalidationsFilterService
  extends GenericFilterService
  implements IFilterService, OnDestroy
{
  subscription?: Subscription;

  preselectedFilters: DealSearchFilter[] = [
    {
      field: 'year',
      value: new Date().getFullYear().toString(),
      operator: 'eq',
    },
    {
      field: 'product_key',
      value: 'BASIC',
      operator: 'eq',
    },
    {
      field: 'vg',
      value: 'EDG',
      operator: 'eq',
    },
    {
      field: 'quantity_delivery_confirmed',
      operator: 'is',
    },
  ];

  constructor(
    protected filterCreationService: FilterCreationService,
    distinctPropertiesService: DistinctPropertiesService
  ) {
    super(filterCreationService);
    this.createFilterForPreselection();

    this.subscription = distinctPropertiesService
      .getDistinctPropertiesForInvalidations()
      .subscribe(distinctProperties => {
        this.filterConfig = [
          {
            type: 'select',
            fieldName: 'year',
            fieldNameAsLabel: true,
            possibleValues: distinctProperties.years,
            value: new Date().getFullYear().toString(),
            resetForbidden: true,
          },
          {
            type: 'select',
            fieldName: 'product_key',
            fieldNameAsLabel: true,
            possibleValues: distinctProperties.products,
            value: 'BASIC',
            resetForbidden: true,
          },
          {
            type: 'select',
            fieldName: 'vg',
            possibleValues: distinctProperties.vgs,
            fieldNameAsLabel: true,
            value: 'EDG',
            resetForbidden: true,
          },

          {
            type: 'headline',
            fieldName: '',
            label: 'Optionale Filter',
            resetForbidden: true,
          },
          {
            type: 'select',
            fieldName: 'segment',
            possibleValues: distinctProperties.segments,
            fieldNameAsLabel: true,
          },
          {
            type: 'range',
            fieldName: 'quantity',
            min: Math.floor(distinctProperties.quantity?.min!),
            max: Math.ceil(distinctProperties.quantity?.max!),
            firstValue: Math.floor(distinctProperties.quantity?.min!),
            secondValue: Math.ceil(distinctProperties.quantity?.max!),
            unit: 'MWh',
            fieldNameAsLabel: true,
          },
          {
            type: 'range',
            fieldName: 'price',
            min: Math.floor(distinctProperties.price?.min!),
            max: Math.ceil(distinctProperties.price?.max!),
            firstValue: Math.floor(distinctProperties.price?.min!),
            secondValue: Math.ceil(distinctProperties.price?.max!),
            unit: '€',
            fieldNameAsLabel: true,
          },
          {
            type: 'checkbox-list-foldable',
            fieldName: 'counterpart_company',
            possibleValues: distinctProperties.counterpart_companies,
            fieldNameAsLabel: true,
            numberOfDefaultDisplayed: 3,
          },
          {
            type: 'yes-no',
            fieldName: 'quantity_delivery_confirmed',
            fieldNameAsLabel: true,
            value: true,
          },
          {
            type: 'yes-no',
            fieldName: 'quantity_cancelled',
            fieldNameAsLabel: true,
            disabled: true,
            value: false,
            resetForbidden: true,
          },
          {
            type: 'yes-no',
            fieldName: 'redistributor',
            fieldNameAsLabel: true,
          },
          { type: 'yes-no', fieldName: 'comment', fieldNameAsLabel: true },
          {
            type: 'yes-no',
            fieldName: 'statement_cancellation',
            fieldNameAsLabel: true,
          },
          {
            type: 'select',
            fieldName: 'energy_source',
            possibleValues: distinctProperties.energy_sources,
            fieldNameAsLabel: true,
          },
          {
            type: 'select',
            fieldName: 'system_age',
            possibleValues: distinctProperties.system_ages,
            fieldNameAsLabel: true,
          },
          {
            type: 'multi-select',
            fieldName: 'region',
            possibleValues: distinctProperties.regions!.map(x =>
              x.replaceAll(',', ';')
            ),
            fieldNameAsLabel: true,
          },
        ];
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
