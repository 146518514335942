import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'gep-welcome-message',
  templateUrl: './welcome_message.component.html',
  styleUrls: ['./welcome_message.component.scss'],
})
export class WelcomeMessageComponent implements OnInit {
  constructor(protected userService: UserService) {}

  profile$?: Observable<UserModel>;

  ngOnInit() {
    this.profile$ = this.userService.profile$;
  }
}
