import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeLeft',
})
export class TimeLeftPipe implements PipeTransform {
  transform(value: Date, ...args: unknown[]): unknown {
    const date = new Date(value);

    const diffInMillis = date.getTime() - Date.now();
    if (diffInMillis < 0) return args.length > 0 ? args[0] : '';
    let diff = Math.abs(diffInMillis) / 1000;
    const days = Math.floor(diff / 86400);
    diff -= days * 86400;
    const hours = Math.floor(diff / 3600) % 24;
    diff -= hours * 3600;
    const minutes = Math.floor(diff / 60) % 60;
    let difference = '';
    if (days > 0) {
      difference += `${days}d `;
    }
    difference += `${hours}h `;
    difference += `${minutes}m`;

    return difference;
  }
}
