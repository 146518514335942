import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError, filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CustomHttpError } from '../models/custom-http-error.model';
import { UserService } from './user.service';
import { Role } from '../models/user.model';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
    private readonly userService: UserService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      filter(event => event instanceof HttpResponse),
      catchError((err: CustomHttpError) => {
        const correlationId = err.headers.get('x-request-id');

        let errorMessage = this.translateService.instant(
          'globalError.message',
          { correlationId }
        );
        let errorTitle = this.translateService.instant('globalError.title');

        return this.userService.hasRole(Role.EXTERNAL_PARTY).pipe(
          map(isExternalParty => {
            if (!isExternalParty && err?.error?.detail) {
              errorTitle = err.status + ' - ' + err.statusText;
              if (Array.isArray(err.error.detail)) {
                errorMessage = err.error.detail[0]?.msg;
              } else {
                errorMessage = err.error.detail;
              }
            }

            this.toastrService.error(errorMessage, errorTitle, {
              progressAnimation: 'decreasing',
              tapToDismiss: false,
              progressBar: true,
              closeButton: true,
              timeOut: 10_000,
            });
          }),
          switchMap(_ => this.handleError(err))
        );
      })
    );
  }

  private stringifyErrors(err: any, space: string) {
    const plainObject: any = {};
    Object.getOwnPropertyNames(err).forEach(function (key: string) {
      plainObject[key] = err[key];
    });
    return JSON.stringify(plainObject, undefined, space);
  }

  private handleError(err: CustomHttpError) {
    const ignoredErrorStatusCodes = [400, 500];
    if (err && err.status && ignoredErrorStatusCodes.includes(err.status)) {
      return of();
    }
    return throwError(() => this.stringifyErrors(err, '\t'));
  }
}
