<eon-ui-input
  #eonUiInput
  [autocomplete]="autocomplete"
  [autocomplete-start]="autocompleteStart"
  [autocomplete-terms]="autocompleteTerms.join(',')"
  [clear-input-icon]="clearInputIcon"
  [cols]="cols"
  [disabled]="disabled"
  [focus-style]="focusStyle"
  [attr.info]="info"
  [attr.info-message]="infoMessage"
  [label]="label"
  [max]="max"
  [message-icon]="messageIcon"
  [min]="min"
  [name]="name"
  [pattern]="pattern"
  [placeholder]="placeholder"
  [preloader]="preloader"
  [read-only]="readOnly"
  [required]="required"
  [rows]="rows"
  [scheme]="scheme"
  [size]="size"
  [success]="success"
  [success-message]="successMessage"
  [textarea]="textarea"
  [thousand-separator]="thousandSeparator"
  [trailingIcon]="trailingIcon"
  [trailingIconName]="trailingIconName"
  [type]="type"
  [value]="value"
  custom-required-message="This field is required."
  [invalid]="invalid"
  (autocompleteSelected)="onAutocompleteSelected($event)"
  (iconClick)="onIconClick($event)"
  (inputBlur)="onInputBlur($event)"
  (inputFocus)="onInputFocus($event)"
  (valueChanged)="onValueChanged($event)"
>
</eon-ui-input>
