<ng-container *ngIf="productConfigurations$ | async as productConfigurations">
  <fieldset *ngIf="innerForm$ | async as form" [formGroup]="form">
    <div>
      <gep-dropdown
        formControlName="productConfig"
        [optionValueTransformer]="productConfigTransformer"
        [optionLabelTransformer]="productConfigTransformer"
        [options]="productConfigurations"
        [label]="'product_key' | translate"
      ></gep-dropdown>
    </div>
    <div>
      <gep-multi-select-dropdown
        formControlName="region"
        [optionValueTransformer]="identityTransformer"
        [optionLabelTransformer]="identityTransformer"
        [options]="(regions$ | async)!"
        [label]="'region' | translate"
      ></gep-multi-select-dropdown>
      <gep-dropdown
        formControlName="system_age"
        [optionLabelTransformer]="identityTransformer"
        [optionValueTransformer]="identityTransformer"
        [label]="'system_age' | translate"
        [options]="(systemAges$ | async)!"
      ></gep-dropdown>
    </div>
    <div>
      <gep-dropdown
        formControlName="energy_source"
        [optionValueTransformer]="identityTransformer"
        [optionLabelTransformer]="identityTransformer"
        [label]="'energy_source' | translate"
        [options]="(energySources$ | async)!"
      ></gep-dropdown>
    </div>
  </fieldset>
</ng-container>
