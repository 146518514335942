import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { map, Observable } from 'rxjs';
import { Role, UserModel } from '../models/user.model';

const GRAPH_ENDPOINT =
  'https://graph.microsoft.com/v1.0/me?$select=givenName,displayName,surname,userPrincipalName,id,mail,otherMails';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(private http: HttpClient, private authService: MsalService) {}

  public getProfile$(): Observable<UserModel> {
    return this.http.get<UserModel>(GRAPH_ENDPOINT).pipe(
      map(response => {
        return {
          ...response,
          roles: this.getRoles(),
        };
      })
    );
  }

  /**
   * Check for the currently active account. The call to `getActiveAccount` can return null,
   * so as a safety measure we check for the currently active user amongst all the accounts.
   * The first is the current account.
   */
  public getRoles(): Role[] {
    const activeAccount = this.authService.instance.getActiveAccount();
    const activeAccountRoles = activeAccount?.idTokenClaims?.roles;

    if (activeAccountRoles) {
      return activeAccountRoles.map(roleString => roleString as Role);
    }

    const allActiveAccounts = this.authService.instance.getAllAccounts();
    if (allActiveAccounts.length > 0) {
      const currentAccount = allActiveAccounts[0];
      this.authService.instance.setActiveAccount(currentAccount);
      if (currentAccount.idTokenClaims && currentAccount.idTokenClaims.roles) {
        return currentAccount.idTokenClaims.roles.map(
          roleString => roleString as Role
        );
      }
    }
    return [];
  }
}
