<div
  *ngIf="
    options.length <= numberOfDefaultDisplayed;
    else collapsableCheckboxList
  "
>
  <eon-ui-checkbox
    ngDefaultControl
    *ngFor="let option of options"
    [attr.label]="option"
    [attr.disabled]="disabled"
    [attr.size]="size"
    (valueChanged)="onValueChange($event, option)"
  ></eon-ui-checkbox>
</div>

<ng-template #collapsableCheckboxList>
  <div *ngIf="isFoldedOut; else notFoldedOut" class="d-flex flex-column">
    <eon-ui-checkbox
      ngDefaultControl
      *ngFor="let option of options"
      [attr.label]="option"
      [attr.size]="size"
      [attr.disabled]="disabled"
      (valueChanged)="onValueChange($event, option)"
    ></eon-ui-checkbox>
    <span class="link" (click)="toggleFoldOut()"
      >{{ 'foldIn' | translate }} ↑</span
    >
  </div>

  <ng-template #notFoldedOut>
    <div class="d-flex flex-column">
      <eon-ui-checkbox
        ngDefaultControl
        *ngFor="
          let f of [].constructor(numberOfDefaultDisplayed);
          let i = index
        "
        [attr.label]="options[i]"
        [attr.size]="size"
        [attr.disabled]="disabled"
        (valueChanged)="onValueChange($event, options[i])"
      ></eon-ui-checkbox>
      <span class="link" (click)="toggleFoldOut()"
        >{{ options.length - numberOfDefaultDisplayed }}
        {{ 'more' | translate }} ↓</span
      >
    </div>
  </ng-template>
</ng-template>
