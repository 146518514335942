import { Component } from '@angular/core';

@Component({
  selector: 'gep-memphis-import-page',
  templateUrl: './memphis-import-page.component.html',
  styleUrls: ['./memphis-import-page.component.scss'],
})
export class MemphisImportPageComponent {
  constructor() {}
}
