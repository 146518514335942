import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Role, UserModel } from '../models/user.model';
import { UserProfileService } from './user-profile.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly profile$?: Observable<UserModel>;
  readonly email$ = this.profile$?.pipe(
    map(profile => {
      if (profile.mail) return profile.mail;
      if (profile.otherMails && profile.otherMails.length > 0)
        return profile.otherMails[profile.otherMails.length - 1];
      return '';
    })
  );
  private readonly onLogout$ = new Subject<void>();
  private readonly logoutBehaviour = this.onLogout$
    .pipe(
      switchMap(() => {
        return this.authService.logoutRedirect({
          // If in iframe, dont perform redirect to AAD
          onRedirectNavigate: () => {
            return !BrowserUtils.isInIframe();
          },
        });
      }),
      take(1)
    )
    .subscribe();

  constructor(
    private userProfileService: UserProfileService,
    private authService: MsalService
  ) {
    this.profile$ = this.userProfileService.getProfile$().pipe(shareReplay());
  }

  logout() {
    this.onLogout$.next();
  }

  hasRole(role: Role): Observable<boolean> {
    return this.profile$!.pipe(
      map(profile => {
        if (!profile.roles) {
          return false;
        }
        return profile.roles.includes(role);
      })
    );
  }
}
