<eon-ui-navigation-meta slot="meta">
  <eon-ui-navigation-column contentAlignment="center" contentAlignmentMobile="center" grow="true" growMobile="true">
    <eon-ui-navigation-meta-link
      [text]="'editDeal.editDeal' | translate"
      [routerLink]="'/admin/edit-deal'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/edit-deal'"
    ></eon-ui-navigation-meta-link>
  </eon-ui-navigation-column>
</eon-ui-navigation-meta>
<!--
<section>
  <div class="heading">
    <eon-ui-headline
      text="{{'editDeal.editDeal' | translate}}"
      size="h4"
      scheme="darkgrey"
    ></eon-ui-headline>
  </div>
</section>
-->
