import { CanMatchFn } from '@angular/router';
import { inject } from '@angular/core';
import { FeatureFlagsFacadeService } from './feature-flag-facade.service';
import { FeatureFlag } from './feature-flag-config';

export function featureFlagGuard(featureFlag: FeatureFlag): CanMatchFn {
  return () => {
    const featureFlagsFacade = inject(FeatureFlagsFacadeService);
    return featureFlagsFacade.featureFlagEnabled(featureFlag);
  };
}
