import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize } from '../../../gep-controls/models/button-size';

@Component({
  selector: 'gep-date-range',
  templateUrl: './date-range.component.html',
})
export class DateRangeComponent {
  @Input() startDate?: Date;
  @Input() endDate?: Date;
  @Input() size: ButtonSize = ButtonSize.Small;
  @Input() disabled?: boolean;

  @Output() valueSelected = new EventEmitter<{
    startDate?: Date;
    endDate?: Date;
  }>();

  onSelected(type: 'start' | 'end', value: Date) {
    if (type === 'start') {
      this.startDate = value;
    } else if (type === 'end') {
      this.endDate = value;
    }

    this.valueSelected.emit({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }
}
