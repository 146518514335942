import { Component, Input } from '@angular/core';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
} from 'ag-grid-community';
import { AgGridHeaderComponent } from '../ag-grid-header/ag-grid-header.component';
import { Observable } from 'rxjs';
import { AG_GRID_LOCALE_DE } from '../localization/locale.de';
import { AG_GRID_LOCALE_EN } from '../localization/locale.en';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gep-ag-grid-table',
  templateUrl: './ag-grid-table.component.html',
  styleUrls: ['./ag-grid-table.component.scss'],
})
export class AgGridTableComponent {
  @Input()
  gridOptions: GridOptions = {
    rowGroupPanelShow: 'always',
    groupDisplayType: 'singleColumn',
    enableRangeSelection: true,
    enableCharts: true,
    animateRows: true,
    rowModelType: 'clientSide',
    getRowId: params => params.data.id!,
  };

  @Input()
  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true,
        },
      },
      {
        id: 'filters',
        labelKey: 'filters',
        labelDefault: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
    position: 'left',
  };

  @Input()
  defaultColDefConfig: ColDef = {
    width: 150,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    enableRowGroup: true,
    sortable: true,
    suppressMenu: true,
  };

  @Input()
  components: {
    [p: string]: any;
  } = {
    agColumnHeader: AgGridHeaderComponent,
  };

  @Input()
  rowData$?: Observable<any>;

  @Input()
  colDefs: ColDef[] = [];

  @Input()
  pagination: boolean = true;

  @Input()
  paginationPageSizeSelector: number[] = [20, 50, 100, 200, 500, 1000];

  @Input()
  defaultPageSize: number = 200;

  @Input()
  rowSelection: 'multiple' | 'single' = 'multiple';

  @Input()
  showRowGroupPanel: 'onlyWhenGrouping' | 'never' | 'always' =
    'onlyWhenGrouping';

  localeText: { [key: string]: string } | undefined = AG_GRID_LOCALE_DE; // default language is german

  constructor(translate: TranslateService) {
    let selectedLanguage = 'de';
    if (translate.getBrowserLang() !== undefined) {
      selectedLanguage = translate.getBrowserLang()!;
    }

    if (selectedLanguage === 'en') {
      this.localeText = AG_GRID_LOCALE_EN;
    }
  }

  public api!: GridApi;
  onGridReady = (event: GridReadyEvent) => {
    this.api = event.api;
  };
}
