import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { HealthStatus } from '../../../models/Health-status.model';
import { DashboardStatusService } from '../../../services/health/dashboardstatus.service';

@Component({
  selector: 'gep-health-status',
  templateUrl: './health-status.component.html',
  styleUrls: ['./health-status.component.scss'],
})
export class HealthStatusComponent {
  healthStatusDropdownIsOpen: boolean = false;

  healthStatus$: Observable<HealthStatus> =
    this.healthService.getTheStatusOfHealth();

  constructor(private healthService: DashboardStatusService) {}

  toggleHealthStatusDropdown() {
    this.healthStatusDropdownIsOpen = !this.healthStatusDropdownIsOpen;
  }
}
