import { EnquiryFormModel } from '../modules/enquiry/models/enquiry-form-model';

export class OptionTransformers {
  static transformStringOptions(option: string) {
    return option;
  }

  static transformEnquiryFormModelToContactPerson(
    prefillOption: Partial<EnquiryFormModel>
  ): string {
    return prefillOption.contact_person!;
  }
}
