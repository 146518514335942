<gep-pagination-table-wrapper
  [dataRetriever]="dataRetriever"
  [selectedColumns]="selectedColumns"
  [availableColumns]="availableColumns"
  [defaultOrderBy]="defaultOrderBy"
>
</gep-pagination-table-wrapper>
<ng-template #businessPartnerTemplate let-column="column" let-row="row">
  <p>{{ row[column.name].name }}</p>
  <p>{{ row[column.name].post_code }}</p>
  <p>{{ row[column.name].city }}</p>
  <p>{{ row[column.name].street }}</p>
  <p>{{ row[column.name].house_number }}</p>
</ng-template>
