import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gep-settings-tab-header',
  templateUrl: './settings-tab-header.component.html',
  styleUrls: ['./settings-tab-header.component.scss'],
})
export class SettingsTabHeaderComponent implements OnInit {
  route: string = '';

  constructor(protected router: Router) {}

  ngOnInit(): void {
    this.route = this.router.url;
  }
}
