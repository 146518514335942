import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenStringReadMore',
})
export class ShortenStringReadMorePipe implements PipeTransform {
  transform(value: string, numberOfCharacters: number): string {
    const characters = [...value];

    if (characters.length <= numberOfCharacters) return value;

    return characters.splice(0, numberOfCharacters).join('') + '...';
  }
}
