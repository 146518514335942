import { Injectable } from '@angular/core';
import { DealModel, Status } from '../../../models/deal.model';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { EnquiryFormModel } from '../models/enquiry-form-model';
import { DeliveryFormModel } from '../models/delivery-form-model';
import { ProductConfigModel } from '../../../models/product-config.model';

@Injectable()
export class EnquiryFormBuilderService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getDealForm(
    existingDeal?: DealModel,
    productConfigurations?: ProductConfigModel[]
  ): UntypedFormGroup {
    if (
      !!existingDeal &&
      (!productConfigurations || productConfigurations.length === 0)
    ) {
      throw new Error('Product configurations are mandatory');
    }

    const enquiryFormModel: EnquiryFormModel = !!existingDeal
      ? this.existingDealToFormModel(existingDeal, productConfigurations!)
      : this.getDefaultFormModel();

    const enquiryForm = this.getFormFromFormModel(
      enquiryFormModel,
      existingDeal?.status
    );

    return enquiryForm;
  }

  getDeals(form: UntypedFormGroup): DealModel[] {
    const rawValue: EnquiryFormModel = form.getRawValue();

    return rawValue.deliveries.map(
      ({ deal_id, delivery_year: year, quantity, price }) => {
        return {
          ...this.getStaticDealDataFromEnquiryFormModel(rawValue),
          deal_id: deal_id!,
          year: year!,
          quantity: quantity!,
          price: price!,
        };
      }
    );
  }

  public disableFieldsForExistingDeal(
    form: UntypedFormGroup,
    status?: Status
  ): UntypedFormGroup {
    if (status === Status.BINDING) {
      form.get('deal_type')!.disable();
    }

    return form;
  }

  getFormFromFormModel(
    formModel: EnquiryFormModel,
    status?: Status
  ): UntypedFormGroup {
    return this.formBuilder.group(
      {
        segment: [formModel.segment, [Validators.required]],
        redistributor: formModel.redistributor,
        binding_period_start: [
          formModel.binding_period_start,
          [Validators.required],
        ],
        binding_period_end: [
          formModel.binding_period_end,
          [Validators.required],
        ],
        address: [formModel.address, [Validators.required]],
        vg: [formModel.vg, [Validators.required]],
        productSelection: [formModel.productSelection, [Validators.required]],
        deal_type: formModel.deal_type,
        contact_person: [formModel.contact_person, [Validators.required]],
        customer: [formModel.customer, [Validators.required]],
        comment: [formModel.comment],
        deliveries: this.getDeliveriesFormArray(formModel.deliveries, status),
        statement_cancellation: formModel.statement_cancellation,
      },
      {
        validators: this.endAfterStartValidator(),
      }
    );
  }

  private getStaticDealDataFromEnquiryFormModel(
    enquiryFormModel: EnquiryFormModel
  ): DealModel {
    const { productSelection } = enquiryFormModel;

    return {
      address: enquiryFormModel.address,
      deal_type: enquiryFormModel.deal_type,
      vg: enquiryFormModel.vg,
      redistributor: enquiryFormModel.redistributor === 'true',
      binding_period_start: enquiryFormModel.binding_period_start!,
      binding_period_end: enquiryFormModel.binding_period_end!,
      comment: enquiryFormModel.comment,
      contact_person: enquiryFormModel.contact_person,
      customer: enquiryFormModel.customer,
      segment: enquiryFormModel.segment,
      product_key: productSelection.productConfig!.key,
      region: productSelection.productConfig!.region_updatable
        ? productSelection.region
        : productSelection.productConfig!.region_id!,
      energy_source: productSelection.productConfig!.energy_source_updatable
        ? productSelection.energy_source
        : productSelection.productConfig!.energy_source!,
      system_age: productSelection.productConfig!.system_age_updatable
        ? productSelection.system_age
        : productSelection.productConfig!.system_age!,
      statement_cancellation: enquiryFormModel.statement_cancellation,
    };
  }

  addEntryToDeliveries(enquiryForm: UntypedFormGroup): void {
    const defaultDeliveryModel: DeliveryFormModel = {
      quantity: null,
      quantityInTonns: null,
      delivery_year: null,
      deal_id: null,
      price: null,
    };

    const formGroup = this.formGroupFromDelivery(defaultDeliveryModel);

    (<UntypedFormArray>enquiryForm.get('deliveries')!).push(formGroup);
  }

  private getDeliveriesFormArray(
    deliveries: DeliveryFormModel[],
    status?: Status
  ): UntypedFormArray {
    const deliveryFormGroups = deliveries.map(delivery => {
      const deliveryFormGroup = this.formGroupFromDelivery(delivery);

      if (status === Status.BINDING) {
        deliveryFormGroup.get('delivery_year')!.disable();
        deliveryFormGroup.get('quantity')!.disable();
      }
      return deliveryFormGroup;
    });
    return this.formBuilder.array(
      deliveryFormGroups.length > 0
        ? deliveryFormGroups
        : [
            this.formGroupFromDelivery({
              deal_id: '',
              delivery_year: null,
              quantity: null,
              quantityInTonns: null,
              price: null,
            }),
          ]
    );
  }

  private formGroupFromDelivery(delivery: DeliveryFormModel): UntypedFormGroup {
    return this.formBuilder.group({
      deal_id: [
        delivery.deal_id,
        [Validators.required, Validators.minLength(3)],
      ],
      delivery_year: [delivery.delivery_year, [Validators.required]],
      quantity: [
        delivery.quantity,
        [Validators.required, Validators.pattern('^-?[0-9]+')],
      ],
      quantityInTonns: [delivery.quantityInTonns],
      price: [
        { value: null, disabled: true },
        [
          Validators.required,
          Validators.pattern(
            /^(?:(?:\d[,.]|\d*)\d|\d*(?:\.\d|,\d+)|\d+\.\d+|\d)$/
          ),
        ],
      ],
    });
  }

  private existingDealToFormModel(
    deal: DealModel,
    productConfigurations: ProductConfigModel[]
  ): EnquiryFormModel {
    let productConfigModel: ProductConfigModel | null;

    if (deal.product_key === 'INDIVIDUAL') {
      productConfigModel = {
        energy_source: deal.energy_source,
        key: deal.product_key!,
        system_age: deal.system_age,
        region_id: deal.region,
        energy_source_updatable: true,
        region_updatable: true,
        system_age_updatable: true,
      };
    } else {
      productConfigModel =
        productConfigurations.find(config => config.key === deal.product_key) ??
        null;
    }

    return {
      address: deal.address ?? '',
      productSelection: {
        region: deal.region!,
        energy_source: deal.energy_source!,
        system_age: deal.system_age!,
        productConfig: productConfigModel,
      },
      redistributor: deal.redistributor ? 'true' : 'false',
      segment: deal.segment!,
      vg: deal.vg!,
      deal_type: deal.deal_type ?? '',
      customer: deal.customer!,
      contact_person: deal.contact_person!,
      comment: deal.comment ?? '',
      binding_period_start: new Date(deal.binding_period_start!),
      binding_period_end: new Date(deal.binding_period_end!),
      deliveries: [
        {
          deal_id: deal.deal_id!,
          delivery_year: deal.year!,
          quantity: deal.quantityInMWh!,
          quantityInTonns: deal?.quantityInTonns!,
          price: deal.price!,
        },
      ],
      statement_cancellation: deal.statement_cancellation!,
    };
  }

  private getDefaultFormModel(): EnquiryFormModel {
    return {
      address: '',
      binding_period_end: null,
      binding_period_start: null,
      comment: '',
      contact_person: '',
      customer: '',
      deal_type: '',
      productSelection: {
        productConfig: null,
        energy_source: '',
        region: '',
        system_age: '',
      },
      segment: '',
      vg: '',
      redistributor: 'false',
      deliveries: [],
      statement_cancellation: false,
    };
  }

  private endAfterStartValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const bindingPeriodStart = form.get('binding_period_start')!.value;
      const bindingPeriodEnd = form.get('binding_period_end')!.value;

      if (!!bindingPeriodStart && !!bindingPeriodEnd) {
        const startTimestamp = (<Date>bindingPeriodStart).getTime();
        const endTimestamp = (<Date>bindingPeriodEnd).getTime();

        if (endTimestamp <= startTimestamp) {
          return { invalidDateTime: true };
        }

        return null;
      }

      return { invalidDateTime: true };
    };
  }
}
