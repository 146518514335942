import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { CounterpartService } from '../../services/counterpart.service';
import { CounterpartUserModel } from '../../models/counterpart-user.model';
import { DealModel } from '../../models/deal.model';
import { DealService } from '../../services/deal.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gep-manual-offer',
  templateUrl: './manual-offer.component.html',
  styleUrls: ['./manual-offer.component.scss'],
})
export class ManualOfferComponent implements OnInit, OnDestroy {
  constructor(
    private authService: MsalService,
    private counterPartService: CounterpartService,
    private dealService: DealService,
    private fb: UntypedFormBuilder
  ) {}

  @Input() priceCollection: boolean = false;
  @Input() deal?: DealModel;
  @Output() offerRecorded: EventEmitter<any> = new EventEmitter<any>();

  counterparts: CounterpartUserModel[] = [];
  showManualOffer: boolean = false;
  form!: UntypedFormGroup;

  _destroy$ = new Subject<void>();

  get userIsAllowedToManuallyAddOfferOnBehalf(): boolean {
    const claims: any =
      this.authService.instance.getActiveAccount()?.idTokenClaims;
    return (
      (claims.roles.includes('SYSADMIN') ||
        claims.roles.includes('GREENDESK')) &&
      this.priceCollection
    );
  }

  ngOnInit(): void {
    this.counterPartService
      .getCounterpartUsers()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.counterparts = value.sort(this.compare)));

    this.form = this.fb.group({
      val: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^(?:(?:\d[,.]|\d*)\d|\d*(?:\.\d|,\d+)|\d+\.\d+|\d)$/
          ),
        ],
      ],
      counterPart: [null, Validators.required],
    });

    this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe();
  }

  compare(a: CounterpartUserModel, b: CounterpartUserModel): number {
    if (a.company_name === null || b.company_name === null) {
      return 1;
    }
    return a.company_name.localeCompare(b.company_name);
  }

  recordOffer(): void {
    if (this.deal?.id) {
      let submittedPrice: string = this.form.controls.val.value;

      if (submittedPrice?.includes(',')) {
        submittedPrice = submittedPrice.replace(',', '.');
      }

      this.dealService
        .createDealOffer(
          this.deal?.id,
          parseFloat(submittedPrice),
          this.form.controls.counterPart.value
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this.closeManualOffer();
          this.offerRecorded.emit();
        });
    }
  }

  closeManualOffer() {
    this.showManualOffer = false;
    this.form.setValue({
      val: null,
      counterPart: null,
    });

    this.form.controls.counterPart.reset();
  }

  openManualOffer() {
    this.showManualOffer = true;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
