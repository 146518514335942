import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Component({
  selector: 'gep-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroy$: Subject<void> = new Subject<void>();
  public authStatus = false;
  public isQA = environment.environment === 'qa';
  protected supportedLanguages: string[] = ['en', 'de'];

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    translate: TranslateService
  ) {
    translate.setDefaultLang('en');

    const browserLanguage = translate.getBrowserLang();

    if (
      browserLanguage !== undefined &&
      this.supportedLanguages.includes(browserLanguage)
    ) {
      translate.use(browserLanguage);
    } else {
      console.error(
        'Not supported language ' +
          browserLanguage +
          '. Switching to default-language.'
      );
      translate.use('en');
    }
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        tap(s => (this.authStatus = s === 'none')),
        filter(s => s === InteractionStatus.None),
        takeUntil(this._destroy$)
      )
      .subscribe(x => this.checkAndSetActiveAccount());
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
