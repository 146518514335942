export const AG_GRID_LOCALE_DE = {
  // Set Filter
  selectAll: '(Alle auswählen)',
  selectAllSearchResults: '(Alle Ergebnisse auswählen)',
  addCurrentSelectionToFilter: 'Aktuelle Auswahl zu Filter hinzufügen',
  searchOoo: 'Suchen...',
  blanks: '(Leerzeichen)',
  noMatches: 'Keine Ergebnisse',

  // Number Filter & Text Filter
  filterOoo: 'Suchen...',
  equals: 'Ist gleich',
  notEqual: 'Ist nicht gleich',
  blank: 'Leer',
  notBlank: 'Nicht leer',
  empty: 'Eines auswählen',

  // Number Filter
  lessThan: 'Kleiner',
  greaterThan: 'Größer',
  lessThanOrEqual: 'Kleiner gleich',
  greaterThanOrEqual: 'Größer gleich',
  inRange: 'Im Bereich',
  inRangeStart: 'von',
  inRangeEnd: 'bis',

  // Text Filter
  contains: 'Enthält',
  notContains: 'Enthält nicht',
  startsWith: 'Beginnt mit',
  endsWith: 'Endet mit',

  // Date Filter
  dateFormatOoo: 'dd.mm.yyyy',
  before: 'Vor',
  after: 'Nach',

  // Filter Conditions
  andCondition: 'UND',
  orCondition: 'ODER',

  // Filter Buttons
  applyFilter: 'Anwenden',
  resetFilter: 'Zurücksetzen',
  clearFilter: 'Löschen',
  cancelFilter: 'Abbrechen',

  // Filter Titles
  textFilter: 'Textfilter',
  numberFilter: 'Nummernfilter',
  dateFilter: 'Datumsfilter',
  setFilter: 'Filter setzen',

  // Side Bar
  columns: 'Spalten',
  filters: 'Filter',

  // columns tool panel
  pivotMode: 'Pivot Mode',
  groups: 'Zeilengruppen',
  rowGroupColumnsEmptyMessage: 'Hierher ziehen zum Setzen der Zeilengruppen',
  values: 'Werte',
  valueColumnsEmptyMessage: 'Hierher ziehen zum Aggregieren',
  pivots: 'Spaltentitel',
  pivotColumnsEmptyMessage: 'Hierher ziehen zum Setzen der Spaltentitel',

  // Header of the Default Group Column
  group: 'Gruppe',

  // Row Drag
  rowDragRow: 'Zeile',
  rowDragRows: 'Zeilen',

  // Other
  loadingOoo: 'Lade...',
  loadingError: 'Fehler',
  noRowsToShow: 'Leere Tabelle',
  enabled: 'Aktiviert',

  // Menu
  pinColumn: 'Spalte anheften',
  pinLeft: 'Links anheften',
  pinRight: 'Rechts anheften',
  noPin: 'Nicht anheften',
  valueAggregation: 'Wertaggregation',
  noAggregation: 'Keine',
  autosizeThiscolumn: 'Automatische Größe für diese Spalte',
  autosizeAllColumns: 'Automatische Größe für alle Spalten',
  groupBy: 'Gruppieren nach',
  ungroupBy: 'Entgruppieren nach',
  ungroupAll: 'Alle entgruppieren',
  addToValues: '${variable} zu Werten addieren',
  removeFromValues: '${variable} von Werten abziehen',
  addToLabels: '${variable} zu Titel hinzufügen',
  removeFromLabels: '${variable} von Titel entfernen',
  resetColumns: 'Spalten Zurücksetzen',
  expandAll: 'Alle maximieren',
  collapseAll: 'Alle minimieren',
  copy: 'Kopieren',
  ctrlC: 'Strg+C',
  ctrlX: 'Strg+X',
  copyWithHeaders: 'Mit Spaltentiteln kopieren',
  copyWithGroupHeaders: 'Mit Gruppentiteln kopieren',
  paste: 'Einfügen',
  ctrlV: 'Strg+V',
  export: 'Exportieren',
  csvExport: 'CSV Export',
  excelExport: 'Excel Export',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Sum',
  min: 'Min',
  max: 'Max',
  none: 'Kein',
  count: 'Anzahl',
  avg: 'Durchschnitt',
  filteredRows: 'Gefiltert',
  selectedRows: 'Ausgewählt',
  totalRows: 'Alle Zeilen',
  totalAndFilteredRows: 'Zeilen',
  more: 'Mehr',
  to: 'bis',
  of: 'von',
  page: 'Seite',
  nextPage: 'Nächste Seite',
  lastPage: 'Letzte Seite',
  firstPage: 'Erste Seite',
  previousPage: 'Vorherige Seite',
  pageSizeSelectorLabel: 'Seitengröße:',
  footerTotal: 'Gesamt',

  // Pivoting
  pivotColumnGroupTotals: 'Summe',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
  pivotChart: 'Pivot Chart',
  chartRange: 'Chart Range',

  columnChart: 'Säulendiagramm',
  groupedColumn: 'Gruppiert',
  stackedColumn: 'Gestapelt',
  normalizedColumn: '100% Gestapelt',

  barChart: 'Balkendiagramm',
  groupedBar: 'Gruppiert',
  stackedBar: 'Gestapelt',
  normalizedBar: '100% Gestapelt',

  pieChart: 'Kreisdiagramm',
  pie: 'Kreis',
  doughnut: 'Doughnut',

  line: 'Liniendiagramm',

  xyChart: 'X Y (Scatter)',
  scatter: 'Scatter',
  bubble: 'Bubble',

  areaChart: 'Flächendiagramm',
  area: 'Fläche',
  stackedArea: 'Gestapelt',
  normalizedArea: '100% Gestapelt',

  histogramChart: 'Histogramm',
  histogramFrequency: 'Häufigkeit',

  combinationChart: 'Kombination',
  columnLineCombo: 'Säulen- & Liniendiagramm',
  AreaColumnCombo: 'Flächen- & Säulendiagramm',

  // Charts
  pivotChartTitle: 'Pivot Chart',
  rangeChartTitle: 'Range Chart',
  settings: 'Einstellungen',
  data: 'Data',
  format: 'Format',
  categories: 'Kategorien',
  defaultCategory: '(None)',
  series: 'Series',
  xyValues: 'X Y Values',
  paired: 'Paired Mode',
  axis: 'Axis',
  navigator: 'Navigator',
  color: 'Color',
  thickness: 'Dicke',
  xType: 'X Type',
  automatic: 'Automatic',
  category: 'Kategorie',
  number: 'Nummer',
  time: 'Zeit',
  autoRotate: 'Auto Rotate',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  ticks: 'Dicke',
  width: 'Breite',
  height: 'Höhe',
  length: 'Länge',
  padding: 'Abstand',
  spacing: 'Spacing',
  chart: 'Diagramm',
  title: 'Titel',
  titlePlaceholder: 'Diagramm Titel - klicke zum bearbeiten',
  background: 'Hintergrund',
  font: 'Schrift',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  labels: 'Labels',
  size: 'Size',
  minSize: 'Minimum Size',
  maxSize: 'Maximum Size',
  legend: 'Legend',
  position: 'Position',
  markerSize: 'Marker Size',
  markerStroke: 'Marker Stroke',
  markerPadding: 'Marker Padding',
  itemSpacing: 'Item Spacing',
  itemPaddingX: 'Item Padding X',
  itemPaddingY: 'Item Padding Y',
  layoutHorizontalSpacing: 'Horizontal Spacing',
  layoutVerticalSpacing: 'Vertical Spacing',
  strokeWidth: 'Stroke Width',
  lineDash: 'Line Dash',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Tooltips',
  callout: 'Callout',
  markers: 'Markers',
  shadow: 'Shadow',
  blur: 'Blur',
  xOffset: 'X Offset',
  yOffset: 'Y Offset',
  lineWidth: 'Line Width',
  normal: 'Normal',
  bold: 'Bold',
  italic: 'Italic',
  boldItalic: 'Bold Italic',
  predefined: 'Predefined',
  fillOpacity: 'Fill Opacity',
  strokeOpacity: 'Line Opacity',
  histogramBinCount: 'Bin count',
  columnGroup: 'Säulendiagramm',
  barGroup: 'Balkendiagramm',
  pieGroup: 'Kreisdiagramm',
  lineGroup: 'Liniendiagramm',
  scatterGroup: 'X Y (Scatter)',
  areaGroup: 'Flächendiagramm',
  histogramGroup: 'Histogram',
  combinationGroup: 'Combination',
  groupedColumnTooltip: 'Gruppiert',
  stackedColumnTooltip: 'Gestapelt',
  normalizedColumnTooltip: '100% Gestapelt',
  groupedBarTooltip: 'Gruppiert',
  stackedBarTooltip: 'Gestapelt',
  normalizedBarTooltip: '100% Gestapelt',
  pieTooltip: 'Pie',
  doughnutTooltip: 'Doughnut',
  lineTooltip: 'Line',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Gestapelt',
  normalizedAreaTooltip: '100% Gestapelt',
  scatterTooltip: 'Scatter',
  bubbleTooltip: 'Bubble',
  histogramTooltip: 'Histogram',
  columnLineComboTooltip: 'Column & Line',
  areaColumnComboTooltip: 'Area & Column',
  customComboTooltip: 'Custom Combination',
  noDataToChart: 'No data available to be charted.',
  pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Linked to Grid',
  chartUnlinkToolbarTooltip: 'Unlinked from Grid',
  chartDownloadToolbarTooltip: 'Download Chart',
  seriesChartType: 'Series Chart Type',
  seriesType: 'Series Type',
  secondaryAxis: 'Secondary Axis',

  // ARIA
  ariaChecked: 'checked',
  ariaColumn: 'Column',
  ariaColumnGroup: 'Column Group',
  ariaColumnList: 'Column List',
  ariaColumnSelectAll: 'Toggle Select All Columns',
  ariaDateFilterInput: 'Date Filter Input',
  ariaDefaultListName: 'List',
  ariaFilterColumnsInput: 'Filter Columns Input',
  ariaFilterFromValue: 'Filter from value',
  ariaFilterInput: 'Filter Input',
  ariaFilterList: 'Filter List',
  ariaFilterToValue: 'Filter to value',
  ariaFilterValue: 'Filter Value',
  ariaFilteringOperator: 'Filtering Operator',
  ariaHidden: 'hidden',
  ariaIndeterminate: 'indeterminate',
  ariaInputEditor: 'Input Editor',
  ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
  ariaRowDeselect: 'Press SPACE to deselect this row',
  ariaRowSelectAll: 'Press Space to toggle all rows selection',
  ariaRowToggleSelection: 'Press Space to toggle row selection',
  ariaRowSelect: 'Press SPACE to select this row',
  ariaSearch: 'Search',
  ariaSortableColumn: 'Press ENTER to sort',
  ariaToggleVisibility: 'Press SPACE to toggle visibility',
  ariaUnchecked: 'unchecked',
  ariaVisible: 'visible',
  ariaSearchFilterValues: 'Search filter values',

  // ARIA Labels for Drop Zones

  ariaRowGroupDropZonePanelLabel: 'Row Groups',
  ariaValuesDropZonePanelLabel: 'Values',
  ariaPivotDropZonePanelLabel: 'Column Labels',
  ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
  ariaDropZoneColumnValueItemDescription:
    'Press ENTER to change the aggregation type',
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
  ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
  ariaDropZoneColumnComponentSortAscending: 'ascending',
  ariaDropZoneColumnComponentSortDescending: 'descending',

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: 'Column Menu',
  ariaLabelCellEditor: 'Cell Editor',
  ariaLabelDialog: 'Dialog',
  ariaLabelSelectField: 'Select Field',
  ariaLabelTooltip: 'Tooltip',
  ariaLabelContextMenu: 'Context Menu',
  ariaLabelSubMenu: 'SubMenu',
  ariaLabelAggregationFunction: 'Aggregation Function',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: '.',
  decimalSeparator: ',',
};
