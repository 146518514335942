<gep-uba-tab-header></gep-uba-tab-header>
<main>
  <div
    *ngIf="
      invalidations !== undefined && invalidations!.length > 0;
      else noDeals
    "
  >
    <div class="d-flex">
      <div style="width: 75%" class="rows infos">
        <div class="row">
          <div class="entry">
            <div class="cell">
              {{ 'invalidations.selectedDeals' | translate }}:
            </div>
            <div class="cell">{{ invalidations!.length }}</div>
          </div>
          <div class="entry">
            <div class="cell">
              {{ 'invalidations.selectedSum' | translate }}:
            </div>
            <div class="cell">{{ selectedSum | number : '0.0-1' }} MWh</div>
          </div>
          <div class="entry">
            <div class="cell">
              {{ 'invalidations.reasonOfCancellation' | translate }}:
            </div>
            <div class="cell">Stromkennzeichnung für {{ year }}</div>
          </div>
        </div>
        <div class="row">
          <div class="entry">
            <div class="cell">{{ 'customer' | translate }}:</div>
            <div class="cell">Sammelentwertung</div>
          </div>
          <div class="entry">
            <div class="cell">{{ 'product' | translate }}:</div>
            <div class="cell">{{ product }}</div>
          </div>
          <div class="entry">
            <div class="cell">
              {{ 'invalidations.annotation' | translate }}:
            </div>
            <div class="cell">{{ cancellationId }}</div>
          </div>
        </div>
      </div>
      <div style="width: 25%; text-align: center">
        <div
          *ngIf="hasActiveCancellation; else cancellationDone"
          class="d-flex flex-column justify-content-around align-items-center"
          style="height: 100%"
        >
          <gep-button
            [label]="'invalidations.acceptCancellation' | translate"
            [style]="Style.Highlight"
            (click)="dealModal.show()"
          ></gep-button>
          <gep-button
            [style]="Style.Gray"
            routerLink="/invalidations"
            [label]="'invalidations.rejectCancellationStatement' | translate"
          ></gep-button>
        </div>

        <ng-template
          #cancellationDone
          class="fw-bold"
          style="text-align: center"
        >
          <eon-ui-icon class="icon" name="radio_checkbox_tick"></eon-ui-icon>
          {{ 'invalidations.successfulCancellation' | translate }}
        </ng-template>
      </div>
    </div>

    <gep-modal
      [visible]="dealModal.visible"
      [headline]="'invalidations.acceptCancellation' | translate"
      [description]="'invalidations.questionForInvalidateMultiple' | translate"
      [positiveButtonStyle]="Style.Highlight"
      [closeButtonText]="'no' | translate"
      [positiveButtonText]="'yes' | translate"
      (positiveButtonClicked)="cancelSelectedDeals()"
      (closedButtonClicked)="dealModal.close()"
      modalId="show-confirmation"
    ></gep-modal>

    <h2>{{ 'invalidations.overviewSelectedDeals' | translate }}</h2>

    <table class="table">
      <thead>
        <tr>
          <th>PSI Id</th>
          <th>{{ 'deal_type' | translate }}</th>
          <th>{{ 'customer' | translate }}</th>
          <th>{{ 'quantityInMWh' | translate }}</th>
          <th>{{ 'invalidations.comment' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let invalidation of invalidations">
          <td>{{ invalidation.deal_id }}</td>
          <td>{{ invalidation.deal_type }}</td>
          <td>{{ invalidation.customer }}</td>
          <td>{{ invalidation.quantityInMWh | number : '0.0-1' }} MWh</td>
          <td>{{ invalidation.comment }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <gep-modal
    [visible]="statementCancellationAttentionModal.visible"
    [headline]="'invalidations.modalStatementCancellationHeader' | translate"
    [description]="'invalidations.modalStatementCancellationDescription' | translate"
    [positiveButtonStyle]="Style.Highlight"
    [positiveButtonText]="'OK'"
    (positiveButtonClicked)="statementCancellationAttentionModal.close()"
    modalId="show-confirmation"
  ></gep-modal>
  <ng-template #noDeals>
    <h1>{{ 'invalidations.errors.title' | translate }}</h1>
    <p>{{ 'invalidations.errors.noDeals' | translate }}</p>
  </ng-template>
</main>
