<ng-container *ngIf="form$ | async as form">
  <fieldset [formGroup]="form">
    <div>
      <gep-date-picker
        [minimumDate]="today"
        [label]="datePickerLabel"
        formControlName="date"
      ></gep-date-picker>
    </div>
    <div>
      <gep-binding-period-time-dropdown
        [label]="timeDropdownLabel"
        formControlName="time"
      ></gep-binding-period-time-dropdown>
    </div>
  </fieldset>
</ng-container>
