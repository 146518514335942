import { Component, Input } from '@angular/core';

@Component({
  selector: 'gep-environment-banner',
  templateUrl: './environment-banner.component.html',
  styleUrls: ['./environment-banner.component.scss'],
})
export class EnvironmentBannerComponent {
  @Input() isQA: boolean = false;
}
