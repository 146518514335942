<eon-ui-radio-button-group
  #eonUiRadioButtonGroup
  [disabled]="disabled"
  [label]="formattedLabel"
  [name]="name"
  [required]="required"
  [scheme]="scheme"
  [value]="value"
  custom-required-message="This field is required."
  (valueChanged)="onValueChanged($event)"
>
  <ng-content></ng-content>
</eon-ui-radio-button-group>
