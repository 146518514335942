
<div class="date-time-picker" [formGroup]="form">
  <gep-date-picker formControlName="selectedDate" (valueSelected)="onValueChange()" [showWeekends]="showWeekends" [label]="label"></gep-date-picker>
  <gep-time-picker
    [interval]="timeInterval"
    [disabled]="!form.get('selectedDate')?.value"
    formControlName="selectedTime"
    (valueSelected)="onValueChange()"
  ></gep-time-picker>
</div>
