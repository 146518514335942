import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeliveryYearService {
  constructor(private http: HttpClient) {}

  getAllDeliveryYears(): Observable<number[]> {
    return this.http.get<number[]>(`${environment.API}v1/deals/delivery-years`);
  }
}
