<eon-ui-navigation-meta slot="meta">
  <eon-ui-navigation-column contentAlignment="center" contentAlignmentMobile="center" grow="true" growMobile="true">
    <eon-ui-navigation-meta-link
      [text]="'settings.general' | translate"
      [routerLink]="'/settings/general'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/settings/general'"
    ></eon-ui-navigation-meta-link>
    <eon-ui-navigation-meta-link
      [text]="'settings.productConfiguration' | translate"
      [routerLink]="'/settings/product-configuration'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/settings/product-configuration'"
    ></eon-ui-navigation-meta-link>
    <eon-ui-navigation-meta-link
      [text]="'settings.enquiryTemplatesSettings' | translate"
      [routerLink]="'/settings/enquiry-defaults'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/settings/enquiry-defaults'"
    ></eon-ui-navigation-meta-link>
    <eon-ui-navigation-meta-link
      [text]="'settings.counterpartUsers' | translate"
      [routerLink]="'/settings/counterpart-users'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/settings/counterpart-users'"
    ></eon-ui-navigation-meta-link>
  </eon-ui-navigation-column>
</eon-ui-navigation-meta>
