import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DealModel } from '../../models/deal.model';
import { Style } from '../../modules/gep-controls/models/style';
import { takeUntil, tap } from 'rxjs/operators';
import { DealService } from '../../services/deal.service';
import { BatchDealCancellationResource } from '../../models/batch-deal-cancellation.model';
import { BatchIdService } from '../../services/batch-id.service';
import { ModalWithData } from '../../models/modal-with-data.model';
import { ToastService } from '../../services/toast.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'gep-delivery-invalidations-overview-page',
  templateUrl: './delivery-invalidations-overview-page.component.html',
  styleUrls: ['./delivery-invalidations-overview-page.component.scss'],
})
export class DeliveryInvalidationsOverviewPageComponent
  implements OnInit, OnDestroy
{
  invalidations?: DealModel[];
  selectedSum?: number;
  product?: string;
  year?: number;
  cancellationId?: string;
  dealModal: ModalWithData = new ModalWithData();
  statementCancellationAttentionModal: ModalWithData = new ModalWithData();
  hasActiveCancellation = true;

  _destroy$ = new Subject<void>();

  protected readonly Style = Style;

  constructor(
    private router: Router,
    private dealsService: DealService,
    private batchIdService: BatchIdService,
    private toastService: ToastService
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.invalidations = navigation?.extras.state?.rows as DealModel[];
    this.selectedSum = navigation?.extras.state?.sum as number;

    if (this.invalidations) {
      this.year = this.invalidations[0]?.year;
      this.product = this.invalidations[0]?.product_key;
    }

    this.batchIdService
      .getBatchId()
      .pipe(takeUntil(this._destroy$))
      .subscribe(batchId => {
        this.cancellationId = batchId.batch_cancellation_id;
      });
  }

  ngOnInit() {
    if (this.checkingTheDealsOnCancellationStatement()) {
      this.statementCancellationAttentionModal.visible = true;
    }
  }

  cancelSelectedDeals() {
    const cancellations = this.invalidations?.map(
      (deal: DealModel) => deal.id!
    );

    if (cancellations) {
      const batchResource: BatchDealCancellationResource = {
        batch_cancellation_id: this.cancellationId!,
        cancellations,
      };

      this.dealModal.close();

      this.dealsService
        .cancelDeals(batchResource)
        .pipe(
          takeUntil(this._destroy$),
          tap(() => {
            this.hasActiveCancellation = false;
            this.toastService.showSavedToast();
          })
        )
        .subscribe();
    }
  }

  checkingTheDealsOnCancellationStatement(): boolean {
    if (this.invalidations && this.invalidations?.length !== 0) {
      return this.invalidations?.some(
        (item: DealModel) => item?.statement_cancellation
      );
    }
    return false;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
