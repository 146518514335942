<div>
  <gep-small-number-input
    [inputWidth]="inputWidth"
    [disabled]="disabled"
    [value]="firstValue"
    (valueChanged)="onInputValueChanged($event, 'first')"
  ></gep-small-number-input>
  {{ unit }}

  {{ 'TO' | translate | lowercase }}

  <gep-small-number-input
    [inputWidth]="inputWidth"
    [disabled]="disabled"
    [value]="secondValue"
    (valueChanged)="onInputValueChanged($event, 'second')"
  ></gep-small-number-input>
  {{ unit }}
</div>
<div style="margin-top: 10px">
  <gep-slider
    [minValue]="minValue!"
    [maxValue]="maxValue!"
    [firstValue]="minValue"
    [secondValue]="maxValue"
    [disabled]="disabled"
    [step]="step"
    (sliderValueChanged)="onSliderValueChanged($event)"
  ></gep-slider>
</div>
