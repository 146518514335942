import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DealModel } from '../models/deal.model';

@Injectable({
  providedIn: 'root',
})
export class BatchConsignmentService {
  constructor() {}

  public sender$ = new ReplaySubject<DealModel[]>(1);

  public receiver$ = this.sender$.asObservable();
}
