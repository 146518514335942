<ul class="checkbox-tree">
  <li *ngFor="let item of items">
    <span style="vertical-align: super;" (click)="item.expanded = !item.expanded">
      <i class="accordion-icon">
        <svg [ngClass]="!item.expanded ? 'icon-transformed' : ''" viewBox="0 0 1024 1024" focusable="false" class="" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
          <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
        </svg>
      </i>
    </span>
    <label class="label-container">
      <b>{{item.label}}</b>
      <input type="checkbox" [(ngModel)]="item.checked" (change)="checkAll(item)" [class.indeterminate]="item.indeterminate">
      <span class="checkmark"></span>
    </label>

    <ul *ngIf="item.children && item.expanded">
      <li *ngFor="let child of item.children">
        <label class="label-container">
          {{child.label}}
          <input type="checkbox" [(ngModel)]="child.checked" (change)="checkParent(item)">
          <span class="checkmark"></span>
        </label>
      </li>
    </ul>
  </li>
</ul>
