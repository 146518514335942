import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Observable,
  ReplaySubject,
  startWith,
  Subject,
} from 'rxjs';
import { DealModel } from '../../../../models/deal.model';
import { EnquiryFormBuilderService } from '../../services/enquiry-form-builder.service';
import {
  combineLatestWith,
  debounceTime,
  filter,
  map,
  pairwise,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { EnquiryFormFassadeService } from '../../services/enquiry-form-fassade.service';
import { TranslateService } from '@ngx-translate/core';
import {
  getCO2FactorFromProductSelection,
  mapToFormGroup,
  quantityTonnsToMWhFieldsSideEffect,
  quantityTonnsToMWhValueSideEffect,
  switchToCommentSideEffect,
  switchToRedistributorSideEffect,
  switchToTooltipTranslations,
  switchToTranslationTransfomerFn,
} from '../../utils/enquiry-form.functions';
import { EnquiryFormModel } from '../../models/enquiry-form-model';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Icon } from '../../../gep-controls/models/icon';
import { ButtonSize } from '../../../gep-controls/models/button-size';
import { Style } from '../../../gep-controls/models/style';
import { ProductConfigModel } from '../../../../models/product-config.model';
import { KpiService } from '../../../../services/kpi.service';
import { ProductService } from '../../../../services/product.service';
import { ToggleComponent } from '../../../deals/components/toggle/toggle.component';
import { ModalWithData } from '../../../../models/modal-with-data.model';

const MAXIMUM_DEAL_YEARS = 10;

@Component({
  selector: 'gep-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss'],
})
export class EnquiryFormComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly injectedProductConfigurations$ = new ReplaySubject<
    ProductConfigModel[]
  >(1);

  @Input()
  set productConfigurations(value: ProductConfigModel[]) {
    this.injectedProductConfigurations$.next(value);
  }

  readonly deal$ = new ReplaySubject<DealModel | null>(1);
  @Input()
  set deal(value: DealModel | null) {
    this.deal$.next(value);
    this.statementCancellation$.next(value?.statement_cancellation ?? false);
  }

  private readonly prefillForm$ =
    new ReplaySubject<Partial<EnquiryFormModel> | null>(1);
  @Input()
  set prefillForm(value: Partial<EnquiryFormModel> | null) {
    this.prefillForm$.next(value);
  }

  @Output()
  save = new EventEmitter<DealModel[]>();

  @Output()
  cancel = new EventEmitter<void>();

  @ViewChild('toggleStatementCancellation')
  toggleStatementCancellation!: ToggleComponent;

  readonly deliveryYears: number[] = Array(MAXIMUM_DEAL_YEARS)
    .fill(0)
    .map((_, index) => {
      const lastYear = new Date().getFullYear() - 1;
      return lastYear + index;
    });

  readonly productConfigurations$: Observable<ProductConfigModel[]> =
    this.injectedProductConfigurations$.pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );

  readonly formGroup$ = this.deal$.pipe(
    combineLatestWith(this.productConfigurations$),
    mapToFormGroup(this.formBuilderService)
  );

  readonly formGroupWithCommentSideEffect$ = this.formGroup$.pipe(
    switchToCommentSideEffect(
      this.enquiryFassade.getCommentsForProductSelection$(),
      this.deal$
    ),
    switchToRedistributorSideEffect()
  );

  readonly form$ = this.prefillForm$.pipe(
    startWith(null),
    combineLatestWith(this.formGroupWithCommentSideEffect$),
    map(([prefill, latestForm]) => {
      if (!!prefill) {
        Object.keys(prefill).map((prefillKey: string) => {
          const control = latestForm.get(prefillKey);
          if (!!control && !control.disabled) {
            control.setValue(
              prefill[prefillKey as keyof Partial<EnquiryFormModel>]
            );
          }
        });
      }
      return latestForm;
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly fromGroupDealTypeChangedSideEffect$ = this.form$.pipe(
    switchMap(form =>
      form.valueChanges.pipe(
        map((form: EnquiryFormModel) => form.deal_type),
        distinctUntilChanged()
      )
    ),
    combineLatestWith(this.form$),
    filter(([dealType, _]) => Boolean(dealType)),
    map(([dealType, formGroup]) => {
      const deliveries = formGroup.get('deliveries') as UntypedFormArray;

      for (const control of deliveries.controls) {
        const priceControl = control.get('price');

        if (dealType === 'ENQUIRY_SELL') {
          priceControl?.enable();
        } else {
          priceControl?.setValue(null);
          priceControl?.disable();
        }
      }

      return dealType;
    })
  );

  readonly formGroupPriceFillWithKpiSideEffect$ = this.form$.pipe(
    switchMap(formGroup =>
      formGroup.valueChanges.pipe(
        debounceTime(1),
        map((form: EnquiryFormModel) => ({
          dealType: form.deal_type,
          productKey: form.productSelection?.productConfig?.key ?? '',
          deliveries: form.deliveries?.map(delivery => ({
            price: delivery.price,
            year: delivery.delivery_year,
          })),
        })),
        distinctUntilChanged(),
        filter(
          ({ dealType, productKey }) =>
            Boolean(productKey) && dealType === 'ENQUIRY_SELL'
        ),
        map(({ deliveries, productKey }) => ({ productKey, deliveries })),
        pairwise()
      )
    ),
    map(([prev, curr]) => {
      const changedDeliveryIndex = prev.deliveries.findIndex(
        (delivery, index) => delivery.year !== curr.deliveries[index]?.year
      );

      if (changedDeliveryIndex === -1) {
        return null;
      }

      const changedDelivery = curr.deliveries[changedDeliveryIndex];

      return {
        curr: changedDelivery,
        productKey: curr.productKey,
        index: changedDeliveryIndex,
      };
    }),
    filter(value => value !== null),
    map(delivery => ({
      delivery: delivery!.curr,
      productKey: delivery!.productKey,
      index: delivery!.index,
    })),
    switchMap(({ delivery, productKey, index }) =>
      this.kpiService.getProductKpis$(productKey).pipe(
        map(kpis => {
          const matchingKpi = kpis.find(kpi => kpi.year === delivery.year);
          return { kpis: matchingKpi, index };
        })
      )
    ),
    combineLatestWith(this.form$),
    tap(([{ kpis, index }, form]) => {
      const deliveries = form.get('deliveries') as UntypedFormArray;
      const matchingDelivery = deliveries.at(index);

      matchingDelivery.get('price')?.setValue(kpis?.market_price?.toFixed(5));
    })
  );

  readonly formValidity$ = this.form$.pipe(
    switchMap(form => {
      return form.valueChanges.pipe(
        // checking for `form.get('productSelection')?.valid` doesn't work
        // for
        map(
          () =>
            form.valid &&
            form.get('productSelection')?.value?.region !== '' &&
            form.get('productSelection')?.value?.productConfig?.key !==
              undefined
        ),
        map(validity => {
          this.changeDetection.detectChanges();
          return validity;
        })
      );
    }),
    distinctUntilChanged()
  );

  statementCancellation$ = new BehaviorSubject<boolean>(false);
  readonly statementCancellationSetter$ = this.form$.pipe(
    combineLatestWith(this.statementCancellation$),
    map(([formGroup, value]) => {
      const formControl = formGroup.get('statement_cancellation');
      formControl?.setValue(value);
      return formGroup;
    }),
    tap(() => {
      this.confirmModal.close();
    })
  );

  readonly salesCompanies$ = this.productService.getVgs$();

  readonly segments$ = this.productService.getSegments$();

  readonly redistributorOptions$ = this.enquiryFassade
    .getRedistributorRadioSelectOptions$()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  readonly redistributorTransformerFn$ = this.redistributorOptions$.pipe(
    switchToTranslationTransfomerFn(
      this.translateService,
      'enquiryForm.redistributor.'
    )
  );

  readonly dealTypes$ = this.enquiryFassade
    .getDealTypes$()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  readonly dealTypesWithTooltips$ = this.dealTypes$.pipe(
    switchToTooltipTranslations(this.translateService)
  );

  readonly dealTypeLabelTransformer$ = this.dealTypes$.pipe(
    switchToTranslationTransfomerFn(this.translateService)
  );

  readonly Icon = Icon;

  readonly ButtonSize = ButtonSize;

  readonly Style = Style;

  confirmModal: ModalWithData = new ModalWithData();

  constructor(
    private readonly formBuilderService: EnquiryFormBuilderService,
    private readonly productService: ProductService,
    private readonly enquiryFassade: EnquiryFormFassadeService,
    private readonly kpiService: KpiService,
    private readonly translateService: TranslateService,
    private readonly changeDetection: ChangeDetectorRef
  ) {
    this.deliveriesSideEffect();
    this.productSelectionSideEffect();
  }

  isFieldInvalid(formGroup: UntypedFormGroup, field: string): boolean {
    const control = formGroup.get(field);
    return Boolean(control?.invalid && (control.touched || control.dirty));
  }

  hasMinLengthError(formGroup: UntypedFormGroup, field: string): boolean {
    const control = formGroup.get(field);
    return Boolean(
      control?.hasError('minlength') && (control.touched || control.dirty)
    );
  }

  identityStringTransformer(option: string): string {
    return String(option);
  }

  addYear(enquiryForm: UntypedFormGroup) {
    this.formBuilderService.addEntryToDeliveries(enquiryForm);
  }

  onCancel() {
    this.cancel.emit();
  }

  onSave(form: UntypedFormGroup) {
    const deals = this.formBuilderService.getDeals(form);
    this.save.emit(deals);
  }

  statementCancellationToggleChanged(value: boolean) {
    if (value) {
      this.confirmModal.show();
    } else {
      this.setFormControlStatementCancellation(false);
    }
  }

  setStatementCancellation() {
    this.confirmModal.close();
    this.setFormControlStatementCancellation(true);
  }
  resetStatementCancellationToggle() {
    this.confirmModal.close();
    this.setFormControlStatementCancellation(false);
    this.toggleStatementCancellation.setChecked(false);
  }

  setFormControlStatementCancellation(value: boolean) {
    this.statementCancellation$.next(value);
  }

  readonly isCO2ProductSelected$: Observable<boolean> = this.form$.pipe(
    switchMap(form =>
      form.get('productSelection')!.valueChanges.pipe(
        map(() => {
          return !!getCO2FactorFromProductSelection(form);
        }),
        startWith(!!getCO2FactorFromProductSelection(form)),
        distinctUntilChanged()
      )
    )
  );

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  deliveriesSideEffect() {
    this.form$
      .pipe(
        switchMap(form =>
          form.get('deliveries')!.valueChanges.pipe(
            debounceTime(500),
            map(() => form)
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(form => {
        quantityTonnsToMWhValueSideEffect(form);
      });
  }

  productSelectionSideEffect() {
    this.form$
      .pipe(
        switchMap(form =>
          form.get('productSelection')!.valueChanges.pipe(map(() => form))
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(form => {
        quantityTonnsToMWhValueSideEffect(form);
        quantityTonnsToMWhFieldsSideEffect(form);
      });
  }
}
