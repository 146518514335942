import { Component } from '@angular/core';
import { DealModel } from '../../../../models/deal.model';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Column, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'gep-price-renderer',
  templateUrl: './price-renderer.component.html',
})
export class PriceRendererComponent implements ICellRendererAngularComp {
  row?: DealModel;
  column?: Column;
  isGroup: boolean = false;
  total_price?: string = '';

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.row = params.node.data;
    this.column = params.column;
    this.isGroup = !!params.node.group;
    let sum: number;

    if (this.row) {
      sum = this.row['price'] ?? 0;
    } else {
      sum = params.value;
    }

    this.total_price = sum
      ? Number(sum).toLocaleString('de-DE', {
          style: 'currency',
          currency: 'eur',
        })
      : '';
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
