import { combineLatestWith, map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProductConfigModel } from '../../../models/product-config.model';
import { TranslateService } from '@ngx-translate/core';
import { ProductSelectionFormModel } from '../models/product-selection-form-model';
import { UntypedFormGroup } from '@angular/forms';
import { DealModel, Status } from '../../../models/deal.model';

interface ProductConfigTranslation {
  productKey: string;
  translation: string;
}

export function switchToProductConfigTranslations(
  translateService: TranslateService
) {
  return switchMap((productConfigurations: ProductConfigModel[]) => {
    let firstInitialTranslateObservable$: Observable<
      ProductConfigTranslation[]
    >;
    const translateObservables$: Array<Observable<ProductConfigTranslation>> =
      productConfigurations
        .map(
          (
            productConfiguration,
            index
          ): Observable<ProductConfigTranslation> => {
            const singleTranslation$ = translateService
              .get(`PRODUCT.KEY.${productConfiguration.key}`)
              .pipe(
                map(
                  (translation): ProductConfigTranslation => ({
                    productKey: productConfiguration.key,
                    translation,
                  })
                )
              );
            if (index === 0)
              firstInitialTranslateObservable$ = singleTranslation$.pipe(
                map(productTranslation => [productTranslation])
              );

            return singleTranslation$;
          }
        )
        .filter((_, index) => index > 0);

    return translateObservables$.reduce((previous$, current$) => {
      return previous$.pipe(
        combineLatestWith(current$),
        map(([allPrevious, current]) => [...allPrevious, current])
      );
    }, firstInitialTranslateObservable$!);
  });
}

export function productSelectionFormModelChangeSideEffect(
  changeFn?: (change: ProductSelectionFormModel) => void,
  touchFn?: () => void
) {
  return tap((change: ProductSelectionFormModel) => {
    if (changeFn) {
      changeFn(change);
    }

    if (touchFn) {
      touchFn();
    }
  });
}

export function productConfigChangeSideEffect(
  form: UntypedFormGroup,
  deal?: DealModel | null
) {
  return tap((configModel: ProductConfigModel) => {
    if (deal?.status === Status.BINDING) {
      form.disable();
    }

    const systemAgeControl = form.get('system_age')!;
    configModel.system_age_updatable
      ? systemAgeControl.enable()
      : systemAgeControl.disable();
    systemAgeControl.setValue(configModel.system_age);

    const energySource = form.get('energy_source')!;
    configModel.energy_source_updatable
      ? energySource.enable()
      : energySource.disable();
    energySource.setValue(configModel.energy_source);

    const region = form.get('region')!;
    configModel.region_updatable ? region.enable() : region.disable();
    region.setValue(configModel.region_id);

    if (deal?.status === Status.BINDING) {
      systemAgeControl.disable();
      energySource.disable();
      region.disable();
    }
  });
}
