import { Component } from '@angular/core';
import { CounterpartService } from '../../../../services/counterpart.service';
import { Subject } from 'rxjs';
import { CounterpartUserModel } from '../../../../models/counterpart-user.model';
import { ColDef } from 'ag-grid-community';
import { CounterpartUsersActionsRendererComponent } from '../../../ag-grid/renderer/counterpart-users-actions-renderer/counterpart-users-actions-renderer.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gep-counterpart-users',
  templateUrl: './counterpart-users.component.html',
  styleUrls: ['./counterpart-users.component.scss'],
})
export class CounterpartUsersComponent {
  protected _destroy$: Subject<void> = new Subject<void>();

  readonly counterpartUsers$ = this.counterpartService.getCounterpartUsers();

  colDefs: ColDef<CounterpartUserModel>[] = [
    {
      field: 'name',
      width: 300,
    },
    {
      field: 'company_name',
      filter: 'agSetColumnFilter',
      sort: 'asc',
      enableRowGroup: true,
      width: 300,
    },
    {
      field: 'email',
      width: 300,
    },
    {
      headerName: this.translateService.instant('settings.notify_by_email'),
      field: 'notify_by_email',
      width: 250,
      cellRenderer: CounterpartUsersActionsRendererComponent,
      filter: 'agSetColumnFilter',
    },
  ];

  constructor(
    private counterpartService: CounterpartService,
    protected translateService: TranslateService
  ) {}
}
