import { Pipe, PipeTransform } from '@angular/core';
import { Status } from '../models/deal.model';

@Pipe({
  name: 'informCounterpartButton',
})
export class InformCounterpartButtonPipe implements PipeTransform {
  transform(hasCounterpartsToBeInformed: number, dealStatus?: Status): string {
    return hasCounterpartsToBeInformed > 0 ||
      dealStatus === Status.PRICE_COLLECTION
      ? 'SEND'
      : 'CHANGE_DEAL_STATUS';
  }
}
