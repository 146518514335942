<gep-uba-tab-header></gep-uba-tab-header>

<main class="bottomed">
  <div class="d-flex flex-row">
    <gep-generic-filter
      [filterProperties]="consignmentsFilterService.getFilterConfig()"
      [filterService]="consignmentsFilterService"
      (filterChanged)="setDataToTableAndReload()"
      [canBeClosed]="false"
    ></gep-generic-filter>
    <div style="overflow: hidden;">
      <div class="save-all-received">
        <gep-button
          *ngIf="!isSubmitting"
          [label]="'consignments.saveAllReceived' | translate"
          [size]="ButtonSize.Small"
          (click)="confirmMultipleModal.show()"
        ></gep-button>
        <gep-loading-spinner [visible]="isSubmitting" [size]="ButtonSize.Small"></gep-loading-spinner>
      </div>

      <gep-modal
        [visible]="confirmOneModal.visible"
        [headline]="'consignments.acceptConsignment' | translate"
        [description]="'consignments.questionForConsignmentSingle' | translate"
        [positiveButtonStyle]="Style.Highlight"
        [closeButtonText]="'no' | translate"
        [positiveButtonText]="'yes' | translate"
        (positiveButtonClicked)="saveCheck(selectedRow)"
        (closedButtonClicked)="confirmOneModal.close()"
        [showClosingX]="true"
        modalId="show-consignment-confirmation"
      ></gep-modal>

      <gep-modal
        [visible]="confirmMultipleModal.visible"
        [headline]="'consignments.acceptConsignment' | translate"
        [description]="'consignments.questionForConsignmentMultiple' | translate"
        [positiveButtonStyle]="Style.Highlight"
        [closeButtonText]="'no' | translate"
        [positiveButtonText]="'yes' | translate"
        (positiveButtonClicked)="saveCheckBatched()"
        (closedButtonClicked)="confirmMultipleModal.close()"
        [showClosingX]="true"
        modalId="show-save-all-consignment-confirmation"
      ></gep-modal>


      <gep-pagination-table-wrapper
        #paginator
        [dataRetriever]="dataRetriever"
        [selectedColumns]="selectedColumns"
        [availableColumns]="availableColumns"
        [activatedFilters]="consignmentsFilterService.getActivatedFilters()"
        [defaultOrderBy]="defaultOrderBy"
      >
      </gep-pagination-table-wrapper>
    </div>
  </div>
</main>

<ng-template #checkTemplate let-column="column" let-row="row">
  <eon-ui-checkbox
    id="check"
    ngDefaultControl
    [disabled]="row._submitting"
    [checked]="row.quantity_delivery_confirmed ?? false"
    (valueChanged)="checkClicked(row, $any($event).detail)"
  ></eon-ui-checkbox>
</ng-template>

<ng-template #redistributorTemplate let-column="column" let-row="row">
  <eon-ui-checkbox
    id="redistributor"
    ngDefaultControl
    [disabled]="true"
    [checked]="row.redistributor ?? false"
  ></eon-ui-checkbox>
</ng-template>

<ng-template #subaccountTemplate let-column="column" let-row="row">
  <gep-input-deprecated
    [disabled]="row._submitting"
    [(ngModel)]="row.quantity_delivery_subaccount"
    size="small"
    type="text"
  ></gep-input-deprecated>
</ng-template>

<ng-template #saveCheckTemplate let-column="column" let-row="row">
  <gep-button
    *ngIf="!row._submitting"
    [label]="'SAVE' | translate"
    [size]="ButtonSize.Small"
    (click)="setSelectedRow(row)">
  </gep-button>
  <gep-loading-spinner [visible]="row._submitting" [size]="ButtonSize.Small"></gep-loading-spinner>
</ng-template>

<ng-template #statement_cancellation let-column="column" let-row="row">
  <gep-icon *ngIf="row.statement_cancellation; else noStatementCancellation" [iconType]="Icon.CheckboxChecked" [style]="Style.Gray"></gep-icon>
  <ng-template #noStatementCancellation>
    <gep-icon [iconType]="Icon.Checkbox" [style]="Style.Gray"></gep-icon>
  </ng-template>
</ng-template>

<ng-template #quantityTemplate let-column="column" let-row="row">
  <div class="quantity">{{ row.quantityInMWh | quantity: QuantityUnit.MWh }}</div>
</ng-template>

<ng-template #quantityInTonnsTemplate let-column="column" let-row="row">
  <div class="quantity">{{ row.quantityInTonns | quantity: QuantityUnit.t }}</div>
</ng-template>

