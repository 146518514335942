import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

export const SaleGuard: CanActivateChildFn = (childRoute, state) => {
  const router = inject(Router);
  const authService = inject(MsalService);

  const activeAccount = authService.instance.getActiveAccount();
  const roles = (activeAccount?.idTokenClaims as { roles: string[] })?.roles;

  const isSalesUser = roles?.length == 1 && roles[0] == 'SALES';

  if (isSalesUser && !childRoute.url[0].path.includes('enquiry')) {
    router.navigate(['/enquiry']);
    return false;
  }

  return true;
};
