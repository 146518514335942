<div class="wrapper" *ngIf="pageData$ | async as page; else loadingIndicator">
  <div class="header">
    <div class="header-controls">
      <eon-ui-button
        *ngIf="columnSelectionAllowed$ | async"
        icon="filter"
        size="small"
        (click)="optionCollapsed = !optionCollapsed"
      ></eon-ui-button>
      <gep-dropdown
        [label]="'dataTable.pageSizeDropdown' | translate"
        [options]="pageSizeOptions"
        [optionLabelTransformer]="pageSizeOptionTransformer"
        [optionValueTransformer]="pageSizeOptionTransformer"
        [formControl]="pageSizeControl"
        [size]="ButtonSize.Small"
      >
      </gep-dropdown>
    </div>
    <div
      *ngIf="columnSelectionAllowed$ | async"
      class="collapsable"
      [class.open]="!optionCollapsed"
    >
      <ng-container *ngIf="availableColumns$ | async as availableColumns">
        <ng-container *ngIf="selectedColumns$ | async as selectedColumns">
          <gep-pagination-column-selection
            [availableColumns]="availableColumns"
            [selectedColumns]="selectedColumns"
            (columnSelectionChanged)="onColumnsChange($event)"
          >
          </gep-pagination-column-selection>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="table">
    <ng-container *ngIf="columns$ | async as columns">
      <gep-pagination-table
        [columns]="columns"
        [page]="page"
        (paramsChanged)="onTriggerReload()"
        [small]="small"
      >
      </gep-pagination-table>
    </ng-container>
  </div>
  <div class="controls">
    <gep-pagination-footer
      [page]="page.page"
      [totalPages]="page.total_pages"
      (pageChanged)="onPageChange($event)"
    ></gep-pagination-footer>
  </div>
</div>

<ng-template #loadingIndicator>
  <div class="wrapper">
    <div class="loading-overlay" *ngIf="loading">
      <eon-ui-preloader-circle scheme="white"></eon-ui-preloader-circle>
    </div>
  </div>
</ng-template>
