import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { PageRetrieverInterface } from './page-retriever.interface';
import { Column } from './column';
import { PageModel } from '../../models/page.model';
import {
  BehaviorSubject,
  distinctUntilChanged,
  mergeWith,
  Observable,
  ReplaySubject,
  startWith,
  Subject,
  withLatestFrom,
} from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import {
  DealSearchFilter,
  DealSearchMode,
} from '../../modules/deal-search/model/deal-search-filter';
import { ButtonSize } from '../../modules/gep-controls/models/button-size';
import { DealSearchSortOption } from '../../modules/deal-search/model/deal-search-sort-option';

type Controls = 'SMALL' | 'COLUMNS';

const DEFAULT_ALLOWED_CONTROLS = ['SMALL', 'COLUMNS'];

@Component({
  selector: 'gep-pagination-table-wrapper',
  templateUrl: './pagination-table-wrapper.component.html',
  styleUrls: ['./pagination-table-wrapper.component.scss'],
})
/**
 * To use custom TD elements, define an ng-template:
 *
 * ```html
 * <ng-template #addressTemplate let-row="row" let-column="column">
 *  {{row[column.name]}}
 * </ng-template>
 * ```
 *
 * Load it via ViewCild:
 * ```typescript
 * @ViewChild("addressTemplate", {read: TemplateRef})
 * public addressTemplate!: TemplateRef<any>;
 * ```
 *
 * When defining the Columns make sure to define them in **AfterViewInit** not in OnInit! But then you should either force an update or call your initialization inside setTimeout
 *
 */
export class PaginationTableWrapperComponent {
  protected readonly ButtonSize = ButtonSize;

  readonly pageSizeControl = new UntypedFormControl(20);

  readonly pageSizeOptions = [20, 50, 100, 150, 250, 500, 1000];

  private readonly pageSize$ = this.pageSizeControl.valueChanges.pipe(
    startWith(this.pageSizeControl.value),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly selectedColumns$ = new ReplaySubject<string[]>(1);

  readonly availableColumns$ = new ReplaySubject<Column[]>(1);

  private readonly allowControls$ = new BehaviorSubject<string[]>(
    DEFAULT_ALLOWED_CONTROLS
  );

  public pageModel?: PageModel<any>;

  readonly reloadTrigger$ = new Subject<void>();

  readonly pageChange$ = new BehaviorSubject<number>(0);

  private readonly reloadPage$ = this.reloadTrigger$.pipe(
    switchMap(() => this.pageChange$)
  );

  readonly columns$ = this.selectedColumns$.pipe(
    withLatestFrom(this.availableColumns$),
    map(([selectedColumns, availableColumns]) =>
      availableColumns.filter(
        column => selectedColumns.indexOf(column.name) !== -1
      )
    )
  );

  readonly pageSizeToPageChange$ = this.pageSize$.pipe(
    switchMap(() => this.pageChange$)
  );

  readonly columnChangeToPageChange$ = this.selectedColumns$.pipe(
    switchMap(() => this.pageChange$),
    distinctUntilChanged()
  );

  readonly pageDataTrigger$ = this.reloadPage$.pipe(
    mergeWith(this.pageChange$),
    mergeWith(this.pageSizeToPageChange$),
    mergeWith(this.columnChangeToPageChange$)
  );

  readonly pageData$: Observable<PageModel<any>> = this.pageDataTrigger$.pipe(
    filter(() => !!this._dataRetriever),
    withLatestFrom(this.pageSize$),
    map(([pageNumber, pageSize]) => ({ pageNumber, pageSize })),
    withLatestFrom(this.columns$),
    map(([pageParameters, columns]) => {
      const sortedColumn = columns.find(column => column.sort !== null);

      let orderBy: DealSearchSortOption[] = this.defaultOrderBy;

      // For old tables without filtering
      if (!!sortedColumn) {
        orderBy = [
          {
            field: sortedColumn.name,
            direction: sortedColumn.sort?.toLowerCase() as 'asc' | 'desc',
          },
        ];
      }

      return {
        pageSize: pageParameters.pageSize,
        pageNumber: pageParameters.pageNumber,
        sort: orderBy,
        search: this.search,
        filter: this.activatedFilters,
        searchMode: this.searchMode,
      };
    }),
    distinctUntilChanged(),
    switchMap(({ pageNumber, pageSize, sort, search, filter, searchMode }) => {
      return this._dataRetriever!.load(
        pageNumber,
        pageSize,
        search,
        sort,
        filter,
        searchMode
      );
    }),

    map((page): PageModel<any> => {
      this.pageModel = page; // TODO: refactor and remove state
      return page;
    })
  );

  public optionCollapsed = true;

  public loading = false;

  readonly columnSelectionAllowed$ = this.allowControls$.pipe(
    map(allowedControls => allowedControls.indexOf('COLUMNS') > -1),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _dataRetriever?: PageRetrieverInterface<any>;

  @Input()
  set dataRetriever(value: PageRetrieverInterface<any>) {
    this._dataRetriever = value;
    this.reloadTrigger$.next();
  }

  @Input()
  small = false;

  @Input()
  defaultOrderBy: DealSearchSortOption[] = [{ field: '_id', direction: 'asc' }];

  @Input()
  set selectedColumns(value: string[]) {
    this.selectedColumns$.next(value);
  }

  @Input()
  set availableColumns(value: Column[]) {
    this.availableColumns$.next(value);
  }

  @Input()
  set allowControls(value: Controls[]) {
    this.allowControls$.next(value);
  }

  @Input()
  activatedFilters: DealSearchFilter[] = [];

  @Input()
  searchMode: DealSearchMode = 'all';

  @Input()
  search: string = '';

  constructor(private readonly changeDetection: ChangeDetectorRef) {}

  onColumnsChange(columns: Column[]) {
    this.selectedColumns$.next(columns.map(({ name }) => name));
    this.changeDetection.detectChanges();
  }
  /*
  onPageSizeChange(size: string){
    this.pageSize$.next(Number(size));
    this.changeDetection.detectChanges();
  }

 */

  onTriggerReload(): void {
    this.reloadTrigger$.next();
  }

  onPageChange(pageNumber: number) {
    this.pageChange$.next(pageNumber);
  }

  pageSizeOptionTransformer(option: number) {
    return option.toString();
  }
}
