<ng-container *ngIf="status === 'ready'">
  <eon-ui-file-upload
    (valueChanged)="fileChanged($event)"
    upload-url="/"
    show-upload-button="false"
    multiple-files="false"
  >
  </eon-ui-file-upload>
  <eon-ui-button
    text="Upload"
    *ngIf="selectedFile"
    (click)="uploadFile()"
  ></eon-ui-button>
</ng-container>
<ng-container *ngIf="status === 'uploading'">
  <eon-ui-preloader-squares
    loader-text="Processing ..."
  ></eon-ui-preloader-squares>
</ng-container>
<ng-container *ngIf="status === 'success'">
  <div class="gep-alert gep-alert-primary">Upload successful!</div>
  <eon-ui-button
    text="Go back"
    (click)="status = 'ready'; selectedFile = null"
  ></eon-ui-button>
</ng-container>
<ng-container *ngIf="status === 'failed'">
  <div class="gep-alert gep-alert-danger">
    Upload failed. This is what we know: {{ error_message }}
  </div>
  <eon-ui-button
    text="Go back"
    (click)="status = 'ready'; selectedFile = null"
  ></eon-ui-button>
</ng-container>
