import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gep-pagination-footer',
  templateUrl: './pagination-footer.component.html',
  styleUrls: ['./pagination-footer.component.scss'],
})
export class PaginationFooterComponent {
  @Input()
  public page = 0;

  @Input()
  public totalPages = 0;

  @Output() pageChanged = new EventEmitter<number>();

  get displayedPage() {
    return this.page + 1;
  }

  set displayedPage(val) {
    this.page = val - 1;
  }

  public pageChangedEvent(newDisplayedPage: number) {
    let nPage = newDisplayedPage - 1;
    if (nPage < 0) nPage = 0;
    if (nPage >= this.totalPages) nPage = this.totalPages - 1;
    this.pageChanged.emit(nPage);
  }

  addPage(number: number) {
    this.page += number;
    if (this.page >= this.totalPages) {
      this.page = this.totalPages - 1;
    } else if (this.page < 0) {
      this.page = 0;
    }
    this.pageChanged.emit(this.page);
  }
}
