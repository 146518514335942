import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { DayTime } from '../../models/day-time';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  ReplaySubject,
  withLatestFrom,
} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { mapToSteps } from '../../utils/binding-period-datetime-picker.functions';
import {
  mapToTimeFormControl,
  switchToTimeValueChangesSideEffects,
} from '../../utils/binding-period-time-dropdown.functions';

const DEFAULT_BEGIN_OF_BUSINESS = new DayTime(8, 0);
const DEFAULT_END_OF_BUSINESS = new DayTime(17, 0);
const DEFAULT_STEP_SIZE = 30;

@Component({
  selector: 'gep-binding-period-time-dropdown',
  templateUrl: './binding-period-time-dropdown.component.html',
  styleUrls: ['./binding-period-time-dropdown.component.scss'],
})
export class BindingPeriodTimeDropdownComponent
  implements ControlValueAccessor
{
  private readonly begin$ = new BehaviorSubject<DayTime>(
    DEFAULT_BEGIN_OF_BUSINESS
  );
  private readonly end$ = new BehaviorSubject<DayTime>(DEFAULT_END_OF_BUSINESS);
  private readonly stepSize$ = new BehaviorSubject<number>(DEFAULT_STEP_SIZE);
  private readonly value$ = new ReplaySubject<Date>(1);

  readonly steps$ = combineLatest([
    this.begin$,
    this.end$,
    this.stepSize$,
    this.value$,
  ]).pipe(
    map(([begin, end, stepSize]): [DayTime, DayTime, number] => [
      begin,
      end,
      stepSize,
    ]),
    mapToSteps(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly dropdownControl$ = this.steps$.pipe(
    withLatestFrom(this.value$),
    mapToTimeFormControl(),
    switchToTimeValueChangesSideEffects(
      this.value$,
      () => this.changeFn,
      () => this.touchFn
    ),
    distinctUntilChanged()
  );

  @Input()
  label: string = '';

  @Input()
  set beginOfBusiness(value: DayTime) {
    this.begin$.next(value);
  }

  @Input()
  set endOfBusiness(value: DayTime) {
    this.end$.next(value);
  }

  @Input()
  set stepSize(value: number) {
    if (value < 0 || value > 60) {
      throw new Error('Invalid step size');
    }

    this.stepSize$.next(value);
  }

  private changeFn?: (change: Date) => void;
  private touchFn?: () => void;

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  writeValue(obj: Date): void {
    this.value$.next(obj);
  }

  optionValueTransformer(option: DayTime): string {
    return option?.toString();
  }

  optionLabelTransformer(option: DayTime): string {
    return option.toString();
  }
}
