import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgGridHeaderComponent } from './ag-grid-header/ag-grid-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { EonUiComponentsAngularModule } from '@eon-ui/eon-ui-components-angular';
import { AgGridTableComponent } from './ag-grid-table/ag-grid-table.component';
import { AgGridAngular } from 'ag-grid-angular';
import { DealActionsRendererComponent } from './renderer/deal-actions-renderer/deal-actions-renderer.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CheckboxRendererComponent } from './renderer/checkbox-renderer/checkbox-renderer.component';
import { DateRendererComponent } from './renderer/date-renderer/date-renderer.component';
import { QuantityRendererComponent } from './renderer/quantity-renderer/quantity-renderer.component';
import { PriceRendererWithTransactionCostsComponent } from './renderer/price-renderer-with-transaction-costs/price-renderer-with-transaction-costs.component';
import { BoundOfferRendererComponent } from './renderer/bound-offer-renderer/bound-offer-renderer.component';
import { HorizonStatusRendererComponent } from './renderer/horizon-status-renderer/horizon-status-renderer.component';
import { HorizonStatusFloatingFilterComponent } from './filter/horizon-status-floating-filter/horizon-status-floating-filter.component';
import { HorizonStatusFilterComponent } from './filter/horizon-status-filter/horizon-status-filter.component';
import { PriceRendererComponent } from './renderer/price-renderer/price-renderer.component';
import { CounterpartUsersActionsRendererComponent } from './renderer/counterpart-users-actions-renderer/counterpart-users-actions-renderer.component';

@NgModule({
  declarations: [
    AgGridHeaderComponent,
    AgGridTableComponent,
    DealActionsRendererComponent,
    CheckboxRendererComponent,
    DateRendererComponent,
    QuantityRendererComponent,
    PriceRendererWithTransactionCostsComponent,
    PriceRendererComponent,
    BoundOfferRendererComponent,
    HorizonStatusRendererComponent,
    HorizonStatusFloatingFilterComponent,
    HorizonStatusFilterComponent,
    CounterpartUsersActionsRendererComponent,
  ],
  imports: [
    TranslateModule,
    CommonModule,
    EonUiComponentsAngularModule,
    AgGridAngular,
    NgbTooltip,
    FormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [AgGridTableComponent],
})
export class AgGridModule {}
