<eon-ui-radio-button
  [label]="label"
  [name]="name"
  [index]="index"
  [disabled]="disabled"
  [initially-checked]="initiallyChecked"
  [value]="value"
  [navigable]="navigable"
>
</eon-ui-radio-button>
