import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageModel } from '../models/page.model';
import { environment } from '../../environments/environment';
import { TaskModel } from '../models/task.model';
import { DealSearchSortOption } from '../modules/deal-search/model/deal-search-sort-option';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(private http: HttpClient) {}

  public getTasks(
    page = 0,
    page_size = 20,
    search: { [column: string]: string } = {},
    orderBy: DealSearchSortOption[] = [{ direction: 'asc', field: '_id' }]
  ): Observable<PageModel<TaskModel>> {
    return this.http.get<PageModel<TaskModel>>(
      `${
        environment.API
      }v1/tasks?page=${page}&page_size=${page_size}&orderby=${this.stringifyOrderBy(
        orderBy
      )}&query=${encodeURI(JSON.stringify(search))}`
    );
  }

  private stringifyOrderBy(orderBy: DealSearchSortOption[]) {
    return orderBy.map(x => x.field + ' ' + x.direction).join(',');
  }
}
