<eon-ui-navigation-meta slot="meta">
  <eon-ui-navigation-column contentAlignment="center" contentAlignmentMobile="center" grow="true" growMobile="true">
    <eon-ui-navigation-meta-link
      [text]="'DELIVERY_CONFIRMATION' | translate"
      [routerLink]="'/consignments'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/consignments'"
    ></eon-ui-navigation-meta-link>
    <eon-ui-navigation-meta-link
      [text]="'DELIVERY_INVALIDATION' | translate"
      [routerLink]="'/invalidations'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/invalidations'"
    ></eon-ui-navigation-meta-link>
    <eon-ui-navigation-meta-link
      *ngIf="isNotProd"
      [text]="'u20.deliveriesList.headline' | translate"
      [routerLink]="'/deliveries'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/deliveries'"
    ></eon-ui-navigation-meta-link>
  </eon-ui-navigation-column>
</eon-ui-navigation-meta>
<section>
  <div class="heading">
    <div>
      <eon-ui-headline
        [attr.text]="'UBA' | translate"
        size="h4"
        scheme="darkgrey"
      ></eon-ui-headline>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</section>
