import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';
import { DropdownColorScheme } from '../dropdown-deprecated/dropdown-deprecated.component';

@Component({
  selector: 'gep-dropdown-option',
  templateUrl: './dropdown-option.component.html',
  styleUrls: ['./dropdown-option.component.scss'],
})
export class DropdownOptionComponent implements AfterViewInit {
  /**
   * The name of the color scheme of the component.
   * If not set, the component will try to calculate it based on the nearest enclosing [eon-ui-background]{@link Background}.
   * Please do not use this Prop. It will be set internally by the E.ON UI Dropdown component.
   */
  @Input() scheme?: DropdownColorScheme;

  /**
   * Reflects the native html value attribute. There is no Default Value.
   */
  @Input() value?: any;

  /**
   * If it's set, the label will appear on the right hand side of the Option value. The label is a string that gives a description on what the value of the option means.
   */
  @Input() label?: string;

  /**
   * If true this Option will be focused. Default is false.
   */
  @Input() hasFocus: boolean = false;

  /**
   * Whether this is the currently selected Option. Default is false.
   */
  @Input() selected: boolean = false;

  /**
   * Whether this option is disabled.
   */
  @Input() disabled: boolean = false;

  constructor(private renderer: Renderer2, private elRef: ElementRef) {}

  ngAfterViewInit(): void {
    // dropdown group needs direct <eon-ui-dropdown-options>,
    // so remove host

    const el = this.elRef.nativeElement;
    const parent = this.renderer.parentNode(this.elRef.nativeElement);

    while (el.firstChild) {
      this.renderer.appendChild(parent, el.firstChild);
    }

    this.renderer.removeChild(parent, el, true);
  }
}
