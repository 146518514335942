export function dateToDatepickerString(date: Date): string {
  const dateString = date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const [month, day, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
}

export function datePickerStringToDate(datePickerString: string): Date {
  const [year, month, day] = datePickerString.split('-');

  return new Date(Number(year), Number(month) - 1, Number(day));
}
