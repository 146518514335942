<eon-ui-dropdown
  [attr.disabled]="disabled$ | async"
  [attr.label]="label$ | async"
  [attr.required]="required$ | async"
  [attr.size]="size"
  [attr.value]="value$ | async"
  custom-required-message="This field is required."
  [attr.height]="fixedHeight ? '18.4em' : undefined"
  multiSelect="true"
  (inputBlur)="onBlur($event)"
  (valueChanged)="onValueChanged($event)"
>
  <eon-ui-dropdown-option
    *ngFor="let optionWithLabel of optionsWithLabels$ | async"
    [attr.value]="optionWithLabel.value"
    [attr.label]="optionWithLabel.label"
  >
  </eon-ui-dropdown-option>
</eon-ui-dropdown>
