import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PageRetrieverInterface } from '../../components/pagination/page-retriever.interface';
import { DealModel } from '../../models/deal.model';
import { Column } from '../../components/pagination/column';
import { PaginationTableWrapperComponent } from '../../components/pagination/pagination-table-wrapper.component';
import { DealService } from '../../services/deal.service';
import {
  delay,
  distinctUntilChanged,
  exhaustMap,
  filter,
  finalize,
  map,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { BatchConsignmentService } from 'src/app/services/batch-consignment.service';
import { DealSearchSortOption } from '../../modules/deal-search/model/deal-search-sort-option';
import { Style } from '../../modules/gep-controls/models/style';
import { Icon } from '../../modules/gep-controls/models/icon';
import { ButtonSize } from '../../modules/gep-controls/models/button-size';
import { tap } from 'rxjs/operators';
import { ModalWithData } from '../../models/modal-with-data.model';
import { ConsignmentsFilterService } from '../../services/filter/consignments-filter.service';
import { DealSearchFilter } from '../../modules/deal-search/model/deal-search-filter';
import { DealSearchService } from '../../modules/deal-search/services/deal-search.service';
import { AcsSearchParams } from '../../models/acs-search-params.model';
import { QuantityUnit } from '../../models/quantity-unit.model';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'gep-delivery-consignments-page',
  templateUrl: './delivery-consignments-page.component.html',
  styleUrls: ['./delivery-consignments-page.component.scss'],
})
export class DeliveryConsignmentsPageComponent
  implements AfterViewInit, OnDestroy
{
  confirmOneModal: ModalWithData = new ModalWithData();

  confirmMultipleModal: ModalWithData = new ModalWithData();

  @ViewChild('paginator')
  public paginator!: PaginationTableWrapperComponent;

  @ViewChild('checkTemplate', { read: TemplateRef })
  public checkTemplate!: TemplateRef<any>;

  @ViewChild('subaccountTemplate', { read: TemplateRef })
  public subaccountTemplate!: TemplateRef<any>;

  @ViewChild('saveCheckTemplate', { read: TemplateRef })
  public saveCheckTemplate!: TemplateRef<any>;

  @ViewChild('statement_cancellation', { read: TemplateRef })
  public statementCancellationTemplate!: TemplateRef<any>;

  @ViewChild('quantityTemplate', { read: TemplateRef })
  public quantityTemplate!: TemplateRef<any>;

  @ViewChild('quantityInTonnsTemplate', { read: TemplateRef })
  public quantityInTonnsTemplate!: TemplateRef<any>;

  @ViewChild('redistributorTemplate', { read: TemplateRef })
  public redistributorTemplate!: TemplateRef<any>;

  @ViewChild('completionDateTemplate', { read: TemplateRef })
  public completionDateTemplate!: TemplateRef<any>;

  protected selectedRow?: any & DealModel;
  protected readonly Style = Style;
  protected readonly Icon = Icon;
  protected readonly ButtonSize = ButtonSize;

  protected defaultOrderBy: DealSearchSortOption[] = [
    { field: 'doc_id', direction: 'asc' },
  ];

  public dataRetriever: PageRetrieverInterface<DealModel> = {
    load: (
      page: number,
      page_size: number,
      search: string,
      orderby: DealSearchSortOption[],
      filter: DealSearchFilter[]
    ) => {
      const acsSearchParams: AcsSearchParams = {
        page: page,
        page_size: page_size,
        search: search,
        sort: orderby,
        version: 1,
        tempFilter: filter,
        searchMode: 'any',
        queryType: 'full',
      };

      return this.dealSearchService.getConsignments(acsSearchParams).pipe(
        map(([p]) => {
          p.data = p.value.map(row => {
            return row;
          });
          return p;
        })
      );
    },
  };

  public selectedColumns: string[] = [
    'id',
    'deal_id',
    'deal_type',
    'year',
    'customer',
    'counterpart_company',
    'quantityInMWh',
    'quantityInTonns',
    'segment',
    'product_key',
    'comment',
    'statement_cancellation',
    'quantity_delivery_confirmed',
    'check_subaccount',
    'ACTION',
  ];

  public availableColumns: Column[] = [];

  private destroy$ = new Subject<void>();

  public saveCheckBatch$ = new Subject<void>();

  public isSubmitting = false;

  public saveCheckBatchSideEffect$ = this.saveCheckBatch$
    .pipe(
      takeUntil(this.destroy$),
      switchMap(() => this.batchConsign.receiver$),
      map(deals =>
        deals
          .filter((deal: DealModel) => deal.quantity_delivery_confirmed)
          .map((deal: DealModel) => ({
            doc_id: deal.id!,
            quantity_delivery_confirmed: deal.quantity_delivery_confirmed,
            quantity_delivery_date: deal.quantity_delivery_date?.toISOString(),
            quantity_delivery_subaccount: deal.quantity_delivery_subaccount,
            redistributor: deal.redistributor,
          }))
      ),
      filter(deals => deals.length > 0),
      distinctUntilChanged(),
      exhaustMap(deals => this.dealService.updateDealConsignmentBatched(deals)),
      delay(900),
      tap(_ => this.toastService.showSavedToast())
    )
    .subscribe(() => {
      this.paginator.reloadTrigger$.next();
      this.isSubmitting = false;
    });

  constructor(
    private dealService: DealService,
    private batchConsign: BatchConsignmentService,
    private changeDetector: ChangeDetectorRef,
    private dealSearchService: DealSearchService,
    private toastService: ToastService,
    protected consignmentsFilterService: ConsignmentsFilterService
  ) {}

  setDataToTableAndReload() {
    this.paginator.activatedFilters =
      this.consignmentsFilterService.getActivatedFilters();
    this.paginator.reloadTrigger$.next();
  }

  ngAfterViewInit(): void {
    this.availableColumns = this.loadAvailableColumns();
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  checkClicked(row: any & DealModel, checked: any): void {
    row.quantity_delivery_confirmed = Boolean(checked);
    row.quantity_delivery_date = checked ? new Date() : null;
    row.quantity_delivery_subaccount = checked ? row.product_key : null;
  }

  saveCheck(row: { _submitting: boolean } & DealModel): void {
    row._submitting = true;
    this.confirmOneModal.close();
    this.dealService
      .updateDealConsignment(row.id!, {
        quantity_delivery_confirmed: row.quantity_delivery_confirmed,
        quantity_delivery_date: row.quantity_delivery_date,
        quantity_delivery_subaccount: row.quantity_delivery_subaccount,
        redistributor: row.redistributor,
      })
      .pipe(
        tap(_ => (this.selectedRow = undefined)),
        delay(900), // waiting for acs to update the index, so the deal disappears
        tap(_ => this.toastService.showSavedToast()),
        finalize(() => {
          row._submitting = false;
          this.paginator.reloadTrigger$.next();
        })
      )
      .subscribe();
  }

  public saveCheckBatched() {
    this.isSubmitting = true;
    this.confirmMultipleModal.close();
    this.saveCheckBatch$.next();
  }

  /*
  Private methods
   */

  private loadAvailableColumns(): Column[] {
    const columns = [
      new Column('deal_id'),
      new Column('deal_type').setWidth(160).setAlign('center'),
      new Column('year'),
      new Column('customer'),
      new Column('counterpart_company'),
      new Column('quantityInMWh').setTemplate(this.quantityTemplate),
      new Column('quantityInTonns').setTemplate(this.quantityInTonnsTemplate),
      new Column('product_key'),
      new Column('price'),
      new Column('closed_date').setTemplate(this.completionDateTemplate),
      new Column('redistributor')
        .setTemplate(this.redistributorTemplate)
        .setAlign('center'),
      new Column('vg'),
      new Column('region'),
      new Column('energy_source'),
      new Column('system_age'),
      new Column('comment'),
      new Column('statement_cancellation').setTemplate(
        this.statementCancellationTemplate
      ),
      new Column('id'),
      new Column('quantity_delivery_confirmed')
        .setTemplate(this.checkTemplate)
        .setAlign('center'),
      new Column('check_subaccount')
        .setTemplate(this.subaccountTemplate)
        .setSortable(false)

        .setWidth(200),
      new Column('ACTION')
        .setTemplate(this.saveCheckTemplate)
        .setSortable(false)

        .setAlign('right'),
    ];

    return columns;
  }

  setSelectedRow(row?: any & DealModel): void {
    this.selectedRow = row;
    this.confirmOneModal.show();
  }

  protected readonly QuantityUnit = QuantityUnit;
}
