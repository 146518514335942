import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DealModel } from '../../../models/deal.model';
import { environment } from '../../../../environments/environment';
import { Observable, of, ReplaySubject, startWith } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { ProductService } from '../../../services/product.service';

@Injectable()
export class EditEnquiryFacadeService {
  readonly activatedRoute$ = new ReplaySubject<ActivatedRoute>();

  readonly productConfigurations$ = this.productService
    .getProductConfigurations$()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  readonly selectedDealId$ = this.activatedRoute$.pipe(
    switchMap(activatedRoute => {
      return activatedRoute.params.pipe(
        startWith(activatedRoute.snapshot.params)
      );
    }),
    filter(params => !!params.dealId),
    map(({ dealId }) => dealId)
  );

  readonly deal$ = this.selectedDealId$.pipe(
    switchMap(dealId => this.getDealById$(dealId)),
    map(deal => {
      const modifiedDeal = structuredClone(deal);
      if (Array.isArray(deal.region)) {
        modifiedDeal.region = deal.region.join(', ');
      }
      return modifiedDeal;
    })
  );

  constructor(
    private readonly productService: ProductService,
    private readonly http: HttpClient,
    private readonly router: Router
  ) {}

  activateRoute(activatedRoute: ActivatedRoute) {
    this.activatedRoute$.next(activatedRoute);
  }

  getDealById$(dealId: string): Observable<DealModel> {
    return this.http.get<DealModel>(`${environment.API}v1/deals/${dealId}`);
  }

  updateDeal$(dealId: string, dealUpdate: DealModel): Observable<boolean> {
    return this.getDealById$(dealId).pipe(
      switchMap(deal => {
        return this.http
          .put<boolean>(`${environment.API}v1/deals/${dealId}`, {
            id: dealId,
            ...deal,
            ...dealUpdate,
          })
          .pipe(catchError(() => of(false)));
      })
    );
  }
}
