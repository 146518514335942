import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PaginationTableWrapperComponent } from '../../components/pagination/pagination-table-wrapper.component';
import { PageRetrieverInterface } from '../../components/pagination/page-retriever.interface';
import { DealModel } from '../../models/deal.model';
import { Column } from '../../components/pagination/column';
import { DealService } from '../../services/deal.service';
import { delay, finalize, startWith, Subject, tap } from 'rxjs';
import { Icon } from '../../modules/gep-controls/models/icon';
import { Style } from '../../modules/gep-controls/models/style';
import { InvalidationsFilterService } from '../../services/filter/invalidations-filter.service';
import { DealSearchSortOption } from '../../modules/deal-search/model/deal-search-sort-option';
import { DealSearchFilter } from '../../modules/deal-search/model/deal-search-filter';
import { DealSearchService } from '../../modules/deal-search/services/deal-search.service';
import { AcsSearchParams } from '../../models/acs-search-params.model';
import { NavigationExtras, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { ButtonSize } from '../../modules/gep-controls/models/button-size';
import { ModalWithData } from '../../models/modal-with-data.model';
import { QuantityUnit } from '../../models/quantity-unit.model';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'gep-delivery-invalidations-page',
  templateUrl: './delivery-invalidations-page.component.html',
  styleUrls: ['./delivery-invalidations-page.component.scss'],
})
export class DeliveryInvalidationsPageComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('paginator')
  public paginator!: PaginationTableWrapperComponent;

  @ViewChild('checkTemplate', { read: TemplateRef })
  public checkTemplate!: TemplateRef<any>;

  @ViewChild('checkDateTemplate', { read: TemplateRef })
  public checkDateTemplate!: TemplateRef<any>;

  @ViewChild('saveCheckTemplate', { read: TemplateRef })
  public saveCheckTemplate!: TemplateRef<any>;

  @ViewChild('cancellationTemplate', { read: TemplateRef })
  public cancellationTemplate!: TemplateRef<any>;

  @ViewChild('quantityTemplate', { read: TemplateRef })
  public quantityTemplate!: TemplateRef<any>;

  @ViewChild('quantityInTonnsTemplate', { read: TemplateRef })
  public quantityInTonnsTemplate!: TemplateRef<any>;

  @ViewChild('redistributorTemplate', { read: TemplateRef })
  public redistributorTemplate!: TemplateRef<any>;

  @ViewChild('closedDateTemplate', { read: TemplateRef })
  public closedDateTemplate!: TemplateRef<any>;

  dealModal: ModalWithData = new ModalWithData();

  public dataRetriever: PageRetrieverInterface<DealModel> = {
    load: (
      page: number,
      page_size: number,
      search: string,
      orderby: DealSearchSortOption[],
      filter: DealSearchFilter[]
    ) => {
      const acsSearchParams: AcsSearchParams = {
        page: page,
        page_size: page_size,
        search: search,
        sort: orderby,
        version: 1,
        tempFilter: filter,
        searchMode: 'any',
        queryType: 'full',
      };
      return this.dealSearchService.getInvalidations(acsSearchParams).pipe(
        map(([p]) => {
          p.data = p.value.map(row => {
            return row;
          });
          return p;
        })
      );
    },
  };

  public selectedColumns: string[] = [];
  public availableColumns: Column[] = [];
  protected selectedRow?: any & DealModel;

  selectedSum: number = 0;

  saveAllDisabled = true;

  _destroy$ = new Subject<void>();

  readonly Icon = Icon;
  readonly Style = Style;
  readonly ButtonSize = ButtonSize;

  protected defaultOrderBy: DealSearchSortOption[] = [
    { field: 'doc_id', direction: 'asc' },
  ];

  constructor(
    private dealSearchService: DealSearchService,
    private dealsService: DealService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private toastService: ToastService,
    protected invalidationsFilterService: InvalidationsFilterService
  ) {}

  ngAfterViewInit(): void {
    this.availableColumns = this.loadAvailableColumns();
    this.selectedColumns = [
      'deal_id',
      'deal_type',
      'statement_cancellation',
      'counterpart_company',
      'customer',
      'address',
      'quantityInMWh',
      'quantityInTonns',
      'completion_date',
      'quantity_cancelled',
      'quantity_cancellation_date',
      'ACTION',
      'id',
      'redistributor',
      'quantity_delivery_subaccount',
    ];
    this.paginator.reloadTrigger$.next();
    this.changeDetector.detectChanges();
  }

  setDataToTableAndReload() {
    this.paginator.activatedFilters =
      this.invalidationsFilterService.getActivatedFilters();
    this.paginator.reloadTrigger$.next();
  }

  checkClicked(
    row: any & DealModel,
    checked: any,
    redistributorChecked: boolean
  ): void {
    row.quantity_cancelled = Boolean(checked);
    row.redistributor = Boolean(redistributorChecked);
    this.calculateSum();
    this.saveAllDisabled = this.selectedSum === 0;
  }

  public saveCheck(row: any & DealModel): void {
    row._submitting = true;
    this.dealModal.close();
    this.dealsService
      .updateDealInvalidation(row.id, {
        quantity_cancelled: row.quantity_cancelled,
        quantity_cancellation_date: new Date().toISOString(),
        redistributor: row.redistributor,
      })
      .pipe(
        takeUntil(this._destroy$),
        delay(900),
        tap(_ => {
          this.selectedRow = undefined;
          this.saveAllDisabled = true;
          this.toastService.showSavedToast();
        }),
        finalize(() => {
          row._submitting = false;
          this.paginator.reloadTrigger$.next();
        })
      )
      .subscribe();
  }

  /*
  Private methods
 */

  private calculateSum() {
    this.selectedSum = this.paginator.pageModel?.data
      .filter(singleElement => singleElement.quantity_cancelled)
      .reduce((previous, current) => {
        return previous + current.quantityInMWh ?? 0;
      }, 0);
  }

  private loadAvailableColumns(): Column[] {
    const columns = [
      new Column('quantity_cancelled')
        .setTemplate(this.checkTemplate)
        .setSortable(false)
        .setAlign('center'),
      new Column('deal_id'),
      new Column('deal_type').setWidth(160).setAlign('center'),
      new Column('year'),
      new Column('product_key'),
      new Column('price'),
      new Column('redistributor')
        .setTemplate(this.redistributorTemplate)
        .setAlign('center'),
      new Column('vg'),
      new Column('region'),
      new Column('segment'),
      new Column('customer'),
      new Column('address'),
      new Column('quantityInMWh').setTemplate(this.quantityTemplate),
      new Column('quantityInTonns').setTemplate(this.quantityInTonnsTemplate),
      new Column('energy_source'),
      new Column('system_age'),
      new Column('comment'),
      new Column('closed_date').setTemplate(this.closedDateTemplate),
      new Column('quantity_delivery_subaccount'),
      new Column('counterpart_company'),
      new Column('quantity_delivery_confirmed').setAlign('center'),
      new Column('statement_cancellation')
        .setAlign('center')
        .setTemplate(this.cancellationTemplate)
        .setSortable(false),
      new Column('id').setSortable(true),
      new Column('ACTION')
        .setTemplate(this.saveCheckTemplate)
        .setSortable(false)
        .setAlign('right'),
    ];
    return columns;
  }

  redirectToOverviewPage() {
    const tickedRows: DealModel[] = this.paginator.pageModel?.data.filter(
      data => data.quantity_cancelled
    ) as unknown as DealModel[];

    if (tickedRows) {
      const navigationExtras: NavigationExtras = {
        state: { rows: tickedRows, sum: this.selectedSum },
      };
      this.router.navigate(['invalidations-overview'], navigationExtras);
    }
  }

  selectAll() {
    this.saveAllDisabled = false;
    this.paginator.pageModel?.data.forEach(data => {
      data.quantity_cancelled = true;
    });

    this.calculateSum();
  }

  setSelectedRow(row?: any & DealModel): void {
    this.selectedRow = row;
    this.dealModal.show();
  }

  protected readonly QuantityUnit = QuantityUnit;

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
