import { map, switchMap, tap } from 'rxjs/operators';
import { DayTime } from '../models/day-time';
import { BindingPeriodFormModel } from '../models/binding-period-form-model';
import { startWith } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

export function mapToSteps() {
  return map(([begin, end, stepSize]: [DayTime, DayTime, number]) => {
    const endMinutes = end.toMinutes();
    let beginMinutes = begin.toMinutes();

    let steps: DayTime[] = [];
    while (beginMinutes < endMinutes) {
      steps = [...steps, DayTime.fromMinutes(beginMinutes)];
      beginMinutes = beginMinutes + stepSize;
    }
    if (!steps[steps.length - 1].equals(end)) {
      steps = [...steps, end];
    }
    return steps;
  });
}

export function switchToValueChangeSideEffects(
  getDateFromInnerFormModel: (model: BindingPeriodFormModel) => Date | null,
  changeFnFactory: () => ((change: any) => void) | undefined,
  touchFnFactory: () => (() => void) | undefined
) {
  return switchMap((form: UntypedFormGroup) =>
    form.valueChanges.pipe(
      tap(() => {
        const formModel: BindingPeriodFormModel = form.getRawValue();
        const changeFn = changeFnFactory();
        if (changeFn) {
          const date = getDateFromInnerFormModel(formModel);
          changeFn(date);
        }

        const touchFn = touchFnFactory();
        if (touchFn) {
          touchFn();
        }
      }),
      map(() => form),
      startWith(form)
    )
  );
}

export function switchToDateTimeSideEffect() {
  return switchMap((form: UntypedFormGroup) => {
    return form.get('date')!.valueChanges.pipe(
      map((valueChange: Date) => {
        if (valueChange === null) {
          return;
        }
        if (form.get('time')?.value) {
          form.get('time')!.setValue(form.get('time')?.value);
        }
      }),
      map(() => form),
      startWith(form)
    );
  });
}
