import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DistinctPropertiesModel,
  FilterFor,
} from '../models/distinct-properties.model';

@Injectable({
  providedIn: 'root',
})
export class DistinctPropertiesService {
  constructor(private http: HttpClient) {}

  public getDistinctPropertiesForClosedDeals(): Observable<DistinctPropertiesModel> {
    return this.getDistinctProperties(FilterFor.CLOSED_DEALS);
  }

  public getDistinctPropertiesForInvalidations(): Observable<DistinctPropertiesModel> {
    return this.getDistinctProperties(FilterFor.INVALIDATIONS);
  }

  public getDistinctPropertiesForConsignments(): Observable<DistinctPropertiesModel> {
    return this.getDistinctProperties(FilterFor.CONSIGNMENTS);
  }

  private getDistinctProperties(
    filter: FilterFor
  ): Observable<DistinctPropertiesModel> {
    return this.http.get<DistinctPropertiesModel>(
      `${environment.API}v1/deals/properties?filter=${FilterFor[filter]}`
    );
  }
}
