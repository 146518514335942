import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  BehaviorSubject,
  distinctUntilChanged,
  ReplaySubject,
  startWith,
  Subject,
  Subscription,
} from 'rxjs';
import { Components } from '@eon-ui/eon-ui-components';
import EonUiInput = Components.EonUiInput;
import { ButtonSize } from '../../../gep-controls/models/button-size';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gep-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  label: string = '';

  @Input()
  placeholder?: string;

  @Input()
  size: ButtonSize = ButtonSize.Normal;

  @Input()
  errorState: boolean = false;

  @Input()
  labelOutside: boolean = false;

  @Input()
  requiredStar: boolean = false;

  get formattedLabel(): string {
    return this.label + (this.requiredStar ? '*' : '');
  }

  @Output()
  valueSelected = new EventEmitter<string>();

  @ViewChild('eon-ui-input')
  eonUiInput?: EonUiInput;

  readonly hasError$ = new ReplaySubject<boolean>();

  readonly showError$ = this.hasError$.pipe(startWith(false));

  readonly disabled$ = new BehaviorSubject<boolean>(false);

  readonly value$ = new ReplaySubject<string>(1);

  internalName: string | null = null;

  changeFn?: (change: string) => void;
  touchFn?: () => void;
  _destroy$ = new Subject<void>();

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.internalName = this.ngControl ? String(this.ngControl.name) : '';

    this.value$
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.valueSelected.emit(value);
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  registerOnChange(fn: (change: string) => void): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }

  writeValue(obj: string): void {
    if (!!this.ngControl.control) {
      this.disabled$.next(this.ngControl.control.disabled);
    }
    this.value$.next(obj);

    this.hasError$.next(!!this.ngControl.control?.errors);
  }

  onValueChange(event: Event) {
    const change = (<CustomEvent>event).detail;
    this.value$.next(change);
    if (this.touchFn) {
      this.touchFn();
    }

    if (this.changeFn) {
      this.changeFn(change);
    }
    if (!this.ngControl?.control?.pristine && this.ngControl?.control?.errors) {
      this.eonUiInvalid();
    }
  }

  onInputBlur() {
    if (this.touchFn) {
      this.touchFn();
    }
  }

  async eonUiInvalid() {
    if (this.eonUiInput) {
      await this.eonUiInput.handleCustomValidation('asd');
    }
  }
}
