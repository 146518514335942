<eon-ui-button
  [attr.scheme]="style"
  [attr.icon]="icon"
  [attr.size]="size"
  [attr.input-type]="type"
  [attr.text]="label"
  [attr.disabled]="disabled"
  [attr.open-modal-id]="openModalId"
  (buttonClick)="onButtonClick()"
>
</eon-ui-button>
