


<div class="wrapper">
  <eon-ui-input [disabled]="disabled" [value]="selectedHour + ':' + selectedMinute" readOnly="true" (click)="showPicker()" trailingIconName="clock"></eon-ui-input>
  <div *ngIf="isPickerVisible" class="time-picker">
    <div class="select-time">
      <ul>
        <li *ngFor="let hour of hours" (click)="selectHour(hour)" [ngClass]="{'selected': selectedHour === hour}">{{hour}}</li>
      </ul>
      <ul>
        <li *ngFor="let minute of minutes" (click)="selectMinute(minute)" [ngClass]="{'selected': selectedMinute === minute}">{{minute}}</li>
      </ul>
    </div>
    <eon-ui-button [size]="ButtonSize.Small" text="OK" (click)="hidePicker()"></eon-ui-button>
  </div>
</div>
