import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { RangeValues } from '../../models/range-values';

@Component({
  selector: 'gep-slider',
  styleUrls: ['./slider.component.scss'],
  templateUrl: './slider.component.html',
})
export class SliderComponent {
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() step?: number = 1;
  @Input() disabled?: boolean;

  @Input() firstValue?: number;
  @Input() secondValue?: number;

  @Output() sliderValueChanged = new EventEmitter<RangeValues>();

  @ViewChild('firstInput')
  firstInput!: ElementRef;

  @ViewChild('secondInput')
  secondInput!: ElementRef;

  onSliderChanged() {
    let slide1 = Number(this.firstInput.nativeElement.value);
    let slide2 = Number(this.secondInput.nativeElement.value);

    // Neither slider will clip the other, so make sure we determine which is larger
    if (slide1 > slide2) {
      const tmp = slide2;
      slide2 = slide1;
      slide1 = tmp;
    }

    this.firstValue = slide1;
    this.secondValue = slide2;

    this.sliderValueChanged.emit({
      firstValue: this.firstValue,
      secondValue: this.secondValue,
    });
  }

  setSliderValues(firstValue: number, secondValue: number) {
    this.firstValue = firstValue;
    this.secondValue = secondValue;
  }
}
