import { combineLatestWith, map, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  distinctUntilChanged,
  Observable,
  OperatorFunction,
  ReplaySubject,
  startWith,
  withLatestFrom,
} from 'rxjs';
import { EnquiryFormBuilderService } from '../services/enquiry-form-builder.service';
import { DealModel, Status } from '../../../models/deal.model';
import { ProductConfigModel } from '../../../models/product-config.model';
import { ProductSelectionFormModel } from '../models/product-selection-form-model';
import { FormArray, UntypedFormGroup } from '@angular/forms';

export function switchToTooltipTranslations(
  translateService: TranslateService
) {
  return (source$: Observable<string[]>) =>
    source$.pipe(
      map(dealTypes =>
        dealTypes.map(dealType => ({
          value: dealType,
          tooltip: `enquiryForm.dealTypes.tooltips.${dealType}`,
        }))
      ),
      map(dealTypesWithTooltips => {
        let initialTranslation$: Observable<
          { value: string; tooltip: string }[]
        >;
        const translations$ = dealTypesWithTooltips
          .map((dealTypeWithTooltip, index) => {
            const translation$ = translateService
              .get(dealTypeWithTooltip.tooltip)
              .pipe(
                map(tooltip => ({
                  value: dealTypeWithTooltip.value,
                  tooltip,
                }))
              );

            if (index === 0) {
              initialTranslation$ = translation$.pipe(map(inner => [inner]));
            }

            return translation$;
          })
          .filter((_, index) => index > 0);

        return translations$.reduce(
          (previous$, current$) =>
            previous$.pipe(
              combineLatestWith(current$),
              map(([previous, current]) => [...previous, current])
            ),
          initialTranslation$!
        );
      }),
      switchMap(
        (translations$: Observable<{ value: string; tooltip: string }[]>) =>
          translations$
      )
    );
}

export function mapToFormGroup(formBuilderService: EnquiryFormBuilderService) {
  return map(
    ([deal, productConfigurations]: [
      DealModel | null,
      ProductConfigModel[]
    ]) => {
      const formGroup = !!deal
        ? formBuilderService.getDealForm(deal, productConfigurations)
        : formBuilderService.getDealForm();

      if (!!deal) {
        formBuilderService.disableFieldsForExistingDeal(formGroup, deal.status);
      }

      return formGroup;
    }
  );
}

export function switchToCommentSideEffect(
  commentsForProductSelection$: Observable<Record<string, string>>,
  dealModel$: ReplaySubject<DealModel | null>
) {
  return switchMap((form: UntypedFormGroup) => {
    return form.get('productSelection')!.valueChanges.pipe(
      withLatestFrom(commentsForProductSelection$, dealModel$),
      tap(
        ([productSelection, comments, dealModel]: [
          ProductSelectionFormModel,
          Record<string, string>,
          DealModel | null
        ]) => {
          if (!!productSelection.productConfig?.key) {
            const commentForSelection =
              comments[productSelection.productConfig.key];

            const commentControl = form.get('comment');
            if (dealModel?.comment) {
              commentControl?.setValue(dealModel.comment);
            } else if (!!commentForSelection && commentForSelection !== '') {
              commentControl?.setValue(commentForSelection);
            } else {
              commentControl?.setValue('');
            }
            if (dealModel?.status === Status.BINDING) {
              commentControl?.disable();
            }
          }
        }
      ),
      map(() => form),
      startWith(form),
      distinctUntilChanged()
    );
  });
}

export function switchToTranslationTransfomerFn(
  translateService: TranslateService,
  translatePrefix: string = ''
): OperatorFunction<string[], (option: string) => string> {
  return switchMap((options: string[]) => {
    let firstDealTypeTranslation$: Observable<
      { option: string; translation: string }[]
    >;
    const mappedOptions = options
      .map((option, index) => {
        const translation$ = translateService
          .get(`${translatePrefix}${option}`)
          .pipe(
            map(translation => ({
              option,
              translation,
            }))
          );
        if (index === 0) {
          firstDealTypeTranslation$ = translation$.pipe(map(inner => [inner]));
        }

        return translation$;
      })
      .filter((_, index) => index > 0);

    return reduceObservableList(mappedOptions, firstDealTypeTranslation$!).pipe(
      mapToTransformerFn()
    );
  });
}

function reduceObservableList(
  mappedOptions: Observable<{ option: string; translation: string }>[],
  initial$: Observable<{ option: string; translation: string }[]>
) {
  return mappedOptions.reduce(
    (previous$, current$) =>
      previous$.pipe(
        combineLatestWith(current$),
        map(([previous, current]) => [...previous, current])
      ),
    initial$
  );
}

function mapToTransformerFn() {
  return map(
    (optionsAndTranslations: { option: string; translation: string }[]) => {
      return (option: string) => {
        return optionsAndTranslations.find(
          optionAndTranslation => optionAndTranslation.option === option
        )!.translation;
      };
    }
  );
}

export function switchToRedistributorSideEffect() {
  return switchMap((form: UntypedFormGroup): Observable<UntypedFormGroup> => {
    return form.get('vg')!.valueChanges.pipe(
      map(vgChange => {
        const redistributorControl = form.get('redistributor')!;
        if (vgChange && vgChange.toLowerCase() === 'edg') {
          redistributorControl.setValue('false');
          redistributorControl.enable();
          return;
        }

        redistributorControl.setValue('true');
        redistributorControl.disable();
      }),
      map(() => form),
      distinctUntilChanged(),
      startWith(form)
    );
  });
}

export function fromTonToMwhConverter(valueInTonns: number, factor: number) {
  if (!valueInTonns) {
    return undefined;
  }

  if (!factor || valueInTonns === 0) {
    return valueInTonns;
  }

  return valueInTonns / factor;
}

export function getCO2FactorFromProductSelection(
  form: UntypedFormGroup
): number | undefined {
  const productSelectionValue = form.get('productSelection')?.value;
  return productSelectionValue?.productConfig?.displayed_quantity?.factor;
}

export function quantityTonnsToMWhFieldsSideEffect(form: UntypedFormGroup) {
  const isCo2Product = !!getCO2FactorFromProductSelection(form);

  const deliveries: FormArray = form.get('deliveries') as FormArray;
  for (const delivery of deliveries.controls) {
    if (isCo2Product) {
      // IMPORTANT: emitEvent must be set to false, otherwise there will be an
      // endless loop because deliveries are always changed
      delivery.get('quantity')?.disable({ emitEvent: false });
    } else {
      delivery.get('quantity')?.enable({ emitEvent: false });
      delivery.get('quantityInTonns')?.setValue(undefined);
    }
  }

  return form;
}

export function quantityTonnsToMWhValueSideEffect(form: UntypedFormGroup) {
  const deliveries: FormArray = form.get('deliveries') as FormArray;

  const co2Factor = getCO2FactorFromProductSelection(form);
  const isCo2Product = !!co2Factor;

  if (!isCo2Product) {
    return;
  }

  for (let delivery of deliveries.controls) {
    const quantityInTonnsValue = delivery.get('quantityInTonns')?.value;

    delivery.get('quantity')?.disable({ emitEvent: false });
    if (quantityInTonnsValue) {
      const convertedValue = fromTonToMwhConverter(
        quantityInTonnsValue,
        co2Factor
      );

      const outputValue = convertedValue
        ? Number(convertedValue.toFixed(5))
        : undefined;

      delivery.get('quantity')?.setValue(outputValue, { emitEvent: false });
    } else {
      delivery.get('quantity')?.setValue(null, { emitEvent: false });
    }
  }
}
