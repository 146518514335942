import { CanActivateChildFn } from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const TranslationLoaderGuard: CanActivateChildFn = (childRoute, _) => {
  const translateService = inject(TranslateService);

  return new Observable(observer => {
    translateService.get('last.dummy').subscribe({
      next: () => {
        observer.next(true);
      },
      error: error => {
        observer.next(false);
        observer.error(error);
      },
      complete: () => {
        observer.complete();
      },
    });
  });
};
