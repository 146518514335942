<ng-container *ngIf="steps$ | async as steps">
  <ng-container *ngIf="dropdownControl$ | async as dropdownControl">
    <gep-dropdown
      [label]="label"
      [optionValueTransformer]="optionValueTransformer"
      [optionLabelTransformer]="optionLabelTransformer"
      [options]="steps"
      [formControl]="dropdownControl"
    ></gep-dropdown>
  </ng-container>
</ng-container>
