import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadlineComponent } from './components/headline/headline.component';
import { ModalComponent } from './components/modal/modal.component';
import { GepControlsModule } from '../gep-controls/gep-controls.module';

@NgModule({
  declarations: [HeadlineComponent, ModalComponent],
  imports: [CommonModule, GepControlsModule],
  exports: [HeadlineComponent, ModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonsModule {}
