<div
  *ngIf="isQA"
  style="
    background-color: #ea1b0a;
    color: white;
    text-align: center;
    font-size: x-large;
    font-weight: 500;
  "
>
  QA ENVIRONMENT
</div>
