import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Inject,
  LOCALE_ID,
  OnDestroy,
} from '@angular/core';
import { InventoryKpiModel } from '../../models/inventory-kpi.model';
import { KpiService } from '../../services/kpi.service';
import { map, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'gep-inventory-kpis',
  templateUrl: './inventory-kpis.component.html',
  styleUrls: ['./inventory-kpis.component.scss'],
})
export class InventoryKpisComponent implements OnChanges, OnInit, OnDestroy {
  @Input() year: number = 1970;

  public kpis: InventoryKpiModel = {};
  public sales: number = 0;
  public buys: number = 0;
  public delta: number = 0;
  public pnl: number = 0;

  private _destroying$ = new Subject<void>();

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private kpiService: KpiService
  ) {}

  ngOnInit(): void {
    this.getYearsKPIs(this.year);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.year = changes.year.currentValue;
    this.getYearsKPIs(this.year);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
  }

  getYearsKPIs(year: number): void {
    // this.kpiService.getInventoryKpis(year).then(result => this.mapInventoryKpis(result));
    this.kpiService
      .getInventoryKpis$(year)
      .pipe(
        map(result => this.mapInventoryKpis(result)),
        takeUntil(this._destroying$)
      )
      .subscribe();
  }

  private mapInventoryKpis(kpis: InventoryKpiModel): void {
    const { sales, buys, delta, pnl } = kpis;

    this.kpis = kpis;
    this.sales = sales ?? 0;
    this.buys = buys ?? 0;
    this.delta = delta ?? 0;
    this.pnl = pnl ?? 0;
  }
}
