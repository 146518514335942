import { Component } from '@angular/core';
import { Role } from '../../models/user.model';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'gep-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  readonly ROLE = Role;

  profileDropdownIsOpen: boolean = false;

  readonly profile$ = this.userService.profile$;
  readonly email$ = this.userService.email$;

  constructor(private userService: UserService) {}

  logout() {
    this.userService.logout();
  }

  toggleProfileDropdown() {
    this.profileDropdownIsOpen = !this.profileDropdownIsOpen;
  }
}
