<div class="sales-inquiry">
  <div class="flex-row">
    <h4>{{ 'SALES_INQUIRY' | translate }}</h4>
  </div>

  <div class="flex-row row-wrapper">
    <h1>{{ 'APPOINTMENT_DUE' | translate }}</h1>

    <div class="right-content">
      <div class="expiration-wrapper">
        <eon-ui-icon size="small" scheme="red" name="gas"></eon-ui-icon>
        <span>Expires on</span>
      </div>
      <span>{{ task.deadline | date: 'dd.MM.YYYY' }}</span>
    </div>
  </div>
  <div class="flex-row row-wrapper">
    <div class="flex-col row-wrapper">
      <div class="flex-row row-view-wrapper headers">
        <div class="flex-col">
          {{ 'COMPANY_NAME' | translate }}
        </div>
        <div class="flex-col">
          {{ 'DATE' | translate }}
        </div>
        <div class="flex-col">
          {{ 'delivery_quantity' | translate }}
        </div>
        <div class="flex-col">
          {{ 'QUALITY' | translate }}
        </div>
      </div>

      <div class="flex-row row-view-wrapper">
        <div class="flex-col">{{ task.customer }}</div>
        <div class="flex-col">
          {{ task.created_at | date: 'dd.MM.YYYY' }}
        </div>
        <div class="flex-col">{{ task.quantity }}</div>
        <div class="flex-col">{{ task.category }}</div>
      </div>
    </div>
    <eon-ui-button text="Process request" scheme="turquoise"></eon-ui-button>
  </div>
</div>
