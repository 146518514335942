import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuantityPipe } from './quantity.pipe';
import { TimeLeftPipe } from './time-left.pipe';
import { NumeralPipe } from './numeral.pipe';
import { HasAnyRolePipe } from './has-any-role.pipe';
import { BestPricePipe } from './best-price.pipe';
import { InformCounterpartButtonPipe } from './inform-counterpart-button.pipe';
import { LocalizeDatePipe } from './localize-date.pipe';
import { GroupDealsByYearPipe } from './group-deals-by-year.pipe';
import { ShortenStringReadMorePipe } from './shorten-string-read-more.pipe';

@NgModule({
  declarations: [
    QuantityPipe,
    TimeLeftPipe,
    NumeralPipe,
    HasAnyRolePipe,
    BestPricePipe,
    InformCounterpartButtonPipe,
    LocalizeDatePipe,
    GroupDealsByYearPipe,
    ShortenStringReadMorePipe,
    QuantityPipe,
  ],
  exports: [
    TimeLeftPipe,
    QuantityPipe,
    NumeralPipe,
    HasAnyRolePipe,
    BestPricePipe,
    InformCounterpartButtonPipe,
    LocalizeDatePipe,
    GroupDealsByYearPipe,
    ShortenStringReadMorePipe,
    QuantityPipe,
  ],
  imports: [CommonModule],
})
export class PipesModule {}
