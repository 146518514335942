import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
} from '@angular/core';
import { TreeNode } from '../../../../models/tree-node.model';

@Component({
  selector: 'gep-checkbox-tree',
  templateUrl: './checkbox-tree.component.html',
  styleUrls: ['./checkbox-tree.component.scss'],
})
export class CheckboxTreeComponent implements OnChanges {
  @Input() items: TreeNode[] = [];
  @Output() itemsChange: EventEmitter<TreeNode[]> = new EventEmitter<
    TreeNode[]
  >();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.initializeTreeState(this.items);
      this.itemsChange.emit(this.items);
    }
  }

  initializeTreeState(nodes: TreeNode[]): void {
    nodes.forEach(node => {
      if (node.children) {
        this.initializeTreeState(node.children);
      }
      this.updateParentState(node);
    });
  }

  checkAll(item: TreeNode) {
    item.indeterminate = false;
    if (item.children) {
      item.children.forEach((child: TreeNode) => {
        child.checked = item.checked;
        child.indeterminate = false;
      });
      this.updateParentState(item);
    }
    this.itemsChange.emit(this.items);
  }

  checkParent(item: TreeNode) {
    if (item.children) {
      item.checked = item.children.every((child: TreeNode) => child.checked);
      item.indeterminate =
        item.children.some((child: TreeNode) => child.checked) && !item.checked;
      this.updateParentState(item);
    }
    this.itemsChange.emit(this.items);
  }

  updateParentState(item: TreeNode) {
    const parent = this.findParent(item, this.items);
    if (parent) {
      parent.checked =
        parent.children?.every((child: TreeNode) => child.checked) ?? false;
      parent.indeterminate =
        (parent.children?.some((child: TreeNode) => child.checked) ?? false) &&
        !parent.checked;
      this.updateParentState(parent);
    }
  }

  findParent(child: TreeNode, nodes: TreeNode[]): TreeNode | null {
    for (let node of nodes) {
      if (node.children && node.children.includes(child)) {
        return node;
      } else if (node.children) {
        const parent = this.findParent(child, node.children);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  }

  selectAll(value: boolean) {
    this.items.forEach(x => {
      x.checked = value;
      x.indeterminate = false;
      x.children?.forEach(y => {
        y.checked = value;
        y.indeterminate = false;
      });
    });
    this.itemsChange.emit(this.items);
  }
}
