import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateEnquiryComponent } from './components/create-enquiry/create-enquiry.component';
import { EditEnquiryComponent } from './components/edit-enquiry/edit-enquiry.component';
import { EnquiryFormBuilderService } from './services/enquiry-form-builder.service';
import { BindingPeriodDatetimePickerComponent } from './components/binding-period-datetime-picker/binding-period-datetime-picker.component';
import { GepFormsModule } from '../gep-forms/gep-forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BindingPeriodTimeDropdownComponent } from './components/binding-period-time-dropdown/binding-period-time-dropdown.component';
import { ProductSelectionComponent } from './components/product-selection/product-selection.component';
import { EnquiryFormComponent } from './components/enquiry-form/enquiry-form.component';
import { EnquiryFormFassadeService } from './services/enquiry-form-fassade.service';
import { GepControlsModule } from '../gep-controls/gep-controls.module';
import { CreateEnquiryFacadeService } from './services/create-enquiry-facade.service';
import { EnquiryPrefillService } from './services/enquiry-prefill.service';
import { EditEnquiryFacadeService } from './services/edit-enquiry-facade.service';
import { CommonsModule } from '../commons/commons.module';
import { DealsModule } from '../deals/deals.module';

@NgModule({
  declarations: [
    CreateEnquiryComponent,
    EditEnquiryComponent,
    BindingPeriodDatetimePickerComponent,
    BindingPeriodTimeDropdownComponent,
    ProductSelectionComponent,
    EnquiryFormComponent,
  ],
  imports: [
    CommonModule,
    GepFormsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    GepControlsModule,
    CommonsModule,
    DealsModule,
  ],
  providers: [
    EnquiryFormBuilderService,
    EnquiryFormFassadeService,
    CreateEnquiryFacadeService,
    EnquiryPrefillService,
    EditEnquiryFacadeService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EnquiryModule {}
