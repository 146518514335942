<gep-settings-tab-header></gep-settings-tab-header>

<main class="d-flex flex-row w-100">
  <div class="d-flex w-100">
    <div style="flex: 1;" class="h-100 me-4">
      <h2>{{ 'settings.enquiryTemplates.selectTemplate' | translate }}</h2>

      <gep-select-list *ngIf="templates$ | async as templates" [options]="templates" (itemSelected)="selectTemplate($event);"></gep-select-list>

      <eon-ui-button class="mt-2" [text]="'settings.enquiryTemplates.createNewTemplate' | translate"  (click)="createNewTemplate()" fullWidth="true"></eon-ui-button>
    </div>
    <div style="flex: 4; border-left: 1px solid grey;" class="position-relative">
      <ng-container *ngIf="showForm else errorNoTemplateSelected">
        <eon-ui-button class="delete-product position-absolute" [icon]="Icon.Trash" (click)="showDeleteModal()" [text]="'settings.enquiryTemplates.deleteTemplate' | translate" [disabled]="isNewTemplate"></eon-ui-button>
        <h2>{{ 'settings.enquiryTemplates.editTemplate' | translate }}</h2>
        <small class="d-block text-center"><i>{{'settings.mandatoryFields' | translate}}</i></small>

        <form [formGroup]="form">
          <fieldset>
            <gep-input [label]="'settings.enquiryTemplates.contactPerson' | translate"  [labelOutside]="true" [requiredStar]="true" formControlName="contact_person"></gep-input>
            <gep-input [label]="'settings.enquiryTemplates.customer' | translate" [labelOutside]="true" [requiredStar]="true" formControlName="customer"></gep-input>
          </fieldset>

          <fieldset>
            <gep-input [label]="'settings.enquiryTemplates.address' | translate" [labelOutside]="true" [requiredStar]="true" formControlName="address"></gep-input>
            <gep-radio-button-group
              [label]="'settings.enquiryTemplates.redistributor' | translate"
              [requiredStar]="true"
              formControlName="redistributor"
            >
              <gep-radio-button label="true" value="true"></gep-radio-button>
              <gep-radio-button label="false" value="false"></gep-radio-button>
            </gep-radio-button-group>
          </fieldset>

          <fieldset>
            <gep-dropdown
              *ngIf="vgs$ | async as vgs"
              [options]="vgs"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [labelOutside]="true"
              [requiredStar]="true"
              [label]="'settings.enquiryTemplates.vg' | translate"
              formControlName="vg"
            ></gep-dropdown>
            <gep-dropdown
              *ngIf="segments$ | async as segments"
              [options]="segments"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [labelOutside]="true"
              [requiredStar]="true"
              [label]="'settings.enquiryTemplates.segment' | translate"
              formControlName="segment"
            ></gep-dropdown>
          </fieldset>

          <fieldset>
            <gep-dropdown
              *ngIf="products$ | async as products"
              [options]="products"
              [labelOutside]="true"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [requiredStar]="true"
              [label]="'settings.enquiryTemplates.product' | translate"
              formControlName="product_key"
            ></gep-dropdown>
            <gep-dropdown
              [options]="dealTypes"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [labelOutside]="true"
              [requiredStar]="true"
              [label]="'settings.enquiryTemplates.dealType' | translate"
              formControlName="deal_type"
            ></gep-dropdown>
          </fieldset>

          <div class="text-center">
            <eon-ui-button [text]="'settings.save' | translate" [disabled]="!this.form.valid"  (click)="saveTemplate()"></eon-ui-button>
          </div>

        </form>
      </ng-container>

      <ng-template #errorNoTemplateSelected>
        <p style="margin: auto; width: 100%; text-align: center;">{{'settings.enquiryTemplates.noTemplateSelected' | translate}}</p>
      </ng-template>

      <gep-modal
        [visible]="deleteModalVisible"
        [headline]="'settings.enquiryTemplates.deleteModal.headline' | translate"
        [description]="'settings.enquiryTemplates.deleteModal.question' | translate"
        [positiveButtonStyle]="Style.Highlight"
        [closeButtonText]="'no' | translate"
        [positiveButtonText]="'yes' | translate"
        (positiveButtonClicked)="deleteTemplate()"
        (closedButtonClicked)="deleteModalVisible = false;"
        [showClosingX]="true"
        modalId="delete-template-modal"
      ></gep-modal>


    </div>
  </div>
</main>
