export enum Role {
  ADMIN_SYS = 'SYSADMIN',
  ADMIN_GEP = 'ADMINGEP',
  READONLY = 'READONLY',
  GREENDESK = 'GREENDESK',
  PRODUCER = 'PRODUCER',
  SALES = 'SALES',
  EXTERNAL_PARTY = 'EXTERNAL_PARTY',
  UAM_SUPPORT = 'UAM_SUPPORT',
  RM_SUPPORT = 'RM_SUPPORT',
  IF_PU6 = 'IF_PU6',
}

export interface UserModel {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  displayName?: string;
  id?: string;
  roles: Role[];
  mail?: string;
  otherMails?: string[];
}
