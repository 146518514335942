import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FormErrorService } from '../../../services/form-error.service';

type RadioGroupColorScheme = 'darkgrey' | 'inverted' | 'red' | 'turquoise';

/**
 * @see https://storybook.web-ui.eon.com/master/?path=/docs/radiobuttongroup--interactive
 */
@Component({
  selector: 'gep-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
})
export class RadioButtonGroupComponent implements ControlValueAccessor {
  @ViewChild('eonUiRadioButtonGroup')
  eonUiRadioButtonGroupElement?: ElementRef<HTMLEonUiRadioButtonGroupElement>;

  /**
   * The error message that is displayed if the component is invalid
   */
  @Input() errorMessage?: string;

  /**
   * A property to set every child Element to disabled.
   */
  @Input() disabled = false;

  /**
   * A property to set the legend title of the RadioButtonGroup.
   * If not set, the legend will not appear.
   */
  @Input() label: string = '';

  /**
   * A property to set the name attribute of every child element.
   */
  @Input() name?: string;

  /**
   * A property to set the group to required
   */
  @Input() required = false;

  /**
   * The name of the color scheme of the component.
   * If not set, the component will try to calculate it based on the nearest enclosing [eon-ui-background]{@link Background}.
   */
  @Input() scheme?: RadioGroupColorScheme;

  /**
   * Holds the value of the current checked radioButton.
   */
  @Input() value = '';

  @Input()
  requiredStar: boolean = false;

  get formattedLabel(): string {
    return this.label + (this.requiredStar ? '*' : '');
  }

  /**
   * Raised after the RadioButton has been clicked. Returns a boolean whether the RadioButton is now checked or not.
   */
  @Output() public valueChanged = new EventEmitter();

  get eonUiRadioButtonGroupNativeElement() {
    return this.eonUiRadioButtonGroupElement?.nativeElement;
  }

  requiredStarOutput = '';

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private errorService: FormErrorService
  ) {
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  private renderInvalidState() {
    if (Boolean(this.ngControl?.invalid) && Boolean(this.ngControl.touched)) {
      this.eonUiRadioButtonGroupNativeElement?.handleCustomValidation(
        this.errorService.getErrorMessage({
          errors: this.ngControl?.errors,
          customErrorMessage: this.errorMessage,
          label: this.label,
        })
      );
    } else {
      this.eonUiRadioButtonGroupNativeElement?.handleCustomValidation('');
    }
  }

  private getErrorMessage(): string {
    return this.errorMessage || 'Error';
  }

  registerOnChange(fn: any): void {
    this.valueChanged.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueChanged.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: string): void {
    this.value = obj;

    if (this.eonUiRadioButtonGroupNativeElement) {
      this.eonUiRadioButtonGroupNativeElement.setAttribute(
        'checked',
        'checked'
      );
      this.eonUiRadioButtonGroupNativeElement.value = this.value;
    }

    this.renderInvalidState();
  }

  onValueChanged(event: any) {
    this.valueChanged.emit(event.detail);

    this.renderInvalidState();
  }
}
