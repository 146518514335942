import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SettingsService } from '../../../../services/settings.service';
import { Subscription } from 'rxjs';
import { ToastService } from '../../../../services/toast.service';
import { OptionTransformers } from '../../../../transformers/option-transformers';

@Component({
  selector: 'gep-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
  subscription?: Subscription;
  form: FormGroup = new FormGroup<any>({
    id: new FormControl(undefined),
    price_unit: new FormControl(undefined),
    price_margin: new FormControl(undefined),
    price_transaction_redistributor: new FormControl(undefined),
    price_transaction_not_redistributor: new FormControl(undefined),
  });

  priceUnits: string[] = ['€', '$'];

  constructor(
    private settingsService: SettingsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.subscription = this.settingsService
      .getGeneralSettings$()
      .subscribe(generalSettings => {
        if (generalSettings) {
          this.form.patchValue(generalSettings);
        }
      });
  }

  saveSettings() {
    this.settingsService
      .saveGeneralSettings$(this.form.value)
      .subscribe(_ => this.toastService.showSavedToast());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  protected readonly OptionTransformers = OptionTransformers;
}
