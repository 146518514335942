import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

export const HasNoRoleGuard: CanActivateChildFn = (childRoute, state) => {
  const router = inject(Router);
  const authService = inject(MsalService);

  const activeAccount = authService.instance.getActiveAccount();
  const roles = (activeAccount?.idTokenClaims as { roles: string[] })?.roles;

  if (roles === undefined || roles.length === 0) {
    router.navigate(['/error']);
    return false;
  }
  return true;
};
