import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gep-admin-tab-header',
  templateUrl: './admin-tab-header.component.html',
  styleUrls: ['./admin-tab-header.component.scss'],
})
export class AdminTabHeaderComponent implements OnInit {
  route: string = '';

  constructor(protected router: Router) {}

  ngOnInit(): void {
    this.route = this.router.url;
  }
}
