<gep-settings-tab-header></gep-settings-tab-header>

<main class="d-flex flex-row w-100">
  <form [formGroup]="form" class="w-100">
    <fieldset>
      <gep-number-input
        [label]="'settings.generalSettings.priceMargin' | translate"
        [labelOutside]="true"
        formControlName="price_margin"
      ></gep-number-input>
      <gep-dropdown
        [label]="'settings.generalSettings.priceUnit' | translate"
        [labelOutside]="true"
        [options]="priceUnits"
        [optionValueTransformer]="OptionTransformers.transformStringOptions"
        [optionLabelTransformer]="OptionTransformers.transformStringOptions"
        formControlName="price_unit"
        ></gep-dropdown>

    </fieldset>
    <fieldset>
      <gep-number-input
        [label]="'settings.generalSettings.priceTransactionRedistributor' | translate"
        [labelOutside]="true"
        formControlName="price_transaction_redistributor"
      ></gep-number-input>
      <gep-number-input
        [label]="'settings.generalSettings.priceTransactionNotRedistributor' | translate"
        [labelOutside]="true"
        formControlName="price_transaction_not_redistributor"
      ></gep-number-input>
    </fieldset>

    <div class="text-center">
      <eon-ui-button [text]="'settings.save' | translate"  (click)="saveSettings()"></eon-ui-button>
    </div>
  </form>
</main>
