import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RerenderDirective } from './rerender.directive';

@NgModule({
  declarations: [RerenderDirective],
  exports: [RerenderDirective],
  imports: [CommonModule],
})
export class DirectivesModule {}
