import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MemphisProducersService } from '../../services/memphis-producers.service';
import { PageRetrieverInterface } from '../pagination/page-retriever.interface';
import { DealModel } from '../../models/deal.model';
import { Column } from '../pagination/column';
import { DealSearchSortOption } from '../../modules/deal-search/model/deal-search-sort-option';

@Component({
  selector: 'gep-memphis-producers-view',
  templateUrl: './memphis-producers-view.component.html',
  styleUrls: ['./memphis-producers-view.component.scss'],
})
export class MemphisProducersViewComponent implements AfterViewInit {
  public selectedColumns: string[] = [];
  public availableColumns: Column[] = [];

  @ViewChild('productTemplate', { read: TemplateRef })
  public productTemplate!: TemplateRef<any>;

  @ViewChild('customerTemplate', { read: TemplateRef })
  public customerTemplate!: TemplateRef<any>;

  protected defaultOrderBy: DealSearchSortOption[] = [
    { field: '_id', direction: 'asc' },
  ];

  constructor(private memphisProducersService: MemphisProducersService) {}

  dataRetriever: PageRetrieverInterface<DealModel> = {
    load: (
      page: number,
      page_size: number,
      search: { [p: string]: string },
      orderBy: DealSearchSortOption[]
    ) => {
      return this.memphisProducersService.findMemphisProducers(
        page,
        page_size,
        search,
        orderBy
      );
    },
  };

  private loadAvailableColumns(): Column[] {
    return [
      new Column('deal_id'),
      new Column('product_key'),
      new Column('year'),
      new Column('status'),
      new Column('customer'),
      new Column('contact_person'),
      new Column('delivery_start'),
      new Column('delivery_end'),
      new Column('completion_date'),
      new Column('segment'),
      new Column('energy_source'),
      new Column('region'),
    ];
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.availableColumns = this.loadAvailableColumns();
      this.selectedColumns = ['deal_id', 'product_key', 'customer', 'segment'];
    });
  }
}
