import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HealthStatus } from '../../models/Health-status.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardStatusService {
  constructor(private http: HttpClient) {}

  public getTheStatusOfHealth(): Observable<HealthStatus> {
    return this.http.get<HealthStatus>(environment.API + 'v1/status/dashboard');
  }
}
