import { Component, Input } from '@angular/core';
import { TaskModel } from 'src/app/models/task.model';

@Component({
  selector: 'gep-list-open-tasks',
  templateUrl: './list-open-tasks.component.html',
  styleUrls: ['./list-open-tasks.component.scss'],
})
export class ListOpenTasksComponent {
  @Input()
  public task: TaskModel = {};

  constructor() {}
}
