<div class="th-control" [class.small]="small">
  <div class="th-control">
    {{column.label | translate}}
  </div>
  <button
    *ngIf="column.sortable"
    class="th-sort"
    [class.asc]="column.sort === 'ASC'"
    [class.desc]="column.sort === 'DESC'"
    (click)="toggleSort()"
  ></button>
</div>
