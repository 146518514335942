import { Injectable } from '@angular/core';
import { map, Observable, Subject, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { debounceTime, shareReplay } from 'rxjs/operators';
import { DealSearchFilter, DealSearchMode } from '../model/deal-search-filter';
import { DealSearchSortOption } from '../model/deal-search-sort-option';
import { environment } from '../../../../environments/environment';
import { DealModel } from '../../../models/deal.model';
import { SearchResult } from '../model/search-result';
import { PageModel } from '../../../models/page.model';
import { AcsSearchParams } from '../../../models/acs-search-params.model';
import { AcsSearchFilter } from '../../../models/acs-search-filter.model';

@Injectable({
  providedIn: 'root',
})
export class DealSearchService {
  private readonly searchString$ = new Subject<{
    search: string;
    filter: AcsSearchFilter;
    sort: DealSearchSortOption[];
    searchMode: DealSearchMode;
  }>();

  readonly result$: Observable<SearchResult> = this.searchString$.pipe(
    debounceTime(400),
    switchMap(({ search, filter, sort, searchMode }) => {
      const acsParams: AcsSearchParams = {
        search: search,
        sort: sort,
        filter: filter,
        version: 1,
        queryType: 'full',
        searchMode: searchMode,
        page: 0,
        page_size: 50,
      };
      return this.http.post<[SearchResult]>(
        `${environment.API}acs/deals/search`,
        acsParams
      );
    }),
    map(searchResult => {
      return {
        ...searchResult,
        value: searchResult[0].value?.map(deal => {
          if ('_id' in deal) {
            return {
              ...deal,
              id: (deal as DealModel & { _id: string })._id,
              _id: undefined,
            };
          }

          if ('doc_id' in deal) {
            return {
              ...deal,
              id: (deal as DealModel & { doc_id: string }).doc_id,
              doc_id: undefined,
            };
          }

          return deal;
        }),
      };
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(private readonly http: HttpClient) {}

  search(
    search: string,
    filters: DealSearchFilter[],
    sort: DealSearchSortOption[],
    searchMode: DealSearchMode
  ): void {
    const filter: AcsSearchFilter = {
      AND: filters,
    };

    this.searchString$.next({ search, filter, sort, searchMode });
  }

  public getConsignments(
    acsSearchParams: AcsSearchParams
  ): Observable<[PageModel<DealModel>]> {
    acsSearchParams.filter = {
      AND: acsSearchParams.tempFilter,
    };

    return this.http.post<[PageModel<DealModel>]>(
      `${environment.API}acs/deals/consignments`,
      acsSearchParams
    );
  }

  public getInvalidations(
    acsSearchParams: AcsSearchParams
  ): Observable<[PageModel<DealModel>]> {
    acsSearchParams.filter = {
      AND: acsSearchParams.tempFilter,
    };

    return this.http.post<[PageModel<DealModel>]>(
      `${environment.API}acs/deals/invalidations`,
      acsSearchParams
    );
  }

  public getDealsForCounterpart() {
    return this.http.get<DealModel[]>(
      `${environment.API}v1/deals/for-counterpart`
    );
  }

  public getClosedDeals(
    acsSearchParams: AcsSearchParams
  ): Observable<[PageModel<DealModel>]> {
    const closedDealFilter: DealSearchFilter = {
      field: 'status',
      operator: 'eq',
      value: 'CLOSED',
    };

    const found = acsSearchParams.tempFilter!.find(params => {
      return (
        params.field == 'status' &&
        params.operator == 'eq' &&
        params.value == 'CLOSED'
      );
    });
    if (!found) {
      acsSearchParams.tempFilter?.push(closedDealFilter);
    }

    acsSearchParams.filter = {
      AND: acsSearchParams.tempFilter,
    };

    return this.http.post<[PageModel<DealModel>]>(
      `${environment.API}acs/deals/search`,
      acsSearchParams
    );
  }
}
