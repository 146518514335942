import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { SliderComponent } from '../../../gep-forms/components/slider/slider.component';
import { RangeValues } from '../../../gep-forms/models/range-values';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { distinctUntilChanged, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gep-range-with-input',
  templateUrl: './range-with-input.component.html',
})
export class RangeWithInputComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() unit?: string = '';
  @Input() firstValue?: number;
  @Input() secondValue?: number;
  @Input() inputWidth?: string;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() step?: number = 1;
  @Input() disabled?: boolean;

  @Output() valueSelected = new EventEmitter<{ min?: number; max?: number }>();

  @ViewChild(SliderComponent)
  public sliderComponent!: SliderComponent;

  changeFn?: (change: { min?: number; max?: number }) => void;
  touchFn?: () => void;
  _destroy$: Subject<void> = new Subject<void>();

  readonly hasError$ = new ReplaySubject<boolean>();
  readonly value$ = new ReplaySubject<{ min?: number; max?: number }>(1);

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.value$
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.valueSelected.emit(value);
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onSliderValueChanged(event: RangeValues) {
    this.firstValue = event.firstValue;
    this.secondValue = event.secondValue;

    this.onValueChange();
  }

  onInputValueChanged(value: number, inputType: string) {
    if (inputType === 'first') {
      this.firstValue = value;
    } else if (inputType === 'second') {
      this.secondValue = value;
    }
    this.sliderComponent.setSliderValues(this.firstValue!, this.secondValue!);

    this.onValueChange();
  }

  registerOnChange(fn: (change: { min?: number; max?: number }) => void): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchFn = fn;
  }

  writeValue(obj: { min?: number; max?: number }): void {
    this.value$.next(obj);

    this.hasError$.next(!!this.ngControl.control?.errors);
  }

  onValueChange() {
    const value = {
      min: this.firstValue,
      max: this.secondValue,
    };

    this.value$.next(value);
    if (this.touchFn) {
      this.touchFn();
    }

    if (this.changeFn) {
      this.changeFn(value);
    }
  }
}
