<main>
  <section>
      <div
        class="enquiry-templates"
        *ngIf="prefillOptions$ | async as prefillOptions"
      >
        <gep-dropdown
          [options]="prefillOptions"
          [optionLabelTransformer]="OptionTransformers.transformEnquiryFormModelToContactPerson"
          [label]="'createEnquiry.prefillFormDropdown' | translate"
          [formControl]="prefillDropdownControl"
        ></gep-dropdown>
      </div>
      <ng-container
        *ngIf="productConfigurations$ | async as productConfigurations"
      >
        <gep-enquiry-form
          [productConfigurations]="productConfigurations"
          [deal]="null"
          [prefillForm]="prefill$ | async"
          (save)="onSave($event)"
          (cancel)="onCreateCancel()"
        ></gep-enquiry-form>
      </ng-container>
  </section>
</main>
