import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { FormErrorService } from '../../../services/form-error.service';

type InputColorScheme =
  | 'darkgreyOnLimeyellow'
  | 'turquoise'
  | 'turquoiseOnLightgrey'
  | 'turquoiseOnUltralightgrey'
  | 'whiteOnBordeaux'
  | 'whiteOnRed'
  | 'whiteOnTurquoise';

type InputSize = 'normal' | 'small';

type InputType =
  | 'date'
  | 'email'
  | 'hidden'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text';

/**
 * @see https://storybook.web-ui.eon.com/master/?path=/docs/input--interactive
 *
 */
@Component({
  selector: 'gep-input-deprecated',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @ViewChild('eonUiInput') eonUiInputElement?: ElementRef;

  /**
   * Show or hide preloader
   */
  @Input() preloader: boolean = false;

  /**
   * Internal use only! Please don't change this.
   * @disableControlInStorybook true
   */
  @Input() isFormComponent: boolean = true;

  /**
   * Define the size of the input element
   */
  @Input() size: 'small' | 'normal' = 'normal';

  /**
   * The name of the input must be unique per page.!
   *@example <eon-ui-input name="firstname"></eon-ui-input>
   */
  @Input() name: string = '';

  /**
   * The value of the input element.
   * @example <eon-ui-input value="Test"></eon-ui-input>
   */
  @Input() value: string = '';

  /**
   * Displays the input as textarea.
   * @example <eon-ui-input textarea="true"></eon-ui-input>
   */
  @Input() textarea: boolean = false;

  /**
   * Set to true if the input is required.
   *
   * @example <eon-ui-input required="required"></eon-ui-input>
   */
  @Input() required: boolean = false;

  /**
   * Set to true if the input is disabled.
   *
   * @example <eon-ui-input disabled="disabled"></eon-ui-input>
   */
  @Input() disabled: boolean = false;

  /**
   * Sets the type of the input. See https://www.w3schools.com/html/html_form_input_types.asp for valid types.
   *
   * @example <eon-ui-input type="text"></eon-ui-input>
   */
  @Input() type: InputType = 'text';

  /**
   * Sets the label for the input element.
   *
   * @example <eon-ui-input label="First Name"></eon-ui-input>
   */
  @Input() label?: string;

  /**
   * Sets the text of the error message.
   * @example <eon-ui-input error="true" error-message="this is an error message"></eon-ui-input>
   */
  @Input() errorMessage?: string;

  /**
   * Sets the text of the success message.
   * @example <eon-ui-input success="true" success-message="this is a success message"></eon-ui-input>
   */
  @Input() successMessage?: string;

  /**
   * Set to true if the Input is in success state and the success message should be displayed.
   * @example <eon-ui-input success="true"></eon-ui-input>
   */
  @Input() success: boolean = false;

  /**
   * Set to true if the Input is in info state and the info message should be displayed.
   * @example <eon-ui-input info="true"></eon-ui-input>
   */
  @Input() info: boolean = false;

  /**
   * Sets the text of the info message.
   * @example <eon-ui-input  info="true" info-message="this is an info message"></eon-ui-input>
   */
  @Input() infoMessage?: string;

  /**
   * The name of the color scheme of the component.
   * If not set, the component will try to calculate it based on the nearest enclosing [eon-ui-background]{@link Background}.
   */
  @Input() scheme?: InputColorScheme;

  /**
   * Determine if the trailing icon for info or error is shown.
   * @example <eon-ui-input trailing-icon="true"></eon-ui-input>
   */
  @Input() trailingIcon: boolean = false;

  /**
   * Name of the trailing icon to display.
   * @example <eon-ui-input trailig-icon="true" trailing-icon-name="search"></eon-ui-input>
   */
  @Input() trailingIconName?: string;

  /**
   * Determine if the trailing icon to clear the input is shown.
   * @example <eon-ui-input clear-input-icon="true"></eon-ui-input>
   */
  @Input() clearInputIcon: boolean = false;

  /**
   * Provide an icon for the messages (info, error and success).
   * If no icon is set, the default icons are used.
   * @example <eon-ui-input success="true" success-message="this is a success" icon="thumb_up"></eon-ui-input>
   */
  @Input() messageIcon?: string;

  /**
   * Determine if the input is in focus style
   * @example <eon-ui-input focus-style="true"></eon-ui-input>
   */
  @Input() focusStyle: boolean = false;

  /**
   * Determine if the input is read only
   * @example <eon-ui-input read-only="true"></eon-ui-input>
   */
  @Input() readOnly: boolean = false;

  @Input() invalid: boolean = false;
  /**
   * Placeholder for the input
   * @example <eon-ui-input placeholder="vorname"></eon-ui-input>
   */
  @Input() placeholder?: string;

  /**
   * Rows: Gives flexibility to editor for setting number of rows in Textarea field
   */
  @Input() rows?: number;

  /**
   * Cols: Gives flexibility to editor for setting number of cols in Textarea field
   */
  @Input() cols?: number;

  /**
   * Autocomplete: Defines if the input should have autocomplete function
   */
  @Input() autocomplete: boolean = false;

  /**
   * Autocomplete Terms: used to save the autocomplete API answer
   */
  @Input() autocompleteTerms: string[] = [];

  /**
   * Autocomplete Start: Defines after how many characters the loading indicator should be shown.
   * "3" would mean as soon as the 3rd character has been typed.
   */
  @Input() autocompleteStart: number = 3;

  /**
   * Defines pattern mask for input.<br>
   * Supported input types - `number`, `text`, `tel`.
   *
   * Look <a href="https://imask.js.org/guide.html#masked-pattern" target="_blank">here</a> for more details.
   * @example <eon-ui-input type="tel" pattern="(000)000-00-00"></eon-ui-input>
   */
  @Input() pattern: string = '';

  /**
   * Specifies the minimum value.
   * When user enters value below `min`, value is being replaced with `min` value on blur event.
   * @example <eon-ui-input min="10"></eon-ui-input>
   */
  @Input() min?: string;

  /**
   * Specifies the maximum value.
   * @example <eon-ui-input max="1000"></eon-ui-input>
   */
  @Input() max?: string;

  /**
   * Sets thousand separator for numeric values.
   * Works only with input `type="text"`
   * @example <eon-ui-input type="text" thousand-separator="."></eon-ui-input>
   */
  @Input() thousandSeparator: string = '';

  /**
   * Fires when the user selects an item in the autocomplete list
   */
  @Output() public autocompleteSelected = new EventEmitter();

  /**
   * Raised when the trailing icon is clicked.
   */
  @Output() public iconClick = new EventEmitter();

  /**
   * Raised when the input is losing focus.
   */
  @Output() public inputBlur = new EventEmitter();

  /**
   * Raised when the input is focused.
   */
  @Output() public inputFocus = new EventEmitter();

  /**
   * Raised when the input has changed. Will emit the current value of the Input field.
   */
  @Output() public valueChanged = new EventEmitter();

  private onChange?: (value: any) => void;
  private onTouch?: () => void;

  private _destroy$ = new Subject<void>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private errorService: FormErrorService
  ) {
    if (ngControl !== null) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.valueChanged
      .pipe(distinctUntilChanged(), takeUntil(this._destroy$))
      .subscribe(value => {
        if (this.onChange) {
          this.onChange(value);
        }
        if (this.onTouch) {
          this.onTouch();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  private renderInvalidState() {
    if (Boolean(this.ngControl?.invalid) && Boolean(this.ngControl.touched)) {
      this.eonUiInputElement?.nativeElement?.handleCustomValidation(
        this.errorService.getErrorMessage({
          errors: this.ngControl?.errors,
          customErrorMessage: this.errorMessage,
          label: this.label,
        })
      );
    } else {
      this.eonUiInputElement?.nativeElement?.handleCustomValidation(undefined);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: string | null): void {
    if (obj) {
      this.value = obj;
      this.renderInvalidState();
    }
  }

  onValueChanged(event: any) {
    this.value = event.detail;
    this.valueChanged.emit(event.detail);

    this.renderInvalidState();
  }

  onAutocompleteSelected($event: any) {
    this.autocompleteSelected.emit($event.detail);
  }

  onIconClick($event: any) {
    this.iconClick.emit();
  }

  onInputBlur($event: any) {
    this.inputBlur.emit();
  }

  onInputFocus($event: any) {
    this.inputFocus.emit();
  }
}
