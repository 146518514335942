export enum Style {
  Standard = 'red500',
  StandardInverted = 'neutral100',
  Highlight = 'red500',
  Secondary = 'secondary',
  HighlightInverted = 'neutral100',
  Gray = 'darkgreyCarrier',

  IconOnlyMiddleGrey = 'middlegrey',
}
