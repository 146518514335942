<div class="component-wrapper">
  <div class="main-panel">
    <div class="welcome_user">
      <div class="welcome-message-wrapper">
        <gep-welcome-message *ngIf="hasRole()"></gep-welcome-message>
      </div>
      <div>
        <eon-ui-button
          class="enquiry_btn"
          text="{{ 'new enquiry' | translate }}"
          icon="add"
          scheme="turquoise"
          use-as-link="true"
          href="/enquiry"
        ></eon-ui-button>
      </div>
    </div>

    <div class="current-inventory">
      <div class="current-inventory-inner">
        <h2 class="current-inventory-header">
          {{ 'CURRENT_INVENTORY' | translate }}
        </h2>
        <div class="form-field-container">
          <gep-dropdown
            *ngIf="deliveryYears | async as years"
            label="{{ 'delivery_year' | translate }}"
            (valueSelected)="onYearSelected($event)"
            [options]="years"
            [optionLabelTransformer]="stringOptionValueTransformer"
            [optionValueTransformer]="stringOptionValueTransformer"
            [value]="selectedYear.toString()"
          >
          </gep-dropdown>
        </div>
      </div>
      <div class="inventory-kpi-wrapper">
        <gep-inventory-kpis [year]="this.selectedYear"></gep-inventory-kpis>
      </div>
    </div>
    <div class="current-inventory-products">
      <div class="current-inventory-container">
        <gep-dropdown
          *ngIf="productConfig$ | async as products"
          class="dropdown"
          label="{{ 'product' | translate }}"
          [value]="selectedProductKey"
          (valueSelected)="onProductSelected($event)"
          height="25em"
          [options]="products"
          [optionLabelTransformer]="stringOptionValueTransformer"
          [optionValueTransformer]="stringOptionValueTransformer"
        >
        </gep-dropdown>
        <div class="last-updated">
          {{'last_updated' | translate}}: {{ lastKpiUpdate | date: 'shortDate'}}
        </div>
      </div>
      <div class="product-kpi-wrapper">
        <table class="product-kpi-captions" style="text-align: right">
          <tr>
            <th style="text-align: right">KPI</th>
            <th style="text-align: right">{{ 'KPI.sales' | translate }}</th>
            <th style="text-align: right">{{ 'KPI.buys' | translate }}</th>
            <th style="text-align: right">DELTA</th>
            <th style="text-align: right">PNL</th>
            <th style="text-align: right">
              {{ 'KPI.market_price' | translate }}
            </th>
            <th style="text-align: right">
              {{ 'KPI.market_value' | translate }}
            </th>
            <th style="text-align: right">
              {{ 'KPI.portfolio_price' | translate }}
            </th>
            <th style="text-align: right">
              {{ 'KPI.portfolio_value' | translate }}
            </th>
            <th>&nbsp;</th>
          </tr>
          <tr *ngFor="let k of productKpis$ | async">
            <td>{{ k.year }}</td>
            <td>
              {{ (k.sales | number: '':locale) + ' MWh'}}
            </td>
            <td>
              {{ (k.buys | number: '':locale) + ' MWh' }}
            </td>
            <td>
              {{ (k.delta | number: '':locale) + ' MWh' }}
            </td>
            <td>
              {{ (k.pnl | number: '':locale) + ' €' }}
            </td>
            <td>{{ k.market_price | number: '1.0-3':locale }}</td>
            <td>{{ k.market_value | number: '1.0-3':locale }}</td>
            <td>{{ k.portfolio_price | number: '1.0-3':locale }}</td>
            <td>{{ k.portfolio_value | number: '1.0-3':locale }}</td>
            <!--
            <td>
              <a
                href="/reporting/?year={{ k.year }}&product_key={{
                  k.product_key
                }}"
                >-> History</a
              >
            </td>
            -->
          </tr>
        </table>
      </div>
    </div>
    <div class="open-tasks">
      <h2>{{ 'OPEN_TASKS' | translate }}</h2>
      <h3>{{ 'SALES_REQUESTS' | translate }}</h3>
      <div *ngFor="let task of openTasks">
        <gep-list-open-tasks [task]="task"></gep-list-open-tasks>
      </div>
    </div>
  </div>
</div>
