<ng-container *ngIf="internalName !== null">
  <eon-ui-input
    type="text"
    [attr.value]="value$ | async"
    [attr.size]="size"
    [attr.disabled]="disabled$ | async"
    [attr.label]="formattedLabel"
    [attr.placeholder]="placeholder"
    [attr.name]="internalName"
    [attr.invalid]="showError$ | async"
    [attr.label-outside]="labelOutside"
    (valueChanged)="onValueChange($event)"
    (inputBlur)="onInputBlur()"
  >
  </eon-ui-input>
</ng-container>
