import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { ButtonSize } from '../../../gep-controls/models/button-size';

@Component({
  selector: 'gep-checkbox-list-foldable',
  templateUrl: './checkbox-list-foldable.component.html',
  styleUrls: ['./checkbox-list-foldable.component.scss'],
})
export class CheckboxListFoldableComponent {
  @Input() options: string[] = [];
  @Input() numberOfDefaultDisplayed: number = 5;
  @Input() size: ButtonSize = ButtonSize.Normal;
  @Input() disabled?: boolean;

  @Output() valueSelected = new EventEmitter<string[]>();

  selectedValues: string[] = [];
  isFoldedOut = false;

  changeFn?: (change: string[]) => void;
  touchFn?: () => void;

  readonly hasError$ = new ReplaySubject<boolean>();
  readonly value$ = new ReplaySubject<string[]>(1);

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: (change: string[]) => void): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchFn = fn;
  }

  writeValue(obj: string[]): void {
    this.value$.next(obj);

    this.hasError$.next(!!this.ngControl.control?.errors);
  }

  toggleFoldOut(): void {
    this.isFoldedOut = !this.isFoldedOut;
  }

  onValueChange(event: Event, option: string) {
    if (
      (<CustomEvent>event).detail === true &&
      this.selectedValues.indexOf(option) === -1
    ) {
      this.selectedValues.push(option);
    } else if ((<CustomEvent>event).detail === false) {
      this.selectedValues = this.selectedValues.filter(
        value => value !== option
      );
    }

    this.value$.next(this.selectedValues);
    if (this.touchFn) {
      this.touchFn();
    }

    if (this.changeFn) {
      this.changeFn(this.selectedValues);
    }

    this.valueSelected.emit(this.selectedValues);
  }
}
