import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

const AQUAPOWER_DEFAULT_COMMENT =
  'Bitte beachten Sie, dass eine TÜV EE Qualität aus eigenen Kraftwerken benötigt wird, da wir diese Mengen unter der Nutzungsvereinbarung laufen lassen möchten.';

const ERZEUGUNG_EE_DEFAULT_COMMENT =
  'Bitte beachten Sie, dass eine TÜV EE Qualität aus eigenen Kraftwerken benötigt wird, da wir diese Mengen unter der Nutzungsvereinbarung laufen lassen möchten.';

const COMMENTS_FOR_PRODUCTS: Record<string, string> = {
  AQUAPOWER: AQUAPOWER_DEFAULT_COMMENT,
  ERZEUGUNG_EE: ERZEUGUNG_EE_DEFAULT_COMMENT,
};

const ENQUIRY_DEAL_TYPES = ['ENQUIRY_BUY', 'ENQUIRY_BUY_SELL', 'ENQUIRY_SELL'];

@Injectable()
export class EnquiryFormFassadeService {
  constructor() {}

  getRedistributorRadioSelectOptions$(): Observable<string[]> {
    return of(['true', 'false']);
  }

  getCommentsForProductSelection$(): Observable<Record<string, string>> {
    return of(COMMENTS_FOR_PRODUCTS);
  }

  getDealTypes$(): Observable<string[]> {
    return of(ENQUIRY_DEAL_TYPES);
  }
}
