<div class="deals-filter" [ngClass]="{ 'deals-filter-opened': isOpened }">
  <div
    *ngIf="filterCanBeClosed"
    class="filter-close-button"
    (click)="toggleDealFilter()"
  >
    X
  </div>
  <div class="deals-filter-inner">
    <div *ngIf="showSearchBar">
      <h2>{{ 'search' | translate }}</h2>
      <gep-input
        [size]="ButtonSize.Small"
        (valueSelected)="emitSearch($event)"
      ></gep-input>
      <h2>{{ 'search_mode' | translate }}</h2>
      <div class="search-mode-toggle">
        <h5>all</h5>
        <gep-toggle (changeSelected)="emitSearchMode($event)" />
        <h5>any</h5>
      </div>
    </div>

    <span
      class="filter-item"
      *ngFor="let property of filterProperties"
      [ngSwitch]="property.type"
    >
      <h2 *ngIf="property.fieldNameAsLabel">
        {{ property.fieldName | translate }}
      </h2>
      <span
        *ngIf="!property.resetForbidden"
        class="reset-filter"
        (click)="removeFilter(property)"
        >x</span
      >
      <ng-container *ngSwitchCase="'select'">
        <gep-dropdown
          *rerender="property.rerender"
          [value]="property.value"
          [options]="property.possibleValues || []"
          [optionLabelTransformer]="identityStringTransformer"
          [optionValueTransformer]="identityStringTransformer"
          [disabled]="property.disabled"
          [size]="ButtonSize.Small"
          (valueSelected)="onChange(property, $event)"
        ></gep-dropdown>
      </ng-container>

      <ng-container *ngSwitchCase="'multi-select'">
        <gep-multi-select-dropdown
          *rerender="property.rerender"
          [optionValueTransformer]="identityStringTransformer"
          [optionLabelTransformer]="identityStringTransformer"
          [options]="property.possibleValues || []"
          [fixedHeight]="false"
          [size]="ButtonSize.Small"
          [disabled]="property.disabled"
          (valueSelected)="onChange(property, $event)"
        ></gep-multi-select-dropdown>
      </ng-container>

      <ng-container *ngSwitchCase="'date'">
        <gep-date-picker
          *rerender="property.rerender"
          [size]="ButtonSize.Small"
          [disabled]="property.disabled"
          (valueSelected)="onChange(property, $event)"
        ></gep-date-picker>
      </ng-container>

      <ng-container *ngSwitchCase="'date-range'">
        <gep-date-range
          *rerender="property.rerender"
          [size]="ButtonSize.Small"
          [disabled]="property.disabled"
          (valueSelected)="onChange(property, $event)"
        ></gep-date-range>
      </ng-container>

      <ng-container *ngSwitchCase="'range'">
        <gep-range-with-input
          *rerender="property.rerender"
          [minValue]="property.min"
          [maxValue]="property.max"
          [firstValue]="property.firstValue"
          [secondValue]="property.secondValue"
          [disabled]="property.disabled"
          (valueSelected)="onChange(property, $event)"
          [unit]="property.unit"
          inputWidth="100px"
        ></gep-range-with-input>
      </ng-container>

      <ng-container *ngSwitchCase="'checkbox-list-foldable'">
        <gep-checkbox-list-foldable
          *rerender="property.rerender"
          [options]="property.possibleValues || []"
          [numberOfDefaultDisplayed]="property.numberOfDefaultDisplayed!"
          [size]="ButtonSize.Small"
          [disabled]="property.disabled"
          (valueSelected)="onChange(property, $event)"
        ></gep-checkbox-list-foldable>
      </ng-container>

      <ng-container *ngSwitchCase="'checkbox-list-columns'">
        <gep-checkbox-in-columns
          *rerender="property.rerender"
          [options]="property.possibleValues || []"
          [size]="ButtonSize.Small"
          [disabled]="property.disabled"
          (valueSelected)="onChange(property, $event)"
        ></gep-checkbox-in-columns>
      </ng-container>

      <ng-container *ngSwitchCase="'radio-group'">
        <gep-radio-select-group
          *rerender="property.rerender"
          [options]="property.possibleValues || []"
          [horizontal]="true"
          [disabled]="property.disabled"
          [size]="ButtonSize.Small"
          (valueSelected)="onChange(property, $event)"
        ></gep-radio-select-group>
      </ng-container>

      <ng-container *ngSwitchCase="'yes-no'">
        <gep-yes-no-radio
          *rerender="property.rerender"
          [value]="property.value"
          [size]="ButtonSize.Small"
          [disabled]="property.disabled"
          (valueSelected)="onChange(property, $event)"
        ></gep-yes-no-radio>
      </ng-container>

      <ng-container *ngSwitchCase="'headline'">
        <br /><br />
        <h2>{{ property.label }}</h2>
      </ng-container>
    </span>
  </div>
</div>
<div
  *ngIf="!isOpened"
  class="filter-open-button"
  style="cursor: pointer"
  (click)="toggleDealFilter()"
>
  {{ 'unfold_filter' | translate }}
</div>
