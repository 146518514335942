import { TemplateRef } from '@angular/core';

export class Column {
  public name!: string;
  public label: string = '';
  public translateLabel = false;
  public sortable = true;
  public width: false | number = false;
  public align: 'left' | 'right' | 'center' = 'left';
  public template?: TemplateRef<{ column: Column; row: any[] }> | null = null;
  public sort: 'ASC' | 'DESC' | null = null;
  public selected = '';

  constructor(name: string) {
    this.name = name;
    this.setLabel(name);
  }

  setLabel(value: string, translate = true) {
    this.label = value;
    this.translateLabel = translate;
    return this;
  }

  setSortable(value: boolean) {
    this.sortable = value;
    return this;
  }

  setWidth(value: false | number) {
    this.width = value;
    return this;
  }

  setAlign(value: 'left' | 'right' | 'center') {
    this.align = value;
    return this;
  }

  setTemplate(template: TemplateRef<{ column: Column; row: any[] }>) {
    this.template = template;
    return this;
  }
}
