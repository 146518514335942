import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'gep-date-renderer',
  template: '{{date}}',
})
export class DateRendererComponent implements ICellRendererAngularComp {
  date: string = '';
  agInit(params: ICellRendererParams<any, any, any>): void {
    this.date = params.value
      ? new Date(params.value).toLocaleDateString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      : '';
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
