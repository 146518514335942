import { map, switchMap, tap } from 'rxjs/operators';
import { DayTime } from '../models/day-time';
import { Observable, startWith, withLatestFrom } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

export function filterStepsForValue() {
  return map(([steps, value]: [DayTime[], Date | null]) => {
    if (!value) {
      return steps;
    }
    const minutesInDate = value.getHours() * 60 + value.getMinutes();
    if (minutesInDate > steps[steps.length - 1].toMinutes()) {
      throw new Error('Date must not be later than end of business');
    }

    const now = new Date();
    const today = now.toISOString().split('T')[0];
    const valueDate = value.toISOString().split('T')[0];
    const valueDateIsToday = today === valueDate;
    const valueIsLaterThanToday = value.getTime() > now.getTime();

    if (valueIsLaterThanToday && !valueDateIsToday) {
      return steps;
    }

    return steps.filter(step => {
      const stepInMinutes = step.toMinutes();
      const valueInMinutes = value.getHours() * 60 + value.getMinutes();
      return stepInMinutes >= valueInMinutes;
    });
  });
}

export function switchToTimeValueChangesSideEffects(
  value$: Observable<Date>,
  changeFnFactory: () => ((change: any) => void) | undefined,
  touchFnFactory: () => (() => void) | undefined,
  dateFactoryFn: (isoString: string) => Date = dateFactory
) {
  return switchMap((formControl: UntypedFormControl) => {
    return formControl.valueChanges.pipe(
      withLatestFrom(value$),
      tap(([valueChange, dateValue]: [DayTime, Date]) => {
        const date = dateValue
          ? dateFactoryFn(dateValue.toISOString())
          : new Date();
        date.setHours(valueChange.hour);
        date.setMinutes(valueChange.minute);
        const changeFn = changeFnFactory();
        const touchFn = touchFnFactory();
        if (changeFn) {
          changeFn(date);
        }

        if (touchFn) {
          touchFn();
        }
      }),
      map(() => formControl),
      startWith(formControl)
    );
  });
}

function dateFactory(isoString: string): Date {
  return new Date(isoString);
}

export function mapToTimeFormControl(
  dateFactoryFn: (isoString: string) => Date = dateFactory
) {
  return map(([, value]: [DayTime[], Date | null]) => {
    if (!value) {
      return new UntypedFormControl(null);
    }
    const valueCopy = dateFactoryFn(value.toISOString());
    const valueDayTime = new DayTime(
      valueCopy.getHours(),
      valueCopy.getMinutes()
    );

    return new UntypedFormControl(valueDayTime);
  });
}
