import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { InputComponent } from './form/input/input.component';
import { DatePickerComponent } from './form/date-picker/date-picker.component';
import { RadioButtonGroupComponent } from './form/radio-button-group/radio-button-group.component';
import { RadioButtonComponent } from './form/radio-button/radio-button.component';
import { DropdownDeprecatedComponent } from './form/dropdown-deprecated/dropdown-deprecated.component';
import { DropdownOptionComponent } from './form/dropdown-option/dropdown-option.component';
import { PaginationTableWrapperComponent } from './pagination/pagination-table-wrapper.component';
import { PaginationTableComponent } from './pagination/pagination-table/pagination-table.component';
import { PaginationFooterComponent } from './pagination/pagination-footer/pagination-footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationTableHeaderControlComponent } from './pagination/pagination-table/pagination-table-header-control/pagination-table-header-control.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationColumnSelectionComponent } from './pagination/pagination-column-selection/pagination-column-selection.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { InventoryKpisComponent } from './inventory-kpis/inventory-kpis.component';
import { ListOpenTasksComponent } from './list-open-tasks/list-open-tasks.component';
import { PsiImportUploadComponent } from './psi-import-upload/psi-import-upload.component';
import { PsiImportViewComponent } from './psi-import-view/psi-import-view.component';
import { MemphisProducersUploadComponent } from './memphis-producers-upload/memphis-producers-upload.component';
import { MemphisProducersViewComponent } from './memphis-producers-view/memphis-producers-view.component';
import { WelcomeMessageComponent } from './welcome_message/welcome_message.component';
import { DealsTabHeaderComponent } from './deals-tab-header/deals-tab-header.component';
import { GepFormsModule } from '../modules/gep-forms/gep-forms.module';
import { PipesModule } from '../pipes/pipes.module';
import { OfferQuickviewComponent } from './offer-quickview/offer-quickview.component';
import { DealsModule } from '../modules/deals/deals.module';
import { GepControlsModule } from '../modules/gep-controls/gep-controls.module';
import { GenericFilterComponent } from './generic-filter/generic-filter.component';
import { DirectivesModule } from '../directives/directives.module';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { EonUiComponentsAngularModule } from '@eon-ui/eon-ui-components-angular';
import { HealthStatusComponent } from './header/health-status/health-status.component';
import { DateTimePickerComponent } from './form/date-time-picker/date-time-picker.component';
import { FeatureFlagDirective } from '../feature-flags/feature-flag.directive';
import { SelectListComponent } from './form/select-list/select-list.component';
import { TimePickerComponent } from './form/time-picker/time-picker.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    InputComponent,
    DatePickerComponent,
    RadioButtonGroupComponent,
    RadioButtonComponent,
    DropdownDeprecatedComponent,
    DropdownOptionComponent,
    PaginationTableWrapperComponent,
    PaginationTableComponent,
    PaginationFooterComponent,
    PaginationTableHeaderControlComponent,
    PaginationColumnSelectionComponent,
    InventoryKpisComponent,
    ListOpenTasksComponent,
    PsiImportUploadComponent,
    PsiImportViewComponent,
    MemphisProducersUploadComponent,
    MemphisProducersViewComponent,
    WelcomeMessageComponent,
    DealsTabHeaderComponent,
    OfferQuickviewComponent,
    GenericFilterComponent,
    LoadingSpinnerComponent,
    HealthStatusComponent,
    TimePickerComponent,
    DateTimePickerComponent,
    SelectListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgbDropdownModule,
    GepFormsModule,
    PipesModule,
    DealsModule,
    GepControlsModule,
    DirectivesModule,
    EonUiComponentsAngularModule,
    FeatureFlagDirective,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    InputComponent,
    DatePickerComponent,
    RadioButtonGroupComponent,
    RadioButtonComponent,
    DropdownDeprecatedComponent,
    DropdownOptionComponent,
    PaginationTableWrapperComponent,
    InventoryKpisComponent,
    ListOpenTasksComponent,
    PsiImportUploadComponent,
    PsiImportViewComponent,
    MemphisProducersUploadComponent,
    MemphisProducersViewComponent,
    WelcomeMessageComponent,
    DealsTabHeaderComponent,
    GenericFilterComponent,
    OfferQuickviewComponent,
    LoadingSpinnerComponent,
    DateTimePickerComponent,
    SelectListComponent,
    DatePickerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule {}
