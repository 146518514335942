import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { map, take, tap } from 'rxjs/operators';

import { DealModel } from '../../models/deal.model';
import { Column } from '../../components/pagination/column';

import { PaginationTableWrapperComponent } from '../../components/pagination/pagination-table-wrapper.component';
import { DealService } from '../../services/deal.service';

import { PageRetrieverInterface } from '../../components/pagination/page-retriever.interface';
import { SelectedColumnsService } from 'src/app/services/selected-columns.service';
import { PageModel } from 'src/app/models/page.model';
import { finalize, Observable } from 'rxjs';
import { DealSearchService } from '../../modules/deal-search/services/deal-search.service';
import {
  DealSearchFilter,
  DealSearchMode,
} from '../../modules/deal-search/model/deal-search-filter';
import { DealSearchSortOption } from '../../modules/deal-search/model/deal-search-sort-option';
import { DealFilterService } from '../../services/filter/deal-filter.service';
import { AcsSearchParams } from '../../models/acs-search-params.model';
import { UserService } from '../../services/user.service';
import { Role } from '../../models/user.model';
import { QuantityUnit } from '../../models/quantity-unit.model';
import { ToastService } from '../../services/toast.service';
import { HorizonService } from '../../services/horizon.service';
import { ButtonSize } from '../../modules/gep-controls/models/button-size';
import { Style } from '../../modules/gep-controls/models/style';
import { ModalWithData } from '../../models/modal-with-data.model';

@Component({
  selector: 'gep-deals-page',
  templateUrl: './deals-page.component.html',
  styleUrls: ['./deals-page.component.scss'],
})
export class DealsPageComponent implements AfterViewInit {
  protected readonly Role = Role;
  protected readonly QuantityUnit = QuantityUnit;
  protected readonly ButtonSize = ButtonSize;
  protected readonly Style = Style;

  dataRetriever: PageRetrieverInterface<DealModel> = {
    load: (
      page: number,
      page_size: number,
      search: string,
      orderby: DealSearchSortOption[],
      filter: DealSearchFilter[],
      searchMode: DealSearchMode
    ): Observable<PageModel<DealModel & { horizonStatus?: string }>> => {
      const acsSearchParams: AcsSearchParams = {
        page: page,
        page_size: page_size,
        search: search,
        tempFilter: filter,
        sort: orderby,
        searchMode: searchMode,
        queryType: 'full',
        version: 1,
      };

      return this.dealSearchService.getClosedDeals(acsSearchParams).pipe(
        map(([p]) => {
          p.data = p.value.map(row => {
            const rowWithStatus: DealModel & { horizonStatus?: string } = {
              ...row,
            };

            if (rowWithStatus.binding_period_start) {
              rowWithStatus.binding_period_start = new Date(
                rowWithStatus.binding_period_start
              );
            }

            if (rowWithStatus.binding_period_end) {
              rowWithStatus.binding_period_end = new Date(
                rowWithStatus.binding_period_end
              );
            }

            if (rowWithStatus.horizon_status) {
              if (rowWithStatus.horizon_status.status.error) {
                rowWithStatus.horizonStatus = 'error';
              } else if (rowWithStatus.horizon_status.status.success) {
                rowWithStatus.horizonStatus = 'success';
              } else {
                rowWithStatus.horizonStatus = 'unknown';
              }
            }

            return Object.assign(new DealModel(), rowWithStatus);
          });

          return p;
        })
      );
    },
  };

  public selectedColumns: string[] = [];
  public availableColumns: Column[] = [];

  @ViewChild('paginator')
  public paginator!: PaginationTableWrapperComponent;

  @ViewChild('quantityTemplate', { read: TemplateRef })
  public quantityTemplate!: TemplateRef<any>;

  @ViewChild('quantityInTonnsTemplate', { read: TemplateRef })
  public quantityInTonnsTemplate!: TemplateRef<any>;

  @ViewChild('productTemplate', { read: TemplateRef })
  public productTemplate!: TemplateRef<any>;

  @ViewChild('wholesalePriceTemplate', { read: TemplateRef })
  public wholesalePriceTemplate!: TemplateRef<any>;

  @ViewChild('boundOfferTemplate', { read: TemplateRef })
  public boundOfferTemplate!: TemplateRef<any>;

  @ViewChild('horizonTemplate', { read: TemplateRef })
  public horizonTemplate!: TemplateRef<any>;

  @ViewChild('cancelDealTemplate', { read: TemplateRef })
  public cancelDealTemplate!: TemplateRef<any>;

  @ViewChild('actionsTemplate', { read: TemplateRef })
  public actionsTemplate!: TemplateRef<any>;

  @ViewChild('bindingPeriodStartDateTemplate', { read: TemplateRef })
  public bindingPeriodStartDateTemplate!: TemplateRef<any>;

  @ViewChild('bindingPeriodEndDateTemplate', { read: TemplateRef })
  public bindingPeriodEndDateTemplate!: TemplateRef<any>;

  @ViewChild('closedDateTemplate', { read: TemplateRef })
  public closedDateTemplate!: TemplateRef<any>;

  protected defaultOrderBy: DealSearchSortOption[] = [
    { field: 'closed_date', direction: 'desc' },
  ];

  constructor(
    private dealsService: DealService,
    private dealSearchService: DealSearchService,
    private changeDetector: ChangeDetectorRef,
    private selectedColumnsService: SelectedColumnsService,
    private toastService: ToastService,
    protected dealFilterService: DealFilterService,
    protected userService: UserService,
    protected horizonService: HorizonService
  ) {}

  triggerHorizonExportModal: ModalWithData = new ModalWithData();

  ngAfterViewInit(): void {
    this.availableColumns = this.loadAvailableColumns();
    this.selectedColumns = this.selectedColumnsService.selectedColumns();
    this.changeDetector.detectChanges();
  }

  setDataToTableAndReload() {
    this.paginator.activatedFilters =
      this.dealFilterService.getActivatedFilters();
    this.paginator.search = this.dealFilterService.getSearchInput();
    this.paginator.searchMode = this.dealFilterService.getSearchMode();
    this.paginator.reloadTrigger$.next();
  }

  private loadAvailableColumns(): Column[] {
    return [
      new Column('closed_date').setTemplate(this.closedDateTemplate),
      new Column('year').setWidth(100),
      new Column('quantityInMWh')
        .setWidth(140)
        .setTemplate(this.quantityTemplate)
        .setAlign('right'),
      new Column('quantityInTonns')
        .setWidth(140)
        .setTemplate(this.quantityInTonnsTemplate)
        .setAlign('right'),
      new Column('price')
        .setTemplate(this.wholesalePriceTemplate)
        .setAlign('center'),
      new Column('counterpart_company'),
      new Column('product_key').setTemplate(this.productTemplate),
      new Column('energy_source'),
      new Column('region'),
      new Column('system_age'),
      new Column('deal_type').setWidth(180).setAlign('center'),
      new Column('quantity_delivery_confirmed')
        .setAlign('center')
        .setWidth(130),
      new Column('quantity_cancelled').setWidth(130),
      new Column('deal_id'),
      new Column('id'),
      new Column('segment').setWidth(150),
      new Column('redistributor'),
      new Column('vg'),
      new Column('customer'),
      new Column('address'),
      new Column('quantity_delivery_subaccount'),
      new Column('statement_cancellation')
        .setTemplate(this.cancelDealTemplate)
        .setWidth(150),
      new Column('cancel_deal')
        .setTemplate(this.actionsTemplate)
        .setWidth(100)
        .setSortable(false),
      new Column('binding_period_start')
        .setWidth(150)
        .setTemplate(this.bindingPeriodStartDateTemplate)
        .setSortable(true),
      new Column('binding_period_end')
        .setWidth(150)
        .setTemplate(this.bindingPeriodEndDateTemplate)
        .setSortable(true),
      new Column('delivery_start'),
      new Column('delivery_end'),
      new Column('completion_date'),
      new Column('contact_person'),
      new Column('section'),
      new Column('comment'),
      new Column('status'),
      new Column('quantity_delivery_date'),
      new Column('quantity_cancellation_date').setAlign('center'),
      new Column('BOUND_OFFER').setTemplate(this.boundOfferTemplate),
      new Column('horizonStatus')
        .setTemplate(this.horizonTemplate)
        .setLabel('Horizon Status'),
      new Column('batch_cancellation_id').setLabel('Batch Cancellation ID'),
    ];
  }

  public updateCancellation(row: { _submitting: boolean } & DealModel) {
    if (!row.id) {
      return;
    }

    row._submitting = true;
    this.dealsService
      .updateCancellationStatement(row.id, {
        statement_cancellation: !row.statement_cancellation,
      })
      .pipe(
        take(1),
        tap(x => (row.statement_cancellation = x.statement_cancellation)),
        tap(_ => this.toastService.showSavedToast()),
        finalize(() => {
          row._submitting = false;
        })
      )
      .subscribe();
  }

  triggerHorizonExport(dealId: string) {
    this.horizonService
      .triggerHorizonExport$(dealId)
      .pipe(
        take(1),
        tap(() => this.triggerHorizonExportModal.reset()),
        tap(_ => this.toastService.showSavedToast())
      )
      .subscribe();
  }

  openHorizonConfirmationModal(deal: DealModel) {
    this.triggerHorizonExportModal.visible = true;
    this.triggerHorizonExportModal.deal = deal;
  }
}
