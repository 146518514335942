import { Component } from '@angular/core';

@Component({
  selector: 'gep-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  yearDate?: string = new Date().getFullYear().toString();
}
