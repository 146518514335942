import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../models/user.model';

@Pipe({
  name: 'hasAnyRole',
})
export class HasAnyRolePipe implements PipeTransform {
  transform(value: Role[], ...roles: Role[]): boolean {
    return value.some(roleToCheck => roles.indexOf(roleToCheck) > -1);
  }
}
