import { Component, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PsiService } from '../../services/psi.service';
import { Subject } from 'rxjs';
import { error } from '@angular/compiler-cli/src/transformers/util';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gep-psi-import-upload',
  templateUrl: './psi-import-upload.component.html',
  styleUrls: ['./psi-import-upload.component.scss'],
})
export class PsiImportUploadComponent implements OnDestroy {
  public selectedFile: File | null = null;
  public status: 'ready' | 'uploading' | 'success' | 'failed' = 'ready';
  public error_message: string | null = null;

  _destroy$ = new Subject<void>();

  constructor(private psiService: PsiService) {}

  fileChanged($event: any) {
    if ($event.detail.length === 0) {
      this.selectedFile = null;
    } else {
      this.selectedFile = $event.detail[0].file;
    }
  }

  uploadFile() {
    this.status = 'uploading';
    this.psiService
      .uploadPsiExcel(this.selectedFile!)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        res => {
          this.status = 'success';
        },
        error => {
          this.status = 'failed';
          if (error instanceof HttpErrorResponse) {
            if (error.error) {
              this.error_message = error.error.detail;
            } else {
              this.error_message = error.message;
            }
          } else {
            this.error_message = 'Unknown error!';
          }
        }
      );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
