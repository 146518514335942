import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Role } from '../../../../models/user.model';
import { take, tap } from 'rxjs/operators';
import { UserService } from '../../../../services/user.service';
import { CounterpartService } from '../../../../services/counterpart.service';
import { CounterpartUserModel } from '../../../../models/counterpart-user.model';
@Component({
  selector: 'gep-counterpart-users-actions-renderer',
  templateUrl: './counterpart-users-actions-renderer.component.html',
})
export class CounterpartUsersActionsRendererComponent
  implements ICellRendererAngularComp
{
  protected readonly Role = Role;

  row?: CounterpartUserModel;

  constructor(
    private counterpartService: CounterpartService,
    protected userService: UserService
  ) {}

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.row = params.node.data;
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  public updateUser(row: CounterpartUserModel) {
    const updatedValue = !row.notify_by_email;
    this.counterpartService
      .updateCounterpartSettings(row.oid, {
        notify_by_email: updatedValue,
      })
      .pipe(
        take(1),
        tap(x => (row.notify_by_email = updatedValue))
      )
      .subscribe();
  }
}
