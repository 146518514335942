<ng-container *ngIf="internalName !== null">
  <eon-ui-input
    type="number"
    [attr.value]="value$ | async"
    [attr.size]="size"
    [attr.disabled]="disabled$ | async"
    [attr.label]="label"
    [attr.placeholder]="placeholder"
    [attr.name]="internalName"
    [attr.min]="min"
    [attr.max]="max"
    [trailingIconName]="trailingIconName"
    [attr.label-outside]="labelOutside"
    (valueChanged)="onValueChange($event)"
    (inputBlur)="onInputBlur()"
  >
  </eon-ui-input>
</ng-container>
