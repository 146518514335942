import { map, take, takeUntil } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Column } from '../../components/pagination/column';
import { PageRetrieverInterface } from '../../components/pagination/page-retriever.interface';

import { DealService } from '../../services/deal.service';
import { DealModel, Status } from '../../models/deal.model';
import { OfferModel } from '../../models/offer.model';
import { CounterpartService } from '../../services/counterpart.service';
import { Router } from '@angular/router';
import { PaginationTableWrapperComponent } from '../../components/pagination/pagination-table-wrapper.component';
import { BehaviorSubject, of, ReplaySubject, Subject, switchMap } from 'rxjs';
import { DealSearchSortOption } from '../../modules/deal-search/model/deal-search-sort-option';
import { ModalWithData } from '../../models/modal-with-data.model';
import { Style } from '../../modules/gep-controls/models/style';
import { Icon } from '../../modules/gep-controls/models/icon';
import { ButtonSize } from '../../modules/gep-controls/models/button-size';
import { QuantityUnit } from '../../models/quantity-unit.model';
import { TreeNode } from '../../models/tree-node.model';
import { CheckboxTreeComponent } from '../../modules/gep-forms/components/checkbox-tree/checkbox-tree.component';

@Component({
  selector: 'gep-offers-page',
  templateUrl: './offers-page.component.html',
  styleUrls: ['./offers-page.component.scss'],
})
export class OffersPageComponent implements AfterViewInit, OnDestroy {
  constructor(
    private dealService: DealService,
    private counterpartService: CounterpartService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {}

  readonly STATUS = Status;
  protected readonly Icon = Icon;
  protected readonly ButtonSize = ButtonSize;
  protected readonly Style = Style;
  protected readonly QuantityUnit = QuantityUnit;

  public selectedColumns: string[] = [];
  public availableColumns: Column[] = [];

  @ViewChild('paginator')
  public paginator!: PaginationTableWrapperComponent;

  @ViewChild('productTemplate', { read: TemplateRef })
  public productTemplate!: TemplateRef<any>;

  @ViewChild('productYear', { read: TemplateRef })
  public productYear!: TemplateRef<any>;

  @ViewChild('quantityTemplate', { read: TemplateRef })
  public quantityTemplate!: TemplateRef<any>;

  @ViewChild('quantityInTonns', { read: TemplateRef })
  public quantityInTonnsTemplate!: TemplateRef<any>;

  @ViewChild('productStatus', { read: TemplateRef })
  public productStatus!: TemplateRef<any>;

  @ViewChild('priceTemplate', { read: TemplateRef })
  public priceTemplate!: TemplateRef<any>;

  @ViewChild('productActions', { read: TemplateRef })
  public productActions!: TemplateRef<any>;

  @ViewChild('bindingPeriodStartDateTemplate', { read: TemplateRef })
  public bindingPeriodStartDateTemplate!: TemplateRef<any>;

  @ViewChild('bindingPeriodEndDateTemplate', { read: TemplateRef })
  public bindingPeriodEndDateTemplate!: TemplateRef<any>;

  @ViewChild('gepCheckboxTree')
  public checkboxTree!: CheckboxTreeComponent;

  countCounterpartsToBeInformed$ = new ReplaySubject<number>();

  dealModal: ModalWithData = new ModalWithData();

  public informModal: {
    visible: boolean;
    counterpartUsersTree: TreeNode[];
    deal?: DealModel;
    selectAll: boolean;
    disableSendButton: boolean;
  } = {
    visible: false,
    counterpartUsersTree: [],
    selectAll: false,
    disableSendButton: false,
  };

  public offersModal: {
    visible: boolean;
    bindingSuccessful: boolean | null;
    loading: boolean | null;
    offers?: OfferModel[];
    deal?: DealModel;
  } = {
    visible: false,
    bindingSuccessful: null,
    loading: null,
  };

  protected defaultOrderBy: DealSearchSortOption[] = [
    { field: 'created_at', direction: 'desc' },
    { field: '_id', direction: 'asc' },
  ];

  private readonly confirmCancellation$ = new Subject<boolean>();

  readonly showConfirmModal$ = new BehaviorSubject(false);
  readonly _destroy$ = new Subject<void>();

  dataRetriever: PageRetrieverInterface<DealModel> = {
    load: (
      page: number,
      page_size: number,
      search: { [p: string]: string },
      orderby: DealSearchSortOption[]
    ) => {
      return this.dealService
        .getDealsOffers(page, page_size, search, orderby)
        .pipe(
          map(p => {
            p.data = p.data.map(row => {
              if (row.binding_period_end && row.binding_period_start) {
                row.binding_period_end = new Date(row.binding_period_end);
                row.binding_period_end.toLocaleString();

                row.binding_period_start = new Date(row.binding_period_start);
                row.binding_period_start.toLocaleString();
              }

              row.offers?.map((offer: OfferModel) => {
                offer.bound_date &&
                  (offer.bound_date = new Date(offer.bound_date));
                offer.bound_date && offer.bound_date.toLocaleString();

                offer.created_at = new Date(offer.created_at);
                offer.created_at.toLocaleString();

                offer.updated_at &&
                  (offer.updated_at = new Date(offer.updated_at));
                offer.updated_at && offer.updated_at.toLocaleString();
                return offer;
              });

              // TODO add a 'buffer' of 30 minutes for the binding deadline
              // row.binding_period_end?.setMinutes(
              //   row.binding_period_end?.getMinutes() -
              //   this.binding_period_end_BUFFER_MINS
              // );
              return Object.assign(new DealModel(), row);
            });

            return p;
          })
        );
    },
  };

  private loadAvailableColumns(): Column[] {
    const columns = [
      new Column('deal_id').setSortable(false),
      new Column('id').setSortable(false),
      new Column('deal_type').setWidth(160).setAlign('center'),
      new Column('product')
        .setTemplate(this.productTemplate)
        .setSortable(false),
      new Column('binding_period_start')
        .setTemplate(this.bindingPeriodStartDateTemplate)
        .setSortable(true),
      new Column('binding_period_end')
        .setTemplate(this.bindingPeriodEndDateTemplate)
        .setSortable(true),
      new Column('customer').setSortable(false),
      new Column('contact_person').setSortable(false),
      new Column('year')
        .setTemplate(this.productYear)
        .setSortable(false)
        .setWidth(90),
      new Column('quantityInMWh')
        .setTemplate(this.quantityTemplate)
        .setSortable(false)
        .setWidth(130),
      new Column('quantityInTonns')
        .setTemplate(this.quantityInTonnsTemplate)
        .setSortable(false)
        .setWidth(130),
      new Column('vg'),
      new Column('region').setSortable(false),
      new Column('comment').setSortable(false),
      new Column('energy_source').setSortable(false),
      new Column('redistributor').setSortable(false),
      new Column('system_age').setSortable(false),
      new Column('status')
        .setTemplate(this.productStatus)
        .setSortable(false)
        .setWidth(180),
      new Column('best_price')
        .setTemplate(this.priceTemplate)
        .setSortable(false)
        .setWidth(120),
      new Column('product_actions')
        .setTemplate(this.productActions)
        .setSortable(false)
        .setWidth(270),
    ];

    return columns;
  }

  ngAfterViewInit(): void {
    this.availableColumns = this.loadAvailableColumns();
    this.selectedColumns = [
      'product',
      'binding_period_start',
      'binding_period_end',
      'customer',
      'year',
      'quantityInMWh',
      'quantityInTonns',
      'status',
      'best_price',
      'product_actions',
    ];
    this.changeDetector.detectChanges();
  }

  /*
    Inform modal
   */

  showInformModal(deal: DealModel): void {
    this.informModal.visible = true;
    this.informModal.deal = deal;
    this.informModal.disableSendButton =
      deal.status === Status.PRICE_COLLECTION;
    this.counterpartService
      .getCounterpartUsers()
      .pipe(takeUntil(this._destroy$))
      .subscribe(counterpartUsers => {
        const counterpartUsersSorted = counterpartUsers.sort((a, b) => {
          if (a.company_name === null || b.company_name === null) {
            return 1;
          }

          if (a.company_name === 'N\\A' || b.company_name === 'N\\A') {
            return 1;
          }

          return a.company_name.localeCompare(b.company_name);
        });

        this.informModal.counterpartUsersTree = this.createTree(
          counterpartUsersSorted
        );
      });
  }

  resetInformModal(): void {
    this.informModal.visible = false;
    this.informModal.counterpartUsersTree = [];
    this.informModal.deal = undefined;
  }

  onInformModalCheckboxChange(tree: TreeNode[]) {
    this.informModal.counterpartUsersTree = tree;

    const count = tree.reduce((sum, counterPart) => {
      return (
        sum +
        (counterPart.children
          ? counterPart.children.filter(child => child.checked).length
          : 0)
      );
    }, 0);
    this.countCounterpartsToBeInformed$.next(count);

    this.informModal.disableSendButton =
      this.informModal.counterpartUsersTree.every(
        counterPartUser => !counterPartUser.checked
      ) && this.informModal.deal?.status === Status.PRICE_COLLECTION;
  }

  selectAllCounterparts(event: any) {
    const value = event.detail;
    this.checkboxTree.selectAll(value);
  }

  /*
    Offers modal
   */

  showOffersModal(deal: DealModel): void {
    this.offersModal.visible = true;
    this.offersModal.deal = deal;
    this.offersModal.offers = this.sortOffers(deal);
  }

  sortOffers(deal: DealModel): OfferModel[] | undefined {
    if (!deal.quantityInMWh || deal.quantityInMWh >= 0) {
      return deal.offers
        ?.sort((a, b) =>
          (a.updated_at || a.created_at) < (b.updated_at || b.created_at)
            ? 1
            : -1
        )
        .sort((a, b) => a.price - b.price);
    } else {
      return deal.offers
        ?.sort((a, b) =>
          (a.updated_at || a.created_at) < (b.updated_at || b.created_at)
            ? 1
            : -1
        )
        .sort((a, b) => b.price - a.price);
    }
  }

  resetOffersModal(): void {
    this.offersModal.visible = false;
    this.offersModal.bindingSuccessful = false;
    this.offersModal.offers = [];
    this.offersModal.deal = undefined;
    this.offersModal.loading = null;
  }

  notify(deal?: DealModel) {
    if (deal?.id) {
      const cps_to_be_informed: string[] = [];

      this.informModal.counterpartUsersTree.forEach(node => {
        node?.children?.forEach(child => {
          if (child.checked && child.value) {
            cps_to_be_informed.push(child.value);
          }
        });
      });

      this.dealService
        .updateDealStatus(deal.id, {
          status: Status.PRICE_COLLECTION,
          cps_to_be_informed,
        })
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          deal.status = Status.PRICE_COLLECTION;
          this.resetInformModal();
        });
    } else {
      console.error('Invalid parameters');
    }
  }

  bind(deal?: DealModel, offer?: OfferModel) {
    if (deal?.id && offer) {
      this.offersModal.loading = true;
      this.dealService
        .updateDealStatus(deal.id, {
          status: Status.BINDING,
          counterpart_oid: offer.counterpart_oid,
        })
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: () => {
            deal.status = Status.BINDING;
            this.offersModal.bindingSuccessful = true;
            this.offersModal.loading = false;
            // Hide offers modal and reload table, after click on bind
            setTimeout(() => {
              this.resetOffersModal();
              this.paginator.reloadTrigger$.next();
            }, 3000);
          },
          error: () => {
            this.offersModal.bindingSuccessful = false;
            this.offersModal.loading = false;
          },
        });
    } else {
      console.error('Invalid parameters');
    }
  }

  edit(deal?: DealModel, offer?: OfferModel) {
    this.router.navigateByUrl(`/enquiry/${deal!.id}`);
  }

  resetToPriceCollection(deal: DealModel) {
    this.dealService
      .updateDealStatus(deal.id!, { status: Status.PRICE_COLLECTION })
      .pipe(
        map(() => (deal.status = Status.PRICE_COLLECTION)),
        map(() => this.paginator!.reloadTrigger$.next()),
        take(1)
      )
      .subscribe();
  }

  cloneDeal(deal: DealModel) {
    this.dealService
      .cloneDeal(deal)
      .pipe(take(1))
      .subscribe(newDealId =>
        this.router.navigateByUrl(`/enquiry/${newDealId.doc_id}`)
      );
  }

  cancelDeal(deal: DealModel) {
    this.showConfirmModal$.next(true);
    this.confirmCancellation$
      .pipe(
        switchMap(isConfirmed => {
          if (isConfirmed) {
            return this.dealService
              .updateDealStatus(deal.id!, {
                status: Status.CANCELLED,
              })
              .pipe(map(() => (deal.status = Status.CANCELLED)));
          }

          return of(null);
        }),
        take(1)
      )
      .subscribe();
  }

  close(deal: DealModel): void {
    if (deal?.id) {
      this.dealModal.reset();
      this.dealService
        .updateDealStatus(deal.id, { status: Status.CLOSED })
        .pipe(take(1))
        .subscribe(() => {
          deal.status = Status.CLOSED;
        });
    } else {
      console.error('Invalid parameters');
    }
  }

  onConfirm(isConfirmed: boolean) {
    this.confirmCancellation$.next(isConfirmed);
    this.showConfirmModal$.next(false);
  }

  refreshDealsAfterManualEdit(): void {
    this.resetOffersModal();
    this.paginator.reloadTrigger$.next();
  }

  showCloseDealModal(row: DealModel) {
    this.dealModal.deal = row;
    this.dealModal.show();
  }

  createTree(data: any[]): TreeNode[] {
    const tree: TreeNode[] = [];
    const groupByCompanyName = data.reduce((groups, item) => {
      const group = groups[item.company_name] || [];
      group.push(item);
      groups[item.company_name] = group;
      return groups;
    }, {});

    for (const companyName in groupByCompanyName) {
      const children = groupByCompanyName[companyName].map((item: any) => {
        return {
          label: item.name,
          value: item.oid,
          checked: item.notify_by_email,
        } as TreeNode;
      });

      tree.push({
        label: companyName,
        children: children,
        expanded: false,
      });
    }

    return tree;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
