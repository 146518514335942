<eon-ui-navigation-meta slot="meta">
  <eon-ui-navigation-column contentAlignment="center" contentAlignmentMobile="center" grow="true" growMobile="true">
    <eon-ui-navigation-meta-link
      [text]="'OPEN_DEALS' | translate"
      [routerLink]="'/offers'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/offers'"
    ></eon-ui-navigation-meta-link>
    <eon-ui-navigation-meta-link
      [text]="'CLOSED_DEALS' | translate"
      [routerLink]="'/deals'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/deals'"
    ></eon-ui-navigation-meta-link>
    <div ngbDropdown>
      <eon-ui-navigation-meta-link
        [text]="'import' | translate"
        class="toggelItem"
        ngbDropdownToggle
      >
        <div ngbDropdownMenu>
          <div
            ngbDropdownItem
            *ngIf="userService.hasRole(ROLE.GREENDESK) || userService.hasRole(ROLE.ADMIN_SYS)"
          >
            <eon-ui-navigation-meta-link
              [routerLink]="'/psi-import'"
              text="{{ 'psi_import' | translate }}"
              icon="upload"
              scheme="darkgrey"
            ></eon-ui-navigation-meta-link>
          </div>
          <div
            ngbDropdownItem
            *ngIf="
              userService.hasRole(ROLE.GREENDESK) ||
              userService.hasRole(ROLE.ADMIN_SYS) ||
              userService.hasRole(ROLE.PRODUCER)
            "
          >
            <eon-ui-navigation-meta-link
              [routerLink]="'/memphis-import'"
              text="{{ 'memphis_import' | translate }}"
              icon="upload"
              scheme="darkgrey"
            ></eon-ui-navigation-meta-link>
          </div>
        </div>
      </eon-ui-navigation-meta-link>
    </div>
  </eon-ui-navigation-column>
</eon-ui-navigation-meta>
<section>
  <div class="heading">
    <eon-ui-headline
      text="{{
        route.includes('deals')
          ? ('CLOSED_DEALS' | translate)
          : ('DEALS' | translate)
      }}"
      size="h4"
      scheme="darkgrey"
    ></eon-ui-headline>
  </div>
</section>
