<div class="header-item">
  <div
    *ngIf="params.enableMenu"
    #menuButton
    class="customHeaderMenuButton"
    (click)="onMenuClicked()"
  >
    <i class="fa"></i>
  </div>
  <div class="customHeaderLabel">{{ params.displayName }}</div>
  <div class="sort-buttons">
    <div
      *ngIf="params.enableSorting"
      (click)="onSortRequested('asc', $event)"
      [ngClass]="ascSort"
    >
      <eon-ui-icon name="arrow_down" class="sort-button" [size]="ButtonSize.Small"></eon-ui-icon>
    </div>
    <div
      *ngIf="params.enableSorting"
      [ngClass]="descSort"
      (click)="onSortRequested('desc', $event)"
    >
      <eon-ui-icon name="arrow_up" class="sort-button" [size]="ButtonSize.Small"></eon-ui-icon>
    </div>
  </div>
</div>
