import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Style } from '../../../gep-controls/models/style';
import {
  combineLatestWith,
  debounceTime,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  BehaviorSubject,
  distinctUntilChanged,
  mergeWith,
  Observable,
  of,
  startWith,
  Subject,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalWithData } from '../../../../models/modal-with-data.model';
import { DealService } from '../../../../services/deal.service';
import { Location } from '@angular/common';
import { DealModel } from '../../../../models/deal.model';
import { AdminService } from '../../../../services/admin.service';
import {
  AdminUpdateProperties,
  AttributeDataType,
  DealAttribute,
} from '../../../../models/admin-update-properties.model';

@Component({
  selector: 'gep-edit-deal-page',
  templateUrl: './edit-deal-page.component.html',
  styleUrls: ['./edit-deal-page.component.scss'],
})
export class EditDealPageComponent implements OnInit, OnDestroy {
  protected readonly Style = Style;
  protected readonly AttributeDataType = AttributeDataType;

  areYouSureModal: ModalWithData = new ModalWithData();
  successModal: ModalWithData = new ModalWithData();

  form: UntypedFormGroup = this.getInitFormGroup();

  dealId: BehaviorSubject<string> = new BehaviorSubject<string>('');

  adminUpdateProperties$: Observable<AdminUpdateProperties> =
    this.adminService.getAdminAttributes$();

  _destroy$ = new Subject<void>();

  constructor(
    protected dealService: DealService,
    protected adminService: AdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    const dealId = this.activatedRoute.snapshot.paramMap.get('id');
    if (dealId) {
      this.form.get('dealId')?.setValue(dealId);
      this.dealId.next(dealId);
    }
  }
  onCancel() {
    this.location.back();
  }

  saveChanges() {
    this.adminService
      .updateDealAttribute(
        this.form.get('dealId')?.value,
        this.form.get('dealAttribute')?.value,
        this.form.get('reasonOfModification')?.value,
        this.form.get('newValue')?.value,
        this.form.get('offerId')?.value,
        this.form.get('offerAttribute')?.value
      )
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.areYouSureModal.close()),
        tap(() => this.successModal.show())
      )
      .subscribe();
  }

  getInitFormGroup(): UntypedFormGroup {
    const formGroup = new FormGroup<any>({
      dealId: new FormControl('', Validators.required),
      dealAttribute: new FormControl('', Validators.required),
      reasonOfModification: new FormControl('', Validators.required),
      oldValue: new FormControl({ value: '', disabled: true }),
      newValue: new FormControl(),

      offerId: new FormControl(),
      offerAttribute: new FormControl(),
    });

    formGroup
      .get('dealId')
      ?.valueChanges.pipe(
        tap(dealId => {
          this.dealId.next(dealId);
        })
      )
      .subscribe();

    return formGroup;
  }

  readonly selectedDeal$: Observable<DealModel | undefined> = this.dealId.pipe(
    switchMap(x => {
      if (x.length === 24) {
        // id has to be 24 characters
        return this.dealService.getDealById(x);
      }
      return of(undefined);
    })
  );

  readonly selectedDealAttribute$: Observable<DealAttribute | undefined> =
    this.form.get('dealAttribute')!.valueChanges.pipe(
      tap(() => {
        this.form.get('newValue')?.reset();
      }),
      combineLatestWith(this.adminUpdateProperties$),
      map(([_, adminUpdateProperties]) =>
        adminUpdateProperties.dealAttributes!.find(
          dealAttribute =>
            dealAttribute.fieldName === this.form.get('dealAttribute')?.value
        )
      ),
      distinctUntilChanged()
    );

  readonly selectedOfferAttribute$: Observable<DealAttribute | undefined> =
    this.form.get('offerAttribute')!.valueChanges.pipe(
      tap(() => {
        this.form.get('newValue')?.reset();
      }),
      combineLatestWith(this.adminUpdateProperties$),
      map(([_, adminUpdateProperties]) =>
        adminUpdateProperties.offerAttributes!.find(
          offer => offer.fieldName === this.form.get('offerAttribute')?.value
        )
      )
    );

  readonly selectedAttribute$: Observable<DealAttribute | undefined> =
    this.selectedDealAttribute$.pipe(
      mergeWith(this.selectedOfferAttribute$),
      distinctUntilChanged()
    );

  readonly formValidity$ = this.form.valueChanges.pipe(
    debounceTime(250),
    map(() => {
      let isFormValid: boolean = this.form.valid;

      if (this.form.get('dealAttribute')?.value === 'bound_offer') {
        isFormValid =
          isFormValid && this.form.get('offerAttribute')?.value !== null;
      } else if (this.form.get('dealAttribute')?.value === 'offers') {
        isFormValid =
          isFormValid &&
          this.form.get('offerAttribute')?.value !== null &&
          this.form.get('offerId')?.value !== null;
      }

      return isFormValid;
    }),

    startWith(false)
  );

  readonly oldValueOffer$ = this.selectedOfferAttribute$.pipe(
    combineLatestWith(this.selectedDeal$),
    map(([offerAttribute, selectedDeal]) => {
      const attributeName: string = offerAttribute!.fieldName;

      const dealAttribute = this.form.get('dealAttribute')?.value;

      let selectedOffer: any = undefined;

      if (dealAttribute === 'offers') {
        selectedOffer = selectedDeal!.offers!.find(
          offer => offer.counterpart_oid === this.form.get('offerId')!.value
        );
      } else if (dealAttribute === 'bound_offer') {
        selectedOffer = selectedDeal!.bound_offer;
      }

      let oldValue = selectedOffer[attributeName];

      if (offerAttribute!.type === AttributeDataType.DATETIME) {
        oldValue = new Date(oldValue);
      }

      this.form.get('oldValue')?.setValue(oldValue);
    })
  );

  readonly oldValue$ = this.selectedDealAttribute$.pipe(
    combineLatestWith(this.selectedDeal$),
    map(([selectedAttribute, selectedDeal]) => {
      const attributeName: string = selectedAttribute!.fieldName;
      const selectedDealUnsafe = selectedDeal as any;

      let oldValue = selectedDealUnsafe[attributeName];

      if (selectedAttribute!.type === AttributeDataType.DATETIME) {
        oldValue = new Date(oldValue);
      }

      this.form.get('oldValue')?.setValue(oldValue);

      return oldValue;
    })
  );

  identityStringTransformer(option: string): string {
    return String(option);
  }

  showConfirmationModal() {
    this.areYouSureModal.newValue = this.form.get('newValue')?.value;
    this.areYouSureModal.oldValue = this.form.get('oldValue')?.value;
    this.areYouSureModal.show();
  }

  closeConfirmationModal() {
    this.areYouSureModal.reset();
    this.areYouSureModal.close();
  }

  closeSuccessModal() {
    this.successModal.close();
    this.router.navigate(['/admin/edit-deal']);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
