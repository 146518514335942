<ng-container *ngIf="form$ | async as form">
  <ng-container *ngIf="productConfigurations$ | async as productConfigurations">
    <form [formGroup]="form">
      <h2>{{ 'customer' | translate }}</h2>
      <fieldset>
        <gep-input
          [label]="'enquiryForm.kamName' | translate"
          formControlName="contact_person"
          [placeholder]="'enquiryForm.kamNamePlaceholder' | translate"
        ></gep-input>
        <gep-input
          [label]="'enquiryForm.gpName' | translate"
          formControlName="customer"
          [placeholder]="'enquiryForm.gpNamePlaceholder' | translate"
        ></gep-input>
      </fieldset>
      <fieldset>
        <gep-input
          [label]="'enquiryForm.gpAddress' | translate"
          formControlName="address"
        ></gep-input>
      </fieldset>
      <fieldset>
        <gep-dropdown
          [label]="'enquiryForm.salesCompany' | translate"
          formControlName="vg"
          [options]="(salesCompanies$ | async)!"
          [optionLabelTransformer]="identityStringTransformer"
          [optionValueTransformer]="identityStringTransformer"
        ></gep-dropdown>
        <gep-dropdown
          [label]="'enquiryForm.segment' | translate"
          formControlName="segment"
          [options]="(segments$ | async)!"
          [optionValueTransformer]="identityStringTransformer"
          [optionLabelTransformer]="identityStringTransformer"
        ></gep-dropdown>
      </fieldset>
      <h2>{{ 'redistributor' | translate }}</h2>
      <fieldset>
        <ng-container
          *ngIf="redistributorOptions$ | async as redistributorOptions"
        >
          <gep-radio-select-group
            [options]="redistributorOptions"
            [optionLabelTransformer]="(redistributorTransformerFn$ | async)!"
            formControlName="redistributor"
            [horizontal]="true"
          ></gep-radio-select-group>
        </ng-container>
      </fieldset>
      <h2>{{ 'time of offer' | translate }}</h2>
      <fieldset>
        <gep-binding-period-datetime-picker
          [datePickerLabel]="'pricing date' | translate"
          [timeDropdownLabel]="'pricing time' | translate"
          formControlName="binding_period_start"
        >
        </gep-binding-period-datetime-picker>
      </fieldset>
      <fieldset>
        <gep-binding-period-datetime-picker
          [datePickerLabel]="'validity date' | translate"
          [timeDropdownLabel]="'validity time' | translate"
          formControlName="binding_period_end"
        >
        </gep-binding-period-datetime-picker>
      </fieldset>
      <h2>{{ 'product details' | translate }}</h2>
      <fieldset>
        <gep-product-selection
          [productConfigurations]="productConfigurations!"
          [deal]="deal$ | async"
          formControlName="productSelection"
        ></gep-product-selection>
      </fieldset>
      <fieldset>
        <gep-input
          [label]="'enquiryForm.comment' | translate"
          formControlName="comment"
        ></gep-input>
      </fieldset>
      <h2>{{ 'deal_type' | translate }}</h2>
      <fieldset>
        <ng-container *ngIf="dealTypesWithTooltips$ | async as dealTypes">
          <ng-container
            *ngIf="dealTypeLabelTransformer$ | async as dealTypeTransformerFn"
          >
            <gep-radio-select-group
              [options]="dealTypes"
              [optionLabelTransformer]="dealTypeTransformerFn"
              formControlName="deal_type"
            ></gep-radio-select-group>
          </ng-container>
        </ng-container>
      </fieldset>
      <h2>{{ 'certificate_of_invalidation' | translate }}</h2>
      <fieldset>
        <gep-toggle
          *ngIf="statementCancellationSetter$ | async"
          #toggleStatementCancellation
          [checked]="(statementCancellation$ | async)!"
          [label]="'enquiryForm.statementCancellation.toggle' | translate"
          (changeSelected)="statementCancellationToggleChanged($event)"
        ></gep-toggle>
      </fieldset>
      <div class="deliveries">
        <ng-container
          *ngFor="
            let deliveryGroup of $any(form.get('deliveries')!).controls;
            let dealCount = index
          "
        >
          <div [formGroup]="$any(deliveryGroup)">
            <span>Deal {{ dealCount + 1 }}</span>
            <fieldset>
              <gep-dropdown
                formControlName="delivery_year"
                [options]="deliveryYears"
                [optionLabelTransformer]="identityStringTransformer"
                [optionValueTransformer]="identityStringTransformer"
                [label]="'year' | translate"
              ></gep-dropdown>
              <gep-input
                *ngIf="isCO2ProductSelected$ | async"
                [label]="'quantityInTonns' | translate"
                formControlName="quantityInTonns"
              ></gep-input>
              <gep-input
                [label]="'quantityInMWh' | translate"
                formControlName="quantity"
              ></gep-input>
            </fieldset>
            <fieldset>
              <gep-input
                formControlName="deal_id"
                [label]="'PSI ID / Campaign ID' | translate"
                placeholder="e.g. YYYY/123456 / 01234567890"
              >
              </gep-input>
            </fieldset>

            <div
              class="error-box"
              *ngIf="hasMinLengthError($any(deliveryGroup), 'deal_id')"
            >
              {{ 'MIN_LENGTH_ERROR_MESSAGE' | translate }}
            </div>

            <fieldset
              *ngIf="
                (fromGroupDealTypeChangedSideEffect$ | async) ===
                  'ENQUIRY_SELL' ||
                (formGroupPriceFillWithKpiSideEffect$ | async)
              "
            >
              <gep-input
                formControlName="price"
                [label]="'Price (excl. transaction cost)'"
                placeholder="e.g. 1.1"
              ></gep-input>
            </fieldset>
          </div>
        </ng-container>
        <div>
          <gep-button
            [icon]="Icon.Add"
            [size]="ButtonSize.Small"
            [style]="Style.Highlight"
            [label]="'add deal' | translate"
            type="button"
            (click)="addYear(form)"
            *ngIf="(deal$ | async) === null"
          ></gep-button>
        </div>
      </div>
      <div class="form-buttons">
        <gep-button
          [label]="'createEnquiry.cancelButton' | translate"
          type="button"
          [style]="Style.Gray"
          (click)="onCancel()"
        >
        </gep-button>
        <gep-button
          [label]="'createEnquiry.saveButton' | translate"
          type="button"
          (clicked)="onSave(form)"
          [disabled]="(formValidity$ | async) === false"
        >
        </gep-button>
      </div>
    </form>
  </ng-container>
</ng-container>

<gep-modal
  [headline]="'enquiryForm.statementCancellation.modalHeader' | translate"
  [description]="'enquiryForm.statementCancellation.modalQuestion' | translate"
  [positiveButtonStyle]="Style.StandardInverted"
  [visible]="confirmModal.visible"
  [closeButtonText]="'no' | translate"
  [positiveButtonText]="'yes' | translate"
  (positiveButtonClicked)="setStatementCancellation()"
  (closedButtonClicked)="resetStatementCancellationToggle()"
  closeButtonRank="primary"
  modalId="show-statement-cancellation-confirmation"
></gep-modal>
