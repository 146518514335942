<div *ngIf="isGroup; else singleDeal">
  {{total_price}}
</div>

<ng-template #singleDeal>
    <span
    *ngIf="row"
  >{{
      total_price
    }}</span
  >
</ng-template>
