import { Injectable } from '@angular/core';
import { EnquiryPrefillService } from './enquiry-prefill.service';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { ProductService } from '../../../services/product.service';

@Injectable({
  providedIn: 'root',
})
export class CreateEnquiryFacadeService {
  readonly productConfigurations$ = this.productService
    .getProductConfigurations$()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  readonly enquiryDefaults$ = this.productConfigurations$.pipe(
    switchMap(productConfigs =>
      this.enquiryPrefill.getPrefillOptions$(productConfigs)
    )
  );

  constructor(
    private readonly enquiryPrefill: EnquiryPrefillService,
    private readonly productService: ProductService
  ) {}
}
