import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '../../models/user.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'gep-deals-tab-header',
  templateUrl: './deals-tab-header.component.html',
  styleUrls: ['./deals-tab-header.component.scss'],
})
export class DealsTabHeaderComponent implements OnInit {
  readonly ROLE = Role;
  route: string = '';

  constructor(protected router: Router, protected userService: UserService) {}

  ngOnInit(): void {
    this.route = this.router.url;
  }
}
