<ng-container *ngIf="optionsWithLabels$ | async as optionsWithLabels">
  <eon-ui-radio-button-group
    scheme="turquoise"
    [attr.disabled]="disabled$ | async"
    [attr.horizontal-alignment]="horizontal"
    [attr.size]="size"
    [attr.value]="value$ | async"
  >
    <ng-container *ngFor="let optionWithLabel of optionsWithLabels">
      <ng-container
        *ngTemplateOutlet="
          optionWithLabel.tooltip ? withTooltip : withoutTooltip;
          context: { $implicit: { optionWithLabel } }
        "
      >
      </ng-container>
    </ng-container>
  </eon-ui-radio-button-group>
</ng-container>

<ng-template #withTooltip let-optionWithLabel>
  <eon-ui-radio-button
    [ngbTooltip]="optionWithLabel.optionWithLabel.tooltip!"
    [attr.label]="optionWithLabel.optionWithLabel.label"
    [attr.value]="optionWithLabel.optionWithLabel.value"
    [attr.size]="size"
    [attr.disabled]="disabled$ | async"
    (click)="onValueChange(optionWithLabel.optionWithLabel.value)"
  ></eon-ui-radio-button>
</ng-template>

<ng-template #withoutTooltip let-optionWithLabel>
  <eon-ui-radio-button
    [attr.label]="optionWithLabel.optionWithLabel.label"
    [attr.value]="optionWithLabel.optionWithLabel.value"
    [attr.size]="size"
    [attr.disabled]="disabled$ | async"
    (click)="onValueChange(optionWithLabel.optionWithLabel.value)"
  ></eon-ui-radio-button>
</ng-template>
