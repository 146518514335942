<div
  *ngIf="healthStatus$ | async as healthStatus"
  ngbDropdown
  (openChange)="toggleHealthStatusDropdown()"
  class="d-flex"
>
  <button
    ngbDropdownToggle
    [class.active]="healthStatusDropdownIsOpen"
    class="condition-button"
    id="dropdownHealthStatus"
  >
    <eon-ui-icon
      [name]="healthStatus.db_conn ? 'favourite' : 'emergency_numbers'"
      scheme="red500"
    ></eon-ui-icon>
  </button>

  <div
    ngbDropdownMenu
    aria-labelledby="dropdownHealthStatus"
    class="content"
  >
    <eon-ui-headline text="Health Status" size="h5"></eon-ui-headline>
    <table class="tg">
      <tr>
        <td>{{'healthStatus.dbConnection' | translate }}:</td>
        <td>
          <eon-ui-icon
            *ngIf="healthStatus.db_conn; else DBError"
            style="color: greenyellow"
            name="thumb_up"
          ></eon-ui-icon>
        </td>
      </tr>
      <ng-template #DBError>
        <eon-ui-icon style="color: red" name="alert"></eon-ui-icon>
      </ng-template>
      <tr>
        <td>{{'healthStatus.lastAcsIndexing' | translate }}:</td>
        <td>{{ healthStatus.last_acs_indexing | date : 'short' }}</td>
      </tr>
      <tr>
        <td>{{'healthStatus.currentOpenDeals' | translate }}:</td>
        <td>{{ healthStatus.curr_open_deals }}</td>
      </tr>
      <tr>
        <td>{{'healthStatus.latestError' | translate }}:</td>
        <td
          *ngIf="healthStatus.last_error.message.length > 0"
          style="white-space: unset !important"
        >
          {{ healthStatus.last_error.message }}
        </td>
      </tr>
    </table>
  </div>
</div>
