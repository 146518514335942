import { Injectable } from '@angular/core';
import { FilterProperty } from '../../models/filter-property.model';
import {
  DealSearchFilter,
  DealSearchMode,
} from '../../modules/deal-search/model/deal-search-filter';
import { FilterCreationService } from './filter-creation.service';
import { IFilterService } from './filter-service.interface';

@Injectable({
  providedIn: 'root',
})
export class GenericFilterService implements IFilterService {
  filterConfig: FilterProperty<any>[] = [];
  preselectedFilters: DealSearchFilter[] = [];
  activatedFilterByPropertyName: Map<string, DealSearchFilter[]> = new Map<
    string,
    DealSearchFilter[]
  >();
  searchString: string = '';
  searchMode: DealSearchMode = 'all';
  constructor(protected filterCreationService: FilterCreationService) {
    this.createFilterForPreselection();
  }

  createFilterForPreselection(): void {
    this.preselectedFilters.forEach((filter: DealSearchFilter) => {
      this.activatedFilterByPropertyName.set(filter.field, [filter]);
    });
  }

  getFilterConfig(): FilterProperty<any>[] {
    return this.filterConfig;
  }

  setActivatedFilters(field: string, filter: DealSearchFilter[]): void {
    this.activatedFilterByPropertyName.set(field, filter);
  }

  removeActivatedFilter(field: string): void {
    this.activatedFilterByPropertyName.delete(field);
  }

  clearFilters(): void {
    this.activatedFilterByPropertyName = new Map();
  }

  getActivatedFilters(): DealSearchFilter[] {
    let activatedFilters: DealSearchFilter[] = [];

    this.activatedFilterByPropertyName.forEach(
      (value: DealSearchFilter[], field: string, _) => {
        activatedFilters = activatedFilters.concat(value);
      }
    );

    return activatedFilters;
  }

  getSearchInput(): string {
    return this.searchString;
  }

  setSearchInput(value: string) {
    this.searchString = value;
  }

  setSearchMode(value: DealSearchMode) {
    this.searchMode = value;
  }

  getSearchMode(): DealSearchMode {
    return this.searchMode;
  }
}
