import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FormErrorService } from '../../../services/form-error.service';

export type DropdownColorScheme =
  | 'darkgreyOnLimeyellow'
  | 'turquoise'
  | 'turquoiseOnLightgrey'
  | 'turquoiseOnUltralightgrey'
  | 'whiteOnBordeaux'
  | 'whiteOnRed'
  | 'whiteOnTurquoise';

/**
 * @see https://storybook.web-ui.eon.com/master/?path=/docs/dropdown--interactive
 *
 */
@Component({
  selector: 'gep-dropdown-deprecated',
  templateUrl: './dropdown-deprecated.component.html',
  styleUrls: ['./dropdown-deprecated.component.scss'],
})
export class DropdownDeprecatedComponent implements ControlValueAccessor {
  @ViewChild('eonUiDropdown') eonUiDropdownElement?: ElementRef;

  /**
   * Raised when the input has changed. Will emit the current value of the Input field.
   */
  @Output() valueChanged = new EventEmitter<string>();

  /**
   * Raised when the input is focused.
   */
  @Output() inputFocus = new EventEmitter();

  /**
   * Raised when the input is losing focus.
   */
  @Output() inputBlur = new EventEmitter();

  /**
   * **Internal use only!**
   * &nbsp;<br>
   * Please don't change this.
   * @disableControlInStorybook true
   */
  @Input() isFormComponent: boolean = true;

  /**
   * The name of the color scheme of the component.
   * If not set, the component will try to calculate it based on the nearest enclosing [eon-ui-background]{@link Background}.
   */
  @Input() scheme?: DropdownColorScheme;

  /**
   * Sets the label for the dropdown element.
   * @example <eon-ui-dropdown label="First Name"></eon-ui-input>
   */
  @Input() label?: string;

  /**
   * The name Attribute is a string that refers to the native name attribute of an HtmlInputElement.
   */
  @Input() name?: string;

  /**
   * Set to true if the input is required.
   */
  @Input() required?: boolean;

  /**
   * Set to true if the Input is in info state and the info message should be displayed.
   */
  @Input() info: boolean = false;

  /**
   * Sets the text of the info message.
   */
  @Input() infoMessage?: string;

  /**
   * Whether or not the dropdown is disabled.
   */
  @Input() disabled: boolean = false;

  /**
   * Reflects the native html value attribute.
   */
  @Input() value: string = '';

  /**
   * The initial selected value.
   */
  @Input() selected?: string;

  /**
   * &nbsp;<br>
   * The error message that is displayed if the dropdown is invalid. Is listed below the label and sublabel. Hidden (display: none) otherwise.
   * @example <eon-ui-dropdown label="Very important, terms of service" invalid error-message="You need to check this">
   */
  @Input() errorMessage?: string;

  /**
   * Defines the height of the drop down option list.
   * @example "200px", "100px", "auto".
   * If not set, height sticks to the max-height which is calc(100vh - 96px)
   */
  @Input() height?: string;

  /**
   * Set the size of the component
   */
  @Input() size: 'small' | 'normal' = 'normal';

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private errorService: FormErrorService
  ) {
    if (ngControl !== null) {
      ngControl.valueAccessor = this;
    }
  }

  private renderInvalidState() {
    if (Boolean(this.ngControl?.invalid) && Boolean(this.ngControl.touched)) {
      this.eonUiDropdownElement?.nativeElement?.handleCustomValidation(
        this.errorService.getErrorMessage({
          errors: this.ngControl?.errors,
          customErrorMessage: this.errorMessage,
          label: this.label,
        })
      );
    } else {
      this.eonUiDropdownElement?.nativeElement?.handleCustomValidation(
        undefined
      );
    }
  }

  private getErrorMessage(): string {
    return this.errorMessage || 'Error';
  }

  registerOnChange(fn: any): void {
    this.valueChanged.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueChanged.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: string | null): void {
    if (obj) {
      this.value = obj;
      this.renderInvalidState();
    }
  }

  onValueChanged(event: any) {
    this.value = event.detail;
    this.valueChanged.emit(event.detail);

    this.renderInvalidState();
  }

  onInputBlur($event: any) {
    this.inputBlur.emit();
  }

  onInputFocus($event: any) {
    this.inputFocus.emit();
  }
}
