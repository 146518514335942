import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UbaTabHeaderComponent } from './uba-tab-header/uba-tab-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { EonUiComponentsAngularModule } from '@eon-ui/eon-ui-components-angular';

@NgModule({
  declarations: [UbaTabHeaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    EonUiComponentsAngularModule,
  ],
  exports: [UbaTabHeaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UbaHeaderModule {}
