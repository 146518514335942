<div class="pagination" *ngIf="totalPages > 1">
  <eon-ui-button
    icon="chevron_bold_left"
    size="small"
    [disabled]="page === 0"
    (click)="addPage(-1)"
  ></eon-ui-button>
  <gep-input-deprecated
    [(ngModel)]="displayedPage"
    (ngModelChange)="pageChangedEvent($event)"
    type="number"
    size="small"
  >
  </gep-input-deprecated>
  <eon-ui-text text-style="copy">of {{ totalPages }}</eon-ui-text>
  <eon-ui-button
    icon="chevron_bold_right"
    size="small"
    [disabled]="page >= totalPages - 1"
    (click)="addPage(1)"
  ></eon-ui-button>
</div>
