import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'gep-quantity-renderer',
  template: '{{output}}',
})
export class QuantityRendererComponent implements ICellRendererAngularComp {
  output: string = '';
  unit: string = 'MWh';

  agInit(params: CustomQuantityRenderParams): void {
    if (params.unit) {
      this.unit = params.unit;
    }
    this.output = params.value
      ? Number(params.value).toLocaleString('de-DE') + ' ' + this.unit
      : '';
  }
  refresh(params: CustomQuantityRenderParams): boolean {
    return false;
  }
}

interface CustomQuantityRenderParams extends ICellRendererParams {
  unit: string;
}
