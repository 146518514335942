import { Injectable } from '@angular/core';
import { PageModel } from '../models/page.model';
import { DealModel } from '../models/deal.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HorizonService {
  constructor(private http: HttpClient) {}

  triggerHorizonExport$(dealId: string) {
    return this.http.post<PageModel<DealModel>>(
      `${environment.API}v1/deals/eai/resend/${dealId}`,
      {}
    );
  }
}
