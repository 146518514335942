import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizeDate',
})
export class LocalizeDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: Date | string, format: string = 'dd.MM.yyyy HH:mm'): string {
    return formatDate(value, format, this.locale);
  }
}
