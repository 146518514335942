import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Style } from '../../../gep-controls/models/style';

@Component({
  selector: 'gep-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() headline?: string;
  @Input() description?: string;
  @Input() modalId?: string;
  @Input() closeButtonText?: string;
  @Input() closeButtonRank?: string = 'secondary';
  @Input() positiveButtonText?: string = '';
  @Input() positiveButtonStyle: Style = Style.Standard;
  @Input() visible?: boolean;
  @Input() showClosingX?: boolean = false;

  @Output() positiveButtonClicked = new EventEmitter();
  @Output() closedButtonClicked = new EventEmitter();

  clickPositiveButton() {
    this.positiveButtonClicked.emit();
  }

  clickCloseButton() {
    this.closedButtonClicked.emit();
  }
}
