import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'gep-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements AfterViewInit {
  /**
   * Raised after the RadioButton has been clicked. Returns a boolean whether the RadioButton is now checked or not.
   */
  @Output() valueChanged = new EventEmitter<string>();

  /**
   * The label that appears on the right hand side of the radio-button. The label is a string that gives a description on what the value of the radio-button means.
   * @example <eon-ui-radio-button label="Agree to terms of service"/>
   */
  @Input() label?: string;

  /**
   * The name Attribute is a string that refers to the native name attribute of an HtmlInputElement.
   * @example <eon-ui-radio-button name="group1"/>
   */
  @Input() name?: string;

  /**
   * The tabIndex Attribute is a number that gives the Element its RadioButton its tabindex if it is not disabled in a RadioButtonGroup.
   * The default is 0;
   */
  @Input() index: number = 0;

  /**
   * Wether or not the radio-button is disabled. Disabled radio-buttones behave like readonly native html-radio-buttones.
   * See [MDN Docs - radio-button]{@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input/radio-button} for the readonly attribute.
   * The color of the labels and error is also changed to indicate this state.
   * @example <eon-ui-radio-button label="undo-time" disabled>
   */
  @Input() disabled: boolean = false;

  /**
   * Wether or not the radio-button is checked. Changes if the radio-button is clicked.
   * @example <eon-ui-radio-button label="I want this to be checked from the beginning" checked />
   */
  @Input() initiallyChecked: boolean = false;

  /**
   * Native html value attribute. Changes the key the radio-button value is stored in form submissions.
   * See [MDN Docs - radio-button]{@link  https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input/radio-button}
   * @example <eon-ui-radio-button value="use-experimental-api" label="Do you want to use the experimental api?">
   */
  @Input() value?: string;

  /**
   * Whether this button can be focused by keyboard. Defaults to true.
   */
  @Input() navigable: boolean = true;

  constructor(private renderer: Renderer2, private elRef: ElementRef) {}

  ngAfterViewInit(): void {
    // radio-button-group needs direct <eon-ui-radio-button>,
    // so remove host element
    const el = this.elRef.nativeElement;
    const parent = this.renderer.parentNode(this.elRef.nativeElement);

    while (el.firstChild) {
      this.renderer.appendChild(parent, el.firstChild);
    }

    this.renderer.removeChild(parent, el, true);
  }
}
