import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gep-small-number-input',
  templateUrl: './small-number-input.component.html',
})
export class SmallNumberInputComponent {
  @Input() value?: number;
  @Input() inputWidth?: string;
  @Input() disabled?: boolean;
  @Output() valueChanged = new EventEmitter<number>();
}
