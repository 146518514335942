import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Role } from '../models/user.model';

export const AdminGuard: CanActivateChildFn = (childRoute, _) => {
  const router = inject(Router);
  const authService = inject(MsalService);

  const activeAccount = authService.instance.getActiveAccount();
  const roles = (activeAccount?.idTokenClaims as { roles: string[] })?.roles;

  if (
    roles?.some(
      x => x === Role.ADMIN_SYS || x === Role.GREENDESK || Role.ADMIN_GEP
    )
  ) {
    return true;
  }
  router.navigate(['/dashboard']);
  return false;
};
