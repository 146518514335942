import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ButtonSize } from '../../../gep-controls/models/button-size';

@Component({
  selector: 'gep-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input()
  size: ButtonSize = ButtonSize.Normal;

  @Input()
  label: string = '';

  @Input()
  labelOutside: boolean = false;

  @Input()
  placeholder: string | null = null;

  @Input()
  min?: number;

  @Input()
  max?: number;

  @Input()
  trailingIconName?: string;

  internalName: string | null = null;

  readonly value$ = new BehaviorSubject<number | null>(null);

  readonly disabled$ = new BehaviorSubject<boolean>(false);

  changeFn?: (change: number) => void;

  touchFn?: () => void;

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
      this.internalName = String(ngControl.name);
    } else {
      this.internalName = String(Math.random() * 10000);
    }
  }

  writeValue(obj: number): void {
    this.value$.next(obj);
  }
  registerOnChange(fn: (change: number) => void): void {
    this.changeFn = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.touchFn = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled$.next(isDisabled);
  }

  onValueChange(changeEvent: Event) {
    const changedValue = (changeEvent as CustomEvent).detail;
    this.value$.next(changedValue);
    if (this.changeFn) {
      this.changeFn(changedValue);
    }

    if (this.touchFn) {
      this.touchFn();
    }
  }

  onInputBlur() {
    if (this.touchFn) {
      this.touchFn();
    }
  }
}
