import { Component, Input, Optional, Self } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import { ProductSelectionFormModel } from '../../models/product-selection-form-model';
import {
  distinctUntilChanged,
  map,
  mergeWith,
  ReplaySubject,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { ProductConfigModel } from '../../../../models/product-config.model';
import {
  productConfigChangeSideEffect,
  productSelectionFormModelChangeSideEffect,
} from '../../utils/product-select.functions';
import { ProductService } from '../../../../services/product.service';
import { DealModel } from '../../../../models/deal.model';

@Component({
  selector: 'gep-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
})
export class ProductSelectionComponent implements ControlValueAccessor {
  private readonly injectedProductConfigs$ = new ReplaySubject<
    ProductConfigModel[]
  >(1);

  @Input()
  set productConfigurations(value: ProductConfigModel[]) {
    this.injectedProductConfigs$.next(value);
  }

  @Input()
  deal?: DealModel | null;

  readonly productConfigurations$ = this.injectedProductConfigs$.pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly regions$ = this.productService.getRegions$().pipe(startWith([]));

  readonly systemAges$ = this.productService
    .getSystemAges$()
    .pipe(startWith([]));
  readonly energySources$ = this.productService
    .getEnergySources$()
    .pipe(startWith([]));
  private readonly writtenValue$ =
    new ReplaySubject<ProductSelectionFormModel | null>();

  readonly innerForm$ = this.writtenValue$.pipe(
    map(formModel => this.createForm(formModel)),
    switchMap(form => {
      const productSelectionFormModelSideEffect$ = form.valueChanges.pipe(
        startWith(form.value),
        productSelectionFormModelChangeSideEffect(this.changeFn, this.touchFn)
      );

      const productConfigChangeSideEffect$ = form
        .get('productConfig')!
        .valueChanges.pipe(
          startWith(form.get('productConfig')!.value),
          productConfigChangeSideEffect(form, this.deal)
        );

      return productSelectionFormModelSideEffect$.pipe(
        mergeWith(productConfigChangeSideEffect$),
        map(() => form),
        startWith(form)
      );
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private changeFn?: (change: ProductSelectionFormModel) => void;

  private touchFn?: () => void;

  constructor(
    @Optional() @Self() private ngControl: NgControl,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly productService: ProductService
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  writeValue(obj: ProductSelectionFormModel | null): void {
    this.writtenValue$.next(obj);
  }

  productConfigTransformer(productConfig: ProductConfigModel): string {
    return productConfig.key;
  }

  identityTransformer(option: string): string {
    return option;
  }

  private createForm(
    formModel: ProductSelectionFormModel | null
  ): UntypedFormGroup {
    const { system_age, region, productConfig, energy_source } =
      { ...formModel } ?? this.createDefaultFormModel();

    return this.formBuilder.group({
      system_age: [system_age, Validators.required],
      region: [region, Validators.required],
      productConfig: [{ ...productConfig }],
      energy_source: [energy_source],
    });
  }

  private createDefaultFormModel(): ProductSelectionFormModel {
    return {
      system_age: '',
      region: '',
      productConfig: null,
      energy_source: '',
    };
  }
}
