import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YesNoRadioComponent } from './components/yes-no-radio/yes-no-radio.component';
import { GepFormsModule } from '../gep-forms/gep-forms.module';
import { RangeWithInputComponent } from './components/range-with-input/range-with-input.component';
import { CheckboxListFoldableComponent } from './components/checkbox-list-foldable/checkbox-list-foldable.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxInColumnsComponent } from './components/checkbox-in-columns/checkbox-in-columns.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { EonUiComponentsAngularModule } from '@eon-ui/eon-ui-components-angular';

@NgModule({
  declarations: [
    YesNoRadioComponent,
    RangeWithInputComponent,
    CheckboxListFoldableComponent,
    CheckboxInColumnsComponent,
    DateRangeComponent,
    ToggleComponent,
  ],
  exports: [
    YesNoRadioComponent,
    RangeWithInputComponent,
    DateRangeComponent,
    CheckboxListFoldableComponent,
    CheckboxInColumnsComponent,
    ToggleComponent,
  ],
  imports: [
    CommonModule,
    GepFormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    EonUiComponentsAngularModule,
  ],
})
export class DealsModule {}
