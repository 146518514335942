<gep-deals-tab-header></gep-deals-tab-header>

<main class="d-flex flex-row">
  <gep-generic-filter
    [filterProperties]="dealFilterService.getFilterConfig()"
    [filterService]="dealFilterService"
    (filterChanged)="setDataToTableAndReload()"
    (searchChanged)="setDataToTableAndReload()"
    (searchModeChanged)="setDataToTableAndReload()"
    [showSearchBar]="true"
  ></gep-generic-filter>

  <gep-pagination-table-wrapper
    #paginator
    [dataRetriever]="dataRetriever"
    [selectedColumns]="selectedColumns"
    [availableColumns]="availableColumns"
    [activatedFilters]="dealFilterService.getActivatedFilters()"
    [defaultOrderBy]="defaultOrderBy"
  >
  </gep-pagination-table-wrapper>
</main>

<ng-template #quantityTemplate let-column="column" let-row="row">
  {{ row[column.name] ? (row[column.name] | quantity: QuantityUnit.MWh) : '' }}
</ng-template>

<ng-template #quantityInTonnsTemplate let-column="column" let-row="row">
  {{ row[column.name] ? (row[column.name]  | quantity: QuantityUnit.t) : '' }}
</ng-template>

<ng-template #productTemplate let-column="column" let-row="row">
  {{ row[column.name] | translate }}
</ng-template>

<ng-template #priceDetailsTooltip let-row="row">
  <div class="price-details-tooltip">
    <span>{{
        ('TRANSACTION_COSTS' | translate) +
        ': ' +
        (row['price_transaction'] | number : '0.2-3')
      }}</span>
    <span *ngIf="row['price_margin']">{{
        ('MARGIN_COSTS' | translate) +
        ': ' +
        (row['price_margin'] | number : '0.2-3')
      }}</span>
  </div>
</ng-template>

<ng-template #wholesalePriceTemplate let-column="column" let-row="row">
  <span
    [ngbTooltip]="priceDetailsTooltip"
    #tooltip="ngbTooltip"
    (mouseover)="tooltip.open({ row: row })"
  >{{
      row[column.name] + row['price_transaction'] + row['price_margin']
        | number : '0.2-3'
    }}</span
  >
  <span *ngIf="row[column.name]">{{ ' ' + row['price_unit'] }}</span>
</ng-template>

<ng-template #boundOfferTemplate let-column="column" let-row="row">
  <span *ngIf="row.bound_offer"
  >{{ row.bound_offer?.price }} € ({{
      row.bound_offer?.counterpart_name
    }})</span
  >
</ng-template>

<ng-template #horizonTemplate let-column="column" let-row="row">
  <gep-button (clicked)="openHorizonConfirmationModal(row)" [label]="'dealsPage.export' | translate" [size]="ButtonSize.Small"></gep-button>
  {{row.horizonStatus}}
</ng-template>

<ng-template #cancelDealTemplate let-column="column" let-row="row">
  <eon-ui-button
    icon="{{ !row.statement_cancellation ? 'checkbox' : 'checkbox_checked' }}"
    size="small"
    scheme="discreetDark"
    disabled="{{ !row.deal_type.includes('SELL') }}"
    (click)="updateCancellation(row)"
  >
  </eon-ui-button>
</ng-template>


<ng-template #actionsTemplate let-column="column" let-row="row">
  <div class="d-flex flex-row">
    <eon-ui-button
      *ngIf="userService.hasRole(Role.GREENDESK) || userService.hasRole(Role.ADMIN_GEP)"
      icon="edit"
      size="small"
      scheme="neutral100"
      use-as-link="true"
      href="/admin/edit-deal/{{row.id}}"
    ></eon-ui-button>
  </div>
</ng-template>

<ng-template #bindingPeriodStartDateTemplate let-column="column" let-row="row">
  <div class="bindingPeriodStartDate">
    {{ row.binding_period_start | date : 'dd.MM.yyyy HH:mm' }}
  </div>
</ng-template>

<ng-template #bindingPeriodEndDateTemplate let-column="column" let-row="row">
  <div class="bindingPeriodEndDate">
    {{ row.binding_period_end | date : 'dd.MM.yyyy HH:mm' }}
  </div>
</ng-template>

<ng-template #closedDateTemplate let-column="column" let-row="row">
  <div class="bindingPeriodEndDate">
    {{ row.closed_date | date : 'short' }}
  </div>
</ng-template>


<gep-modal
  [visible]="triggerHorizonExportModal.visible"
  [headline]="'dealsPage.horizonExportModal.headline' | translate"
  [description]="'dealsPage.horizonExportModal.description' | translate"
  [positiveButtonStyle]="Style.Highlight"
  [closeButtonText]="'no' | translate"
  [positiveButtonText]="'yes' | translate"
  (positiveButtonClicked)="triggerHorizonExport(triggerHorizonExportModal.deal?.id!)"
  (closedButtonClicked)="triggerHorizonExportModal.reset()"
  [showClosingX]="true"
  modalId="show-trigger-horizon-confirmation"
></gep-modal>
