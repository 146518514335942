<footer>
  <eon-ui-footer-bar [copyright]="'© E.ON SE ' + yearDate">
    <eon-ui-link
      slot="links"
      target="_self"
      size="small"
      text="Imprint"
      href="https://www.eon.com/en/imprint.html"
    ></eon-ui-link>
    <eon-ui-link
      slot="links"
      target="_self"
      size="small"
      text="Privacy Policy"
      href="https://www.eon.com/en/privacy.html"
    ></eon-ui-link>
    <eon-ui-image
      slot="logo"
      src="assets/img/eon_logo.svg"
      alt="E.ON Logo"
    ></eon-ui-image>
  </eon-ui-footer-bar>
</footer>
