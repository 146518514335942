import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LabelValueModel } from '../../../models/label-value.model';

@Component({
  selector: 'gep-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
})
export class SelectListComponent {
  @Input() options: LabelValueModel[] = [];

  @Output() itemSelected = new EventEmitter<string>();

  selectedItem: string = '';

  selectItem(value: string): void {
    this.selectedItem = value;
    this.itemSelected.emit(this.selectedItem);
  }
}
