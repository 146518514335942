import { Injectable, OnDestroy } from '@angular/core';
import { DistinctPropertiesService } from '../distinct-properties.service';
import { FilterCreationService } from './filter-creation.service';
import { Subscription } from 'rxjs';
import { GenericFilterService } from './generic-filter.service';
import { IFilterService } from './filter-service.interface';
import { DealSearchFilter } from '../../modules/deal-search/model/deal-search-filter';

@Injectable({
  providedIn: 'root',
})
export class ConsignmentsFilterService
  extends GenericFilterService
  implements IFilterService, OnDestroy
{
  subscription?: Subscription;

  preselectedFilters: DealSearchFilter[] = [
    {
      field: 'year',
      value: new Date().getFullYear().toString(),
      operator: 'eq',
    },
  ];

  constructor(
    protected filterCreationService: FilterCreationService,
    distinctPropertiesService: DistinctPropertiesService
  ) {
    super(filterCreationService);
    this.createFilterForPreselection();

    this.subscription = distinctPropertiesService
      .getDistinctPropertiesForConsignments()
      .subscribe(distinctProperties => {
        this.filterConfig = [
          {
            type: 'select',
            fieldName: 'year',
            fieldNameAsLabel: true,
            possibleValues: distinctProperties.years,
            value: new Date().getFullYear().toString(),
            resetForbidden: true,
          },
          {
            type: 'headline',
            fieldName: '',
            label: 'Optionale Filter',
            resetForbidden: true,
          },
          {
            type: 'date-range',
            fieldName: 'closed_date',
            fieldNameAsLabel: true,
          },
          {
            type: 'select',
            fieldName: 'product_key',
            fieldNameAsLabel: true,
            possibleValues: distinctProperties.products,
          },
          {
            type: 'range',
            fieldName: 'quantity',
            min: Math.floor(distinctProperties.quantity?.min!),
            max: Math.ceil(distinctProperties.quantity?.max!),
            firstValue: Math.floor(distinctProperties.quantity?.min!),
            secondValue: Math.ceil(distinctProperties.quantity?.max!),
            unit: 'MWh',
            fieldNameAsLabel: true,
          },
          {
            type: 'range',
            fieldName: 'price',
            min: Math.floor(distinctProperties.price?.min!),
            max: Math.ceil(distinctProperties.price?.max!),
            firstValue: Math.floor(distinctProperties.price?.min!),
            secondValue: Math.ceil(distinctProperties.price?.max!),
            unit: '€',
            fieldNameAsLabel: true,
          },
          {
            type: 'checkbox-list-foldable',
            fieldName: 'counterpart_company',
            possibleValues: distinctProperties.counterpart_companies,
            fieldNameAsLabel: true,
            numberOfDefaultDisplayed: 3,
          },
          { type: 'yes-no', fieldName: 'comment', fieldNameAsLabel: true },
        ];
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
