import { AbstractControl, NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable, OperatorFunction } from 'rxjs';

export function controlHasRequiredValidator(ngControl: NgControl): boolean {
  if (ngControl.control) {
    const validator = !!ngControl.control.validator
      ? ngControl.control.validator({} as AbstractControl)
      : null;
    return !!validator && validator.required;
  }
  return false;
}

type InternalOption = {
  originalOption: any;
  value: string;
  label: string;
};
export function mapValueChangeToOption(): OperatorFunction<
  [string, InternalOption[]],
  InternalOption
> {
  return (
    source$: Observable<[string, InternalOption[]]>
  ): Observable<InternalOption> =>
    source$.pipe(
      map(([valueChange, optionsWithLabels]): InternalOption => {
        const option = optionsWithLabels.find(
          optionWithLabel => optionWithLabel.value === valueChange
        );

        if (!!option) {
          return option;
        }

        throw new Error('could not find option');
      })
    );
}

export function mapOptionsToOptionsWithLabels<TOption>() {
  return map(
    ([options, valueTransformer, labelTransformer]: [
      TOption[],
      (option: TOption) => string,
      (option: TOption) => string
    ]) => {
      return options.map(option => ({
        originalOption: option,
        value: valueTransformer(option),
        label: labelTransformer(option),
      }));
    }
  );
}
