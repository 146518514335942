<main>
  <section *ngIf="deal$ | async as deal">
    <h1>{{ 'editEnquiry.title' | translate }}</h1>
    <ng-container
      *ngIf="productConfigurations$ | async as productConfigurations"
    >
      <gep-enquiry-form
        [productConfigurations]="productConfigurations"
        [deal]="deal"
        (save)="onSave($event)"
        (cancel)="onEditCancel()"
      ></gep-enquiry-form>
    </ng-container>
  </section>
</main>
