import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SelectedColumnsService {
  public selectedColumns() {
    return [
      'closed_date',
      'year',
      'quantityInMWh',
      'quantityInTonns',
      'price',
      'counterpart_company',
      'product_key',
      'cancel_deal',
      'deal_type',
      'quantity_delivery_confirmed',
      'quantity_cancelled',
      'deal_id',
      'customer',
      'statement_cancellation',
    ];
  }
}
