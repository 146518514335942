import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormErrorService {
  public getErrorMessage(config: {
    errors: ValidationErrors | null;
    customErrorMessage?: string;
    label?: string;
  }): string {
    if (config.customErrorMessage) {
      return config.customErrorMessage;
    } else if (config.errors?.required) {
      return `${this.getFieldName(config.label)} is required.`;
    } else if (config.errors?.minlength) {
      return `${this.getFieldName(config.label)} is to short.`;
    }
    return `${this.getFieldName(config.label)} is invalid.`;
  }

  public getFieldName(label?: string): string {
    return label || 'Field';
  }
}
