import { Component, Input } from '@angular/core';
import { ButtonSize } from '../../modules/gep-controls/models/button-size';

@Component({
  selector: 'gep-loading-spinner',
  templateUrl: './loading-spinner.component.html',
})
export class LoadingSpinnerComponent {
  @Input() visible = false;
  @Input() fullscreen = true;
  @Input() size: ButtonSize = ButtonSize.Small;

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }
}
