export class DayTime {
  readonly hour: number;
  readonly minute: number;

  constructor(hour: number, minute: number) {
    if (hour < 0 || hour > 23) {
      throw new Error('Hour value is invalid');
    }

    if (minute < 0 || minute > 59) {
      throw new Error('Minute is invalid');
    }

    this.hour = hour;
    this.minute = minute;
  }

  toMinutes(): number {
    return this.hour * 60 + this.minute;
  }

  toString(): string {
    return `${this.hour.toString().padStart(2, '0')}:${this.minute
      .toString()
      .padStart(2, '0')}`;
  }

  equals(dayTime: DayTime) {
    return this.hour === dayTime.hour && this.minute === dayTime.minute;
  }

  static fromMinutes(minutes: number): DayTime {
    const maximumMinutesPerDay = 24 * 60;
    if (minutes > maximumMinutesPerDay) {
      throw new Error('A day must not be created from more than 24 hours');
    }

    if (minutes < 0) {
      throw new Error('Minutes must be positive or 0');
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return new DayTime(hours, remainingMinutes);
  }
}
