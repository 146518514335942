import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Column } from '../column';

@Component({
  selector: 'gep-pagination-column-selection',
  templateUrl: './pagination-column-selection.component.html',
  styleUrls: ['./pagination-column-selection.component.scss'],
})
export class PaginationColumnSelectionComponent implements AfterViewInit {
  private _selectedColumns: string[] = [];

  @Input() set selectedColumns(value: string[]) {
    this._selectedColumns = value;
    // availableColumns might have already been set
    if (this.columns.length > 0) {
      this.columns.forEach(column => {
        column.selected =
          this._selectedColumns.indexOf(column.column.name) !== -1;
      });
    }
  }

  public columns: { column: Column; selected: boolean }[] = [];

  @Input() set availableColumns(value: Column[]) {
    this.columns = value.map(v => {
      return {
        column: v,
        selected: this._selectedColumns.indexOf(v.name) !== -1,
      }; // this.selectedColumns might be empty at this point
    });
  }

  @Output() columnSelectionChanged = new EventEmitter<Column[]>();

  ngAfterViewInit(): void {
    this.emitEvent();
  }

  public emitEvent() {
    this.columnSelectionChanged.emit(
      this.columns.filter(c => c.selected).map(c => c.column)
    );
  }
}
