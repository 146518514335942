import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnquiryDefaultModel } from '../../../models/enquirydefault.model';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EnquiryFormModel } from '../models/enquiry-form-model';
import { map } from 'rxjs/operators';
import { ProductConfigModel } from '../../../models/product-config.model';
import { ProductSelectionFormModel } from '../models/product-selection-form-model';

@Injectable()
export class EnquiryPrefillService {
  constructor(private readonly http: HttpClient) {}

  getPrefillOptions$(
    productConfigs: ProductConfigModel[]
  ): Observable<Partial<EnquiryFormModel>[]> {
    return this.getLegacyEnquiryDefaults$().pipe(
      map(
        (
          enquiryDefaultModels: EnquiryDefaultModel[]
        ): Partial<EnquiryFormModel>[] => {
          return enquiryDefaultModels.map(legacyDefault => {
            const productConfig = productConfigs.find(
              config => config.key === legacyDefault?.product_key
            );
            return this.mapLegacyDefaultToPartialFormModel(
              legacyDefault,
              productConfig
            );
          });
        }
      )
    );
  }

  private mapLegacyDefaultToPartialFormModel(
    legacyDefault: EnquiryDefaultModel,
    product?: ProductConfigModel
  ): Partial<EnquiryFormModel> {
    const {
      address,
      customer,
      segment,
      vg,
      redistributor,
      contact_person,
      deal_type,
      binding_period_start,
      binding_period_end,
    } = legacyDefault;

    return {
      productSelection: this.productConfigToProductSelectionFormModel(product),
      address,
      customer,
      segment,
      vg,
      contact_person,
      deal_type,
      binding_period_start: binding_period_start
        ? new Date(binding_period_start)
        : undefined,
      binding_period_end: binding_period_end
        ? new Date(binding_period_end)
        : undefined,
    };
  }

  private productConfigToProductSelectionFormModel(
    productConfig?: ProductConfigModel
  ): ProductSelectionFormModel | undefined {
    let formModel: ProductSelectionFormModel | undefined;

    if (!!productConfig) {
      formModel = {
        productConfig: productConfig!,
        energy_source: productConfig!.energy_source ?? '',
        system_age: productConfig!.system_age ?? '',
        region: productConfig!.region_id ?? '',
      };
    }
    return formModel;
  }

  private getLegacyEnquiryDefaults$(): Observable<EnquiryDefaultModel[]> {
    return this.http.get<EnquiryDefaultModel[]>(
      `${environment.API}v1/config/enquiry_defaults`
    );
  }
}
