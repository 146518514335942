import { DestroyRef, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FeatureFlagsDataService } from './feature-flag-data.service';
import { tap } from 'rxjs/operators';
import { FeatureFlag, FeatureFlags } from './feature-flag-config';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsFacadeService {
  constructor(
    private featureFlagsDataService: FeatureFlagsDataService,
    private destroyRef: DestroyRef
  ) {}

  featureFlags?: FeatureFlags;
  async loadFeatureFlags() {
    return await lastValueFrom(
      this.featureFlagsDataService.getFeatureFlags().pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(featureFlags => (this.featureFlags = featureFlags))
      )
    );
  }

  featureFlagEnabled(featureFlag: FeatureFlag) {
    if (!environment.featureFlagsEnabled) {
      return true; // enable all feature flags for local development
    }

    return this.featureFlags?.[featureFlag] ?? false;
  }
}
