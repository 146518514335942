import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  BehaviorSubject,
  mergeWith,
  ReplaySubject,
  Subject,
  withLatestFrom,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  controlHasRequiredValidator,
  mapOptionsToOptionsWithLabels,
  mapValueChangeToOption,
} from '../../utils/dropdown.functions';
import { ButtonSize } from '../../../gep-controls/models/button-size';

@Component({
  selector: 'gep-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements ControlValueAccessor {
  private readonly options$ = new ReplaySubject<any[]>(1);

  /***
   * The options that should be displayed
   */
  @Input()
  set options(value: any[]) {
    this.options$.next(value);
  }

  /***
   * The label of the dropdown
   */
  @Input()
  label: string = '';

  @Input()
  requiredStar: boolean = false;

  get formattedLabel(): string {
    return this.label + (this.requiredStar ? '*' : '');
  }

  @Input()
  labelOutside: boolean = false;

  /***
   * Transforms every entry to a string that will be used as a label
   * @param option The option that will be transformed
   */
  private readonly optionLabelTransformer$ = new ReplaySubject<
    (option: any) => string
  >(1);
  @Input()
  set optionLabelTransformer(value: (option: any) => string) {
    this.optionLabelTransformer$.next(value);
  }

  /***
   * Transforms every option to a string that will be used as the option value.
   * Default is a function that returns JSON.stringify() on the option
   * @param option The option that will be transformed
   */
  private readonly optionValueTransformer$ = new BehaviorSubject<
    (option: any) => string
  >((option: any) => JSON.stringify(option));
  @Input()
  set optionValueTransformer(value: (option: any) => string) {
    this.optionValueTransformer$.next(value);
  }

  /***
   * Sets the size of the dropdown, default: normal
   */
  @Input()
  size: ButtonSize = ButtonSize.Normal;

  @Input()
  height: string = '10em';

  @Input()
  set value(value: string) {
    this.writeValue$.next(value);
  }

  @Input()
  set disabled(value: boolean | undefined) {
    if (value !== undefined) {
      this.disabled$.next(value);
    }
  }

  @Output()
  valueSelected: EventEmitter<string> = new EventEmitter<string>();

  readonly required$ = new BehaviorSubject<boolean>(false);

  readonly disabled$ = new BehaviorSubject<boolean>(false);

  private readonly writeValue$ = new ReplaySubject<any>(1);
  private readonly valueChange$ = new Subject<any>();

  readonly optionsWithLabels$ = this.options$.pipe(
    withLatestFrom(this.optionValueTransformer$),
    withLatestFrom(this.optionLabelTransformer$),
    map(
      ([[option, valueTransformer], labelTransformer]: [
        [any, (option: any) => string],
        (option: any) => string
      ]): [any, (option: any) => string, (option: any) => string] => [
        option,
        valueTransformer,
        labelTransformer,
      ]
    ),
    mapOptionsToOptionsWithLabels()
  );

  private readonly valueChangeEffect$ = this.valueChange$.pipe(
    withLatestFrom(this.optionsWithLabels$),
    mapValueChangeToOption(),
    tap(optionWithLabel => {
      if (this.changeFn) {
        this.changeFn(optionWithLabel!.originalOption);
      }
    }),
    map(optionWithLabel => optionWithLabel!.value)
  );

  readonly value$ = this.writeValue$.pipe(
    withLatestFrom(this.optionValueTransformer$),
    map(([writtenValue, valueTransformer]) => {
      return valueTransformer(writtenValue);
    }),
    mergeWith(this.valueChangeEffect$)
  );

  private changeFn?: (change: any) => void;
  private touchFn?: () => void;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
      this.required$.next(controlHasRequiredValidator(ngControl));
    }
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }

  writeValue(obj: any): void {
    this.writeValue$.next(obj);
  }

  onValueChanged(changedValue: any): void {
    // workaround: for some reason the `eon-ui-dropdown`
    // component emits 'null' (null as a string) on init
    if (
      changedValue.detail &&
      changedValue.detail !== 'null' &&
      changedValue.detail != 'undefined'
    ) {
      this.valueChange$.next(changedValue.detail);
      this.valueSelected.emit(changedValue.detail);
    }
  }

  onBlur(_: Event) {
    if (this.touchFn) {
      this.touchFn();
    }
  }
}
