import { Pipe, PipeTransform } from '@angular/core';
import { QuantityUnit } from '../models/quantity-unit.model';

@Pipe({
  name: 'quantity',
})
export class QuantityPipe implements PipeTransform {
  transform(
    value: number | null,
    unit: QuantityUnit,
    ...args: unknown[]
  ): string {
    if (value === null) return '';

    const formattedValue = value.toLocaleString('de-DE');
    return unit === QuantityUnit.MWh ? formattedValue + ' MWh' : value + ' t';
  }
}
