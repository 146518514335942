import { Component, OnInit } from '@angular/core';
import { Observable, startWith, Subject } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonSize } from '../../../gep-controls/models/button-size';
import { Icon } from '../../../gep-controls/models/icon';
import { ProductService } from '../../../../services/product.service';
import { SettingsService } from '../../../../services/settings.service';
import { Style } from '../../../gep-controls/models/style';
import { OptionTransformers } from '../../../../transformers/option-transformers';
import { LabelValueModel } from '../../../../models/label-value.model';
import { ToastService } from '../../../../services/toast.service';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'gep-product-configuration',
  templateUrl: './product-configuration.component.html',
  styleUrls: ['./product-configuration.component.scss'],
})
export class ProductConfigurationComponent implements OnInit {
  products$!: Observable<LabelValueModel[]>;

  private productListChangedSubject = new Subject<void>();
  productListChanged$ = this.productListChangedSubject.asObservable();

  form: FormGroup = new FormGroup<any>({
    id: new FormControl(),
    key: new FormControl(undefined, [Validators.required]),
    price_gdm_namefield: new FormControl(),
    region_id: new FormControl(),
    region_updatable: new FormControl(undefined, [Validators.required]),
    system_age: new FormControl(),
    system_age_updatable: new FormControl(undefined, [Validators.required]),
    energy_source: new FormControl(),
    energy_source_updatable: new FormControl(undefined, [Validators.required]),
    price_fixed: new FormControl(),
    displayed_quantity: new FormGroup({
      unit: new FormControl(),
      factor: new FormControl(),
    }),
    labels: new FormArray([]),
    position: new FormControl(),
    price_addition: new FormControl(),
    price_multiplier: new FormControl(),
  });
  protected readonly ButtonSize = ButtonSize;
  protected readonly Icon = Icon;
  protected readonly Style = Style;
  protected readonly OptionTransformers = OptionTransformers;

  showForm: boolean = false;
  isNewProduct: boolean = false;
  deleteModalVisible: boolean = false;

  displayedQuantityUnits = ['MWh', 't'];

  constructor(
    protected productService: ProductService,
    private settingsService: SettingsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.products$ = this.productListChanged$.pipe(
      startWith(null),
      switchMap(() => this.settingsService.getAllProducts$())
    );
  }

  createNewProduct() {
    this.showForm = true;
    this.isNewProduct = true;
    this.form.reset();
  }

  showDeleteModal() {
    this.deleteModalVisible = true;
  }

  deleteProduct() {
    this.settingsService
      .deleteProduct$(<string>this.form.get('id')?.value)
      .subscribe(_ => {
        this.deleteModalVisible = false;
        this.resetView();
        this.productListChangedSubject.next();
      });
  }

  saveProduct() {
    if (this.form.get('price_fixed')?.value === '') {
      this.form.get('price_fixed')?.setValue(null);
    }

    this.settingsService.saveProduct$(this.form.value).subscribe(_ => {
      this.toastService.showSavedToast();
      if (this.isNewProduct) {
        this.resetView();
      }
      this.productListChangedSubject.next();
    });
  }

  selectProduct(product: string) {
    this.showForm = true;
    this.isNewProduct = false;
    this.form.reset();
    this.settingsService.getProductById$(product).subscribe(data => {
      if (data) {
        const labelsFormArray = this.form.get('labels') as FormArray;
        labelsFormArray.clear();

        if (data.labels) {
          data.labels!.map((label, index) => {
            labelsFormArray.push(new FormControl(label));
          });
        }

        this.form.patchValue(data);
      }
    });
  }
  get labels() {
    return this.form.get('labels') as FormArray;
  }

  addLabel() {
    this.labels.push(new FormControl());
  }

  removeLabel(index: number) {
    this.labels.removeAt(index);
  }

  resetView() {
    this.form.reset();
    this.showForm = false;
  }

  readonly conversionFactorEnabled$: Observable<boolean> | undefined = this.form
    .get('displayed_quantity')!
    .get('unit')
    ?.valueChanges.pipe(
      tap(x => {
        if (x === 'MWh') {
          this.form.get('displayed_quantity')!.get('factor')?.reset();
        }
      }),
      startWith(this.form.get('displayed_quantity')!.get('unit')?.value),
      map(x => x === 't')
    );
}
