<div class="columns">
  <div class="column" *ngFor="let column of columns">
    <input
      type="checkbox"
      [(ngModel)]="column.selected"
      (ngModelChange)="emitEvent()"
      id="c-{{ column.column.name }}"
    />
    <label for="c-{{ column.column.name }}">
      {{
        column.column.translateLabel
          ? (column.column.label | translate)
          : column.column.label
      }}
    </label>
  </div>
</div>
