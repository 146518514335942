import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ButtonSize } from '../../../gep-controls/models/button-size';

@Component({
  selector: 'gep-yes-no-radio',
  templateUrl: './yes-no-radio.component.html',
})
export class YesNoRadioComponent implements ControlValueAccessor {
  @Input() size: ButtonSize = ButtonSize.Normal;
  @Input() value?: boolean;
  @Input() disabled?: boolean;
  @Input() label?: string;

  @Output() valueSelected = new EventEmitter<string>();

  changeFn?: (change: string[]) => void;
  touchFn?: () => void;

  readonly hasError$ = new ReplaySubject<boolean>();
  readonly disabled$ = new BehaviorSubject<boolean>(false);
  readonly value$ = new ReplaySubject<string[]>(1);

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: (change: string[]) => void): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchFn = fn;
  }

  writeValue(obj: string[]): void {
    if (!!this.ngControl.control) {
      this.disabled$.next(this.ngControl.control.disabled);
    }
    this.value$.next(obj);

    this.hasError$.next(!!this.ngControl.control?.errors);
  }

  identityStringTransformer(option: string): string {
    // todo: translateService
    if (option === 'true') {
      return 'Ja';
    } else if (option === 'false') {
      return 'Nein';
    }
    return '';
  }

  onValueChange(event: Event) {
    const next = (<CustomEvent>event).detail;

    this.value$.next(next);
    if (this.touchFn) {
      this.touchFn();
    }

    if (this.changeFn) {
      this.changeFn(next);
    }

    this.valueSelected.emit(next);
  }
}
