import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  BehaviorSubject,
  distinctUntilChanged,
  ReplaySubject,
  startWith,
  withLatestFrom,
} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ButtonSize } from '../../../gep-controls/models/button-size';

interface RadioGroupOption {
  label: string;
  value: any;

  tooltip?: string;
}

@Component({
  selector: 'gep-radio-select-group',
  templateUrl: './radio-select-group.component.html',
  styleUrls: ['./radio-select-group.component.scss'],
})
export class RadioSelectGroupComponent implements ControlValueAccessor {
  private readonly options$ = new ReplaySubject<any[]>();

  @Input()
  set options(value: any[] | { value: any; tooltip: string }[]) {
    this.options$.next(value);
  }

  @Input()
  optionLabelTransformer: (option: any) => string = (option: any) => option;

  @Input()
  horizontal: boolean = true;

  @Input()
  size: ButtonSize = ButtonSize.Normal;

  @Input()
  set value(value: any) {
    if (value !== undefined) {
      this.writeValue$.next(value);
    }
  }

  @Input()
  set disabled(value: boolean | undefined) {
    if (value !== undefined) {
      this.disabled$.next(value);
    }
  }

  @Output()
  valueSelected = new EventEmitter<string>();

  changeFn?: (change: any) => void;
  touchFn?: () => void;

  private readonly writeValue$ = new ReplaySubject<any>(1);

  readonly value$ = this.writeValue$.pipe(
    distinctUntilChanged(),
    startWith(null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly optionsWithLabels$ = this.options$.pipe(
    map((options: any[]): RadioGroupOption[] => {
      return options.map(option => {
        if (!!option.tooltip) {
          return {
            value: option.value,
            label: this.optionLabelTransformer(option.value),
            tooltip: option.tooltip,
          };
        }

        return {
          value: option,
          label: this.optionLabelTransformer(option),
        };
      });
    }),
    withLatestFrom(this.value$),
    map(([options, currentValue]) => {
      return options.map(option => {
        return {
          ...option,
          isInitiallyChecked: currentValue === option.value,
        };
      });
    })
  );

  readonly disabled$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Optional() @Self() private ngControl: NgControl,
    private element: ElementRef
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: (change: any) => void): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchFn = fn;
  }

  writeValue(obj: any): void {
    this.writeValue$.next(obj);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled$.next(isDisabled);
  }

  onValueChange(change: any) {
    this.writeValue$.next(change);
    if (this.changeFn) {
      this.changeFn(change);
    }

    if (this.touchFn) {
      this.touchFn();
    }

    this.valueSelected.emit(change);
  }
}
