import { Component, Input, Optional, Self } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import { BindingPeriodFormModel } from '../../models/binding-period-form-model';
import { distinctUntilChanged, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  switchToDateTimeSideEffect,
  switchToValueChangeSideEffects,
} from '../../utils/binding-period-datetime-picker.functions';

@Component({
  selector: 'gep-binding-period-datetime-picker',
  templateUrl: './binding-period-datetime-picker.component.html',
  styleUrls: ['./binding-period-datetime-picker.component.scss'],
})
export class BindingPeriodDatetimePickerComponent
  implements ControlValueAccessor
{
  @Input()
  datePickerLabel: string = '';

  @Input()
  timeDropdownLabel: string = '';

  readonly today = new Date();

  private readonly innerFormModel$ = new ReplaySubject<BindingPeriodFormModel>(
    1
  );
  readonly form$ = this.innerFormModel$.pipe(
    map(formModel => {
      return new UntypedFormGroup({
        time: new UntypedFormControl(formModel.time, Validators.required),
        date: new UntypedFormControl(formModel.date, Validators.required),
      });
    }),
    switchToValueChangeSideEffects(
      this.getDateFromInnerFormModel.bind(this),
      () => this.changeFn,
      () => this.touchFn
    ),
    switchToDateTimeSideEffect(),
    distinctUntilChanged()
  );

  private changeFn?: (value: Date | null) => void;

  private touchFn?: () => void;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: (change: Date | null) => void): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchFn = fn;
  }

  writeValue(obj: Date): void {
    const innerFormModel = this.createInnerFormModel(obj);
    this.innerFormModel$.next(innerFormModel);
  }

  private createInnerFormModel(
    value: Date | undefined
  ): BindingPeriodFormModel {
    return {
      time: !!value ? new Date(value.toISOString()) : null,
      date: !!value ? new Date(value.toISOString()) : null,
    };
  }

  private getDateFromInnerFormModel({
    date,
    time,
  }: BindingPeriodFormModel): Date | null {
    if (!date || !time) {
      return null;
    }

    const datePart = `${date!.getFullYear()}-${this.padLeftWithZero(
      (date.getMonth() + 1).toString()
    )}-${this.padLeftWithZero(date.getDate().toString()).toString()}`;
    const timePart = time?.toISOString().split('T')[1];

    return new Date(`${datePart}T${timePart}`);
  }

  private padLeftWithZero(rawString: string): string {
    return rawString.padStart(2, '0');
  }
}
