import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ButtonSize } from '../../../modules/gep-controls/models/button-size';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'gep-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements ControlValueAccessor {
  @Input() interval: number = 1;
  @Input() disabled: boolean = false;
  @Output() valueSelected = new EventEmitter<{
    hour: string;
    minute: string;
  }>();

  selectedHour?: string = '--';
  selectedMinute?: string = '--';
  isPickerVisible: boolean = false;

  hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  minutes = Array.from({ length: 60 / this.interval }, (_, i) =>
    (i * this.interval).toString().padStart(2, '0')
  );

  readonly value$ = new ReplaySubject<{ hour: string; minute: string }>(1);

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  changeFn?: (change: { hour?: string; minute?: string }) => void;
  private touchFn?: () => void;

  showPicker() {
    this.isPickerVisible = true;
  }

  hidePicker() {
    this.onValueChange();
    this.isPickerVisible = false;
  }

  selectHour(hour: string) {
    this.selectedHour = hour;
  }

  selectMinute(minute: string) {
    this.selectedMinute = minute;
  }

  protected readonly ButtonSize = ButtonSize;

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  writeValue(obj: { hour: string; minute: string }): void {
    this.value$.next(obj);

    if (obj) {
      this.selectedHour = obj.hour.toString().padStart(2, '0');
      this.selectedMinute = obj.minute.toString().padStart(2, '0');
    }
  }

  onValueChange() {
    const value = { hour: this.selectedHour!, minute: this.selectedMinute! };
    this.value$.next(value);

    if (!!this.changeFn) {
      this.changeFn(value);
    }

    if (!!this.touchFn) {
      this.touchFn();
    }
    this.valueSelected.emit(value);
  }
}
