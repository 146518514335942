import { Injectable } from '@angular/core';
import { withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrService
  ) {}

  showSavedToast() {
    this.translateService
      .get('globalSave.message')
      .pipe(
        withLatestFrom(this.translateService.get('globalSave.title')),
        map(([message, title]) =>
          this.toastrService.success(message, title, {
            progressAnimation: 'decreasing',
            tapToDismiss: false,
            progressBar: true,
            closeButton: true,
            timeOut: 7_000,
          })
        )
      )
      .subscribe();
  }
}
