import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { DealModel } from '../../../../models/deal.model';
import { CreateEnquiryFacadeService } from '../../services/create-enquiry-facade.service';
import { takeUntil } from 'rxjs/operators';
import { Icon } from '../../../gep-controls/models/icon';
import { Router } from '@angular/router';
import { ToastService } from '../../../../services/toast.service';
import { DealService } from '../../../../services/deal.service';
import { OptionTransformers } from '../../../../transformers/option-transformers';

@Component({
  selector: 'gep-create-enquiry',
  templateUrl: './create-enquiry.component.html',
  styleUrls: ['./create-enquiry.component.scss'],
})
export class CreateEnquiryComponent implements OnDestroy {
  readonly prefillDropdownControl = new UntypedFormControl(null);

  readonly prefill$ = this.prefillDropdownControl.valueChanges;
  readonly prefillOptions$ = this.facade.enquiryDefaults$;

  readonly productConfigurations$ = this.facade.productConfigurations$;

  readonly _destroy$ = new Subject<void>();

  protected readonly Icon = Icon;
  protected readonly OptionTransformers = OptionTransformers;

  constructor(
    private readonly facade: CreateEnquiryFacadeService,
    private readonly dealService: DealService,
    private router: Router,
    private toastService: ToastService
  ) {}

  onCreateCancel() {
    this.router.navigate(['/dashboard']);
  }

  onSave(deals: DealModel[]) {
    this.dealService
      .createDeals(deals)
      .pipe(takeUntil(this._destroy$))
      .subscribe(_ => {
        this.toastService.showSavedToast();
        this.router.navigateByUrl('/offers');
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
