export class DistinctPropertiesModel {
  counterpart_companies?: string[];
  customers?: string[];
  energy_sources?: string[];
  products?: string[];
  regions?: string[];
  segments?: string[];
  vgs?: string[];
  years?: string[];
  system_ages?: string[];

  price?: { min: number; max: number };
  quantity?: { min: number; max: number };
}

export enum FilterFor {
  CLOSED_DEALS,
  INVALIDATIONS,
  CONSIGNMENTS,
}
