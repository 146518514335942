import {
  Directive,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FeatureFlagsFacadeService } from './feature-flag-facade.service';
import { FeatureFlag } from './feature-flag-config';

@Directive({
  selector: '[gepFeatureFlag]',
  standalone: true,
})
export class FeatureFlagDirective implements OnChanges {
  @Input({ required: true, alias: 'gepFeatureFlag' })
  featureFlag!: FeatureFlag | null;

  @Input() featureFlagElseRef: TemplateRef<unknown> | null = null;

  constructor(
    private featureFlagsFacadeService: FeatureFlagsFacadeService,
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnChanges() {
    this.onChanges();
  }

  onChanges() {
    this.viewContainerRef.clear();
    if (this.featureFlagEnabled()) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.featureFlagElseRef) {
      this.viewContainerRef.createEmbeddedView(this.featureFlagElseRef);
    }
  }

  featureFlagEnabled() {
    if (!this.featureFlag) {
      return true;
    }

    return this.featureFlagsFacadeService.featureFlagEnabled(this.featureFlag);
  }
}
