import { Pipe, PipeTransform } from '@angular/core';
import { DealModel } from '../models/deal.model';

@Pipe({
  name: 'bestPrice',
})
export class BestPricePipe implements PipeTransform {
  transform(deal: DealModel, ...args: any[]): string {
    if (
      !deal.quantityInMWh ||
      !deal.offers ||
      Object.keys(deal.offers).length == 0
    ) {
      return '-';
    }
    if (deal.quantityInMWh >= 0) {
      return (
        deal.offers
          .map(offer => offer.price)
          .reduce((a, b) => (a < b ? a : b))
          .toString() + ' €'
      );
    }
    return (
      deal.offers
        .map(offer => offer.price)
        .reduce((a, b) => (a < b ? b : a))
        .toString() + ' €'
    );
  }
}
