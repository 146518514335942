<div class="inventory-kpis">
  <div class="sales-box kpi-box">
    <div class="caption">TOTAL SOLD</div>
    <div class="value">{{ sales | number: '1.0-2':locale }}</div>
    <div class="unit">MWh</div>
  </div>
  <div class="buys-box kpi-box">
    <div class="caption">PORTFOLIO</div>
    <div class="value">{{ buys | number: '1.0-2':locale }}</div>
    <div class="unit">MWh</div>
  </div>
  <div class="delta-box kpi-box">
    <div class="caption">DELTA</div>
    <div class="value">{{ delta | number: '':locale }}</div>
    <div class="unit">MWh</div>
  </div>
  <div class="delta-box kpi-box">
    <div class="caption">PnL</div>
    <div class="value">{{ pnl | number: '':locale }}</div>
    <div class="unit">€</div>
  </div>
</div>
