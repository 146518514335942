import { Injectable } from '@angular/core';
import { DealSearchFilter } from '../../modules/deal-search/model/deal-search-filter';

@Injectable({
  providedIn: 'root',
})
export class FilterCreationService {
  constructor() {}

  createFilter(field: string, type: string, value: any): DealSearchFilter[] {
    let tempFilter: DealSearchFilter[] = [];
    if (value !== undefined && value !== 'undefined') {
      switch (type) {
        case 'select':
          tempFilter = this.createFilterForSelect(field, value);
          break;
        case 'multi-select':
          tempFilter = this.createFilterForMultipleSelect(field, value);
          break;
        case 'string':
          tempFilter = this.createFilterForString(field, value);
          break;
        case 'date':
          tempFilter = this.createFilterForDate(field, value);
          break;
        case 'yes-no':
          tempFilter = this.createFilterForTrueFalse(field, value);
          break;
        case 'checkbox-list-foldable':
        case 'checkbox-list-columns':
          tempFilter = this.createFilterForMultipleCheckboxes(field, value);
          break;
        case 'range':
          tempFilter = this.createFilterForRange(field, value);
          break;
        case 'date-range':
          tempFilter = this.createFilterForDateRange(field, value);
          break;
      }

      if (field === 'deal_type') {
        tempFilter = this.createFilterForDealType(field, value);
      } else if (field === 'comment') {
        tempFilter = this.createFilterForHasOrHasNot(field, value);
      }
    }

    return tempFilter;
  }

  private createFilterForSelect(
    field: string,
    value: string
  ): DealSearchFilter[] {
    return [
      {
        field: field,
        value: value,
        operator: 'eq',
      },
    ];
  }

  private createFilterForDealType(
    field: string,
    values: string[]
  ): DealSearchFilter[] {
    let dealTypes: string[] = [];

    if (!values) {
      return [];
    }

    if (values.length === 0) {
      return [];
    }

    values.forEach(value => {
      switch (value.toUpperCase()) {
        case 'BUY':
          dealTypes = dealTypes.concat([
            'ENQUIRY_BUY',
            'LEGACY_BUY',
            'MEMPHIS_BUY',
          ]);
          break;
        case 'SELL':
          dealTypes = dealTypes.concat([
            'PSI_SELL',
            'ENQUIRY_SELL',
            'LEGACY_SELL',
          ]);
          break;
        case 'BUY/SELL':
          dealTypes = dealTypes.concat(['ENQUIRY_BUY_SELL', 'LEGACY_BUY_SELL']);
          break;
      }
    });

    return [
      {
        values: dealTypes,
        field: field,
      },
    ];
  }

  private createFilterForString(
    field: string,
    value: string
  ): DealSearchFilter[] {
    return [
      {
        field: field,
        value: value,
        operator: 'eq',
      },
    ];
  }

  private createFilterForRange(
    field: string,
    value: { min?: number; max?: number }
  ): DealSearchFilter[] {
    if (
      typeof value.min === 'undefined' ||
      typeof value.max === 'undefined' ||
      value.min === null ||
      value.max === null
    ) {
      return [];
    }

    return [
      {
        field: field,
        value: value.min?.toString(),
        operator: 'ge',
      },
      {
        field: field,
        value: value.max?.toString(),
        operator: 'le',
      },
    ];
  }

  private createFilterForDateRange(
    field: string,
    value: { startDate?: Date; endDate?: Date }
  ): DealSearchFilter[] {
    const result: DealSearchFilter[] = [];

    if (value.startDate) {
      result.push({
        field: field,
        value: value.startDate.toISOString(),
        operator: 'ge',
      });
    }

    if (value.endDate) {
      result.push({
        field: field,
        value: value.endDate.toISOString(),
        operator: 'le',
      });
    }

    return result;
  }

  private createFilterForMultipleCheckboxes(
    field: string,
    value: string[]
  ): DealSearchFilter[] {
    if (value.length === 0) {
      return [];
    }

    return [
      {
        values: value,
        field: field,
      },
    ];
  }

  private createFilterForMultipleSelect(
    field: string,
    value: string
  ): DealSearchFilter[] {
    const valueList = value.split(',').map(x => x.trim());
    const flattedValueList = valueList
      .map(x => x.split(';').map(x => x.trim()))
      .flat();

    return [
      {
        values: flattedValueList,
        field: field,
      },
    ];
  }

  private createFilterForTrueFalse(
    field: string,
    value: string
  ): DealSearchFilter[] {
    let filter: DealSearchFilter[] = [];

    if (value === 'true') {
      filter = [
        {
          field: field,
          operator: 'is',
        },
      ];
    } else if (value === 'false') {
      filter = [
        {
          field: field,
          operator: 'not',
        },
      ];
    }

    return filter;
  }

  private createFilterForHasOrHasNot(
    field: string,
    value: string
  ): DealSearchFilter[] {
    let filter: DealSearchFilter[] = [];
    if (value === 'true') {
      filter = [
        {
          field: field,
          operator: 'ne',
          value: '',
        },
        {
          field: field,
          operator: 'ne',
          value: null,
        },
      ];
    } else if (value === 'false') {
      filter = [
        {
          field: field,
          operator: 'eq',
          value: '',
        },
      ];
    }

    return filter;
  }

  private createFilterForDate(field: string, value: Date): DealSearchFilter[] {
    return [
      {
        field: field,
        value: value.toISOString(),
        operator: 'eq',
      },
    ];
  }
}
