import { Component, Input } from '@angular/core';
import { DealModel } from 'src/app/models/deal.model';

@Component({
  selector: 'gep-offer-quickview',
  templateUrl: './offer-quickview.component.html',
  styleUrls: ['./offer-quickview.component.scss'],
})
export class OfferQuickviewComponent {
  constructor() {}

  @Input() deal?: DealModel;
}
