<eon-ui-radio-button-group
  scheme="turquoise"
  [horizontalAlignment]="true"
  [disabled]="disabled"
  [value]="value"
  [size]="size"
  [label]="label"
  (valueChanged)="onValueChange($event)" >
  <eon-ui-radio-button value="true" [label]="'yes' | translate"></eon-ui-radio-button>
  <eon-ui-radio-button value="false" [label]="'no' | translate"></eon-ui-radio-button>
</eon-ui-radio-button-group>
