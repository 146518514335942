<gep-admin-tab-header></gep-admin-tab-header>

<main>
  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <fieldset>
        <gep-input
          [label]="'editDeal.dealId' | translate"
          formControlName="dealId"
          class="form-item"
        ></gep-input>

        <eon-ui-dropdown
          *ngIf="adminUpdateProperties$ | async as adminUpdateProperties"
          [label]="'editDeal.attribute' | translate"
          class="form-item"
          formControlName="dealAttribute"
          height="10em"
        >
          <eon-ui-dropdown-option
            *ngFor="let dealAttribute of adminUpdateProperties.dealAttributes"
            [value]="dealAttribute.fieldName"
            [label]="dealAttribute.fieldName"
          ></eon-ui-dropdown-option>
        </eon-ui-dropdown>
      </fieldset>

      <span style="display: none">{{ oldValue$ | async | json }} {{ oldValueOffer$ | async | json }}</span>

      <ng-container *ngIf="selectedDeal$ | async as selectedDeal">
        <div *ngIf="selectedDealAttribute$ | async as selectedDealAttribute">
          <fieldset>
            <eon-ui-dropdown
              *ngIf="selectedDealAttribute.fieldName === 'offers'"
              [label]="'editDeal.offerId' | translate"
              formControlName="offerId"
              class="form-item">
              <eon-ui-dropdown-option
                *ngFor="let option of selectedDeal.offers"
                [value]="option.counterpart_oid"
                [label]="option.counterpart_name"
              ></eon-ui-dropdown-option>
            </eon-ui-dropdown>

            <ng-container
              *ngIf="selectedDealAttribute.fieldName === 'offers' || selectedDealAttribute.fieldName === 'bound_offer'"
                 >
              <eon-ui-dropdown
                *ngIf="adminUpdateProperties$ | async as adminUpdateProperties"
                [label]="'editDeal.offerAttribute' | translate"
                class="form-item"
                height="10em"
                formControlName="offerAttribute"
              >
                <eon-ui-dropdown-option
                  *ngFor="let offerAttribute of adminUpdateProperties.offerAttributes"
                  [value]="offerAttribute.fieldName"
                  [label]="offerAttribute.fieldName"
                ></eon-ui-dropdown-option>
              </eon-ui-dropdown>
            </ng-container>
          </fieldset>
        </div>

        <div>
          <ng-container
            *ngIf="selectedAttribute$ | async as selectedAttribute"
            [ngSwitch]="selectedAttribute?.type"
          >
            <ng-container *ngSwitchCase="AttributeDataType.STRING">
              <fieldset>
                <gep-input
                  [label]="'editDeal.oldValue' | translate"
                  formControlName="oldValue"
                  class="form-item"
                ></gep-input>

                <div class="form-item">
                  <gep-input
                    [label]="'editDeal.newValue' | translate"
                    formControlName="newValue"
                  ></gep-input>
                </div>
              </fieldset>
            </ng-container>

            <ng-container *ngSwitchCase="AttributeDataType.DATETIME">
              <fieldset>
                <div class="form-item">
                  <label>{{'editDeal.oldValue' | translate}}:</label><br>
                  {{form.get('oldValue')?.value | date: 'dd.MM.YYYY HH:mm'}}
                </div>

                <div class="form-item">
                  <gep-date-time-picker
                    [label]="'editDeal.newValue' | translate"
                   formControlName="newValue"
                  ></gep-date-time-picker>
                </div>
              </fieldset>
            </ng-container>

            <ng-container *ngSwitchCase="AttributeDataType.ENUM">
              <fieldset>
                <gep-input
                  [label]="'editDeal.oldValue' | translate"
                  formControlName="oldValue"
                  class="form-item"
                ></gep-input>
                <div class="form-item">
                  <gep-dropdown
                    [label]="'editDeal.newValue' | translate"
                    formControlName="newValue"
                    [optionValueTransformer]="identityStringTransformer"
                    [optionLabelTransformer]="identityStringTransformer"
                    [options]="selectedAttribute.values!"
                  ></gep-dropdown>
                </div>
              </fieldset>
            </ng-container>

            <ng-container *ngSwitchCase="AttributeDataType.NUMBER">
              <fieldset>
                <gep-number-input
                  [label]="'editDeal.oldValue' | translate"
                  formControlName="oldValue"
                  class="form-item"
                ></gep-number-input>

                <div class="form-item">
                  <gep-number-input
                    [label]="'editDeal.newValue' | translate"
                    formControlName="newValue"
                  ></gep-number-input>
                </div>
              </fieldset>
            </ng-container>

            <ng-container *ngSwitchCase="AttributeDataType.BOOLEAN">
              <fieldset>
                <div class="form-item">
                  <label>{{'editDeal.oldValue' | translate}}:</label><br>
                  {{form.get('oldValue')?.value}}
                </div>

                <div class="form-item">
                  <gep-radio-button-group
                    [label]="'editDeal.newValue' | translate"
                    formControlName="newValue"
                  >
                    <gep-radio-button label="True" value="true"></gep-radio-button>
                    <gep-radio-button label="False" value="false"></gep-radio-button>
                  </gep-radio-button-group>
                </div>
              </fieldset>
            </ng-container>

            <ng-container *ngSwitchCase="AttributeDataType.MULTISELECT">
              <fieldset>
                <gep-input
                  [label]="'editDeal.oldValue' | translate"
                  formControlName="oldValue"
                  class="form-item"
                ></gep-input>

                <div class="form-item">
                  <gep-multi-select-dropdown
                    [label]="'editDeal.newValue' | translate"
                    formControlName="newValue"
                    [options]="selectedAttribute.values!"
                    [optionValueTransformer]="identityStringTransformer"
                    [optionLabelTransformer]="identityStringTransformer"
                  ></gep-multi-select-dropdown>
                </div>
              </fieldset>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <fieldset>
        <gep-radio-button-group
          [label]="'editDeal.reasonOfModification' | translate"
          formControlName="reasonOfModification"
        >
          <gep-radio-button
            [label]="'editDeal.userError' | translate"
            value="User Error"
          ></gep-radio-button>
          <gep-radio-button
            [label]="'editDeal.counterpartError' | translate"
            value="Counterpart Error"
          ></gep-radio-button>
          <gep-radio-button
            [label]="'editDeal.mismatch' | translate"
            value="Missmatch"
          ></gep-radio-button>
          <gep-radio-button
            [label]="'editDeal.other' | translate"
            value="Other"
          ></gep-radio-button>
        </gep-radio-button-group>
      </fieldset>

      <div class="form-buttons">
        <gep-button
          [label]="'editDeal.cancel' | translate"
          type="button"
          [style]="Style.Gray"
          (click)="onCancel()"
        >
        </gep-button>
        <gep-button
          [label]="'editDeal.save' | translate"
          type="button"
          (clicked)="showConfirmationModal()"
          [disabled]="(formValidity$ | async) === false"
        >
        </gep-button>
      </div>
    </form>
  </ng-container>
</main>

<gep-modal
  [visible]="areYouSureModal.visible"
  [headline]="'editDeal.areYouSureModal.headline' | translate"
  [description]="'editDeal.areYouSureModal.question' | translate"
  [positiveButtonStyle]="Style.Highlight"
  [closeButtonText]="'no' | translate"
  [positiveButtonText]="'yes' | translate"
  (positiveButtonClicked)="saveChanges()"
  (closedButtonClicked)="closeConfirmationModal()"
  modalId="show-confirmation"
>
  <div>
    <strong>{{ 'editDeal.dealId' | translate }}: </strong>
    {{ form.controls.dealId.value }} <br />
    <strong>{{ 'editDeal.attribute' | translate }}: </strong
    >{{ form.controls.dealAttribute.value }} <br />
    <div *ngIf="form.controls.offerId.value">
      <strong>{{ 'editDeal.offerId' | translate }}: </strong>
      {{ form.controls.offerId.value }} <br />
      <strong>{{ 'editDeal.offerAttribute' | translate }}: </strong>
      {{ form.controls.offerAttribute.value }} <br />
    </div>
    <strong>{{ 'editDeal.oldValue' | translate }}: </strong
    >{{ areYouSureModal.oldValue }} <br />
    <strong>{{ 'editDeal.newValue' | translate }}: </strong
    >{{ areYouSureModal.newValue }} <br />
    <strong>{{ 'editDeal.reasonOfModification' | translate }}: </strong>
    {{ form.controls.reasonOfModification.value }}
  </div>
</gep-modal>
<gep-modal
  [visible]="successModal.visible"
  [headline]="'editDeal.successModal.headline' | translate"
  [description]="'editDeal.successModal.question' | translate"
  [positiveButtonStyle]="Style.Highlight"
  [closeButtonText]="'editDeal.successModal.understood' | translate"
  (closedButtonClicked)="closeSuccessModal()"
  modalId="show-success"
>
</gep-modal>
