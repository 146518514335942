<main>
  <eon-ui-tabs inner-background="eon-lightgrey" scheme="lightgrey">
    <eon-ui-tab header="Upload">
      <gep-memphis-producers-upload></gep-memphis-producers-upload>
    </eon-ui-tab>
    <eon-ui-tab header="View">
      <gep-memphis-producers-view></gep-memphis-producers-view>
    </eon-ui-tab>
  </eon-ui-tabs>
</main>
