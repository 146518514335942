export enum Icon {
  Chevron = 'chevron',
  Filter = 'filter',
  ChevronBoldRight = 'chevron_bold_right',
  ChevronBoldLeft = 'chevron_bold_left',
  Chat = 'chat',
  Add = 'add',
  Copy = 'copy',
  Quote = 'quote',
  Edit = 'edit',
  RadioCheckboxTick = 'radio_checkbox_tick',
  Checkbox = 'checkbox',
  CheckboxChecked = 'checkbox_checked',
  ChevronBoldDown = 'chevron_bold_down',
  Close = 'close',
  Info = 'info_secondary',
  ValueProposition = 'value_proposition',
  Rewind = 'rewind',
  Trash = 'trash_bin',
}
