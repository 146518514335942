<div *ngIf="userIsAllowedToManuallyAddOfferOnBehalf">
  <form [formGroup]="form">
    <div class="manual-offer-form-wrapper" *ngIf="!showManualOffer">
      <button class="manual-offer-form-button" (click)="openManualOffer()">
        <eon-ui-icon name="add" size="small" scheme="turquoise"></eon-ui-icon>
        <span class="manual-offer-form-button-content">{{
          'manual_deal_offer' | translate
        }}</span>
      </button>
      <div class="gep-ttip-container-margined">
        <eon-ui-icon name="info_secondary" scheme="middlegrey"></eon-ui-icon>
        <div class="gep-ttip">
          {{ 'manual_deal_offer_tooltip' | translate }}
        </div>
      </div>
    </div>
    <div class="show-manual-offer-wrapper" *ngIf="showManualOffer">
      <div class="show-manual-offer">
        <div class="show-manual-offer-elements">
          <span class="show-manual-offer-element-center">{{
            'manual_deal_offer_detail' | translate
          }}</span>
          <eon-ui-icon
            name="info_secondary"
            scheme="middlegrey"
            class="show-manual-offer-element-center"
          ></eon-ui-icon>
        </div>
        <div class="gep-ttip-container">
          <div class="gep-ttip">
            {{ 'manual_deal_offer_tooltip' | translate }}
          </div>
        </div>
      </div>
      <div class="manual-offer">
        <gep-input-deprecated
          class="gep-input"
          formControlName="val"
          type="text"
          label="{{ 'manual_deal_offer_price' | translate }}"
          [trailingIcon]="true"
          trailingIconName="currency_euro"
        ></gep-input-deprecated>
        <gep-dropdown-deprecated
          class="gep-dropdown"
          formControlName="counterPart"
          label="{{ 'manual_deal_offer_counterpart' | translate }}"
          infoMessage="test"
        >
          <div class="counterPartDropDown">
            <gep-dropdown-option
              *ngFor="let counterpart of counterparts"
              value="{{ counterpart.oid }}"
              label="{{ counterpart.company_name }} - {{ counterpart.name }}"
            >
            </gep-dropdown-option>
          </div>
        </gep-dropdown-deprecated>
      </div>
      <div class="eon-ui-button-wrapper">
        <eon-ui-button
          (click)="recordOffer()"
          [disabled]="!form.valid"
          text="{{ 'manual_deal_offer_save' | translate }}"
          size="small"
        ></eon-ui-button>
        <eon-ui-button
          (click)="closeManualOffer()"
          size="small"
          icon="close"
          scheme="redInverted"
          text="{{ 'manual_deal_offer_close' | translate }}"
        ></eon-ui-button>
      </div>
    </div>
  </form>
</div>
