import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AdminUpdateProperties } from '../models/admin-update-properties.model';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  // GET
  getAdminAttributes$(): Observable<AdminUpdateProperties> {
    return this.http.get<AdminUpdateProperties>(
      `${environment.API}v1/admin/deal/fields`
    );
  }

  // PATCH

  public updateDealAttribute(
    id: string,
    dealAttribute: string,
    reason: string,
    value: any,
    offerOid?: string,
    offerAttribute?: string
  ): Observable<void> {
    let update = {
      [dealAttribute]: value,
    };

    if (offerAttribute) {
      update = {
        offers: {
          filter: {
            counterpart_oid: offerOid,
          },
          update: {
            [offerAttribute]: value,
          },
        },
      };
    }

    return this.http.patch<void>(`${environment.API}v1/admin/deal/update`, {
      filter: {
        id: id,
      },
      update: update,
      reason_of_modification: reason,
    });
  }
}
