<div class="d-flex flex-wrap">
  <eon-ui-checkbox
    ngDefaultControl
    *ngFor="let option of options"
    [ngClass]="{
      'columns-1': columns === 1,
      'columns-2': columns === 2,
      'columns-3': columns === 3,
      'columns-4': columns === 4
    }"
    [attr.label]="option"
    [attr.size]="size"
    [attr.disabled]="disabled"
    (valueChanged)="onValueChange($event, option)"
  ></eon-ui-checkbox>
</div>
