import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'gep-uba-tab-header',
  templateUrl: './uba-tab-header.component.html',
  styleUrls: ['./uba-tab-header.component.scss'],
})
export class UbaTabHeaderComponent {
  readonly isNotProd = environment.environment !== 'production';
  constructor(protected router: Router) {}
}
