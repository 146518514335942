import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Column } from '../../components/pagination/column';
import { PageRetrieverInterface } from '../../components/pagination/page-retriever.interface';
import { map, tap, takeUntil } from 'rxjs/operators';
import { DealService } from '../../services/deal.service';
import { DealModel } from '../../models/deal.model';
import { MsalService } from '@azure/msal-angular';
import { DealSearchSortOption } from '../../modules/deal-search/model/deal-search-sort-option';
import { QuantityUnit } from '../../models/quantity-unit.model';
import { Subject } from 'rxjs';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'gep-create-offer-page',
  templateUrl: './create-offer-page.component.html',
  styleUrls: ['./create-offer-page.component.scss'],
})
export class CreateOfferPageComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  constructor(
    private dealService: DealService,
    private msal: MsalService,
    private toastService: ToastService
  ) {}

  public selectedColumns: string[] = [];
  public availableColumns: Column[] = [];

  @ViewChild('productTemplate', { read: TemplateRef })
  public productTemplate!: TemplateRef<any>;

  @ViewChild('productSpecificationTemplate', { read: TemplateRef })
  public productSpecificationTemplate!: TemplateRef<any>;

  @ViewChild('priceTemplate', { read: TemplateRef })
  public priceTemplate!: TemplateRef<any>;

  @ViewChild('quantityInMWhTemplate', { read: TemplateRef })
  public quantityInMWhTemplate!: TemplateRef<any>;

  @ViewChild('quantityInTonnsTemplate', { read: TemplateRef })
  public quantityInTonnsTemplate!: TemplateRef<any>;

  @ViewChild('bindingDeadlineTemplate', { read: TemplateRef })
  public bindingDeadlineTemplate!: TemplateRef<any>;

  @ViewChild('timeOfPricingTemplate', { read: TemplateRef })
  public timeOfPricingTemplate!: TemplateRef<any>;

  @ViewChild('commentTemplate', { read: TemplateRef })
  public commentTemplate!: TemplateRef<any>;

  public commentModal: { visible: boolean; text: string } = {
    visible: false,
    text: '',
  };

  private counterpart_oid: string | undefined;

  private readonly binding_period_end_BUFFER_MINS = 30;

  protected readonly QuantityUnit = QuantityUnit;
  protected defaultOrderBy: DealSearchSortOption[] = [
    { field: '_id', direction: 'asc' },
  ];

  _destroy$ = new Subject<void>();

  dataRetriever: PageRetrieverInterface<DealModel> = {
    load: (
      page: number,
      page_size: number,
      search: { [p: string]: string },
      orderby: DealSearchSortOption[]
    ) =>
      this.dealService.getDealsOffersCp(page, page_size, search, orderby).pipe(
        map(p => {
          p.data = p.data.map(row => {
            if (row.binding_period_end && row.binding_period_start) {
              row.binding_period_end = new Date(row.binding_period_end);
              row.binding_period_end.toLocaleString();

              row.binding_period_start = new Date(row.binding_period_start);
              row.binding_period_start.toLocaleString();
            }

            // TODO add a 'buffer' of 30 minutes for the binding deadline
            // row.binding_period_end?.setMinutes(
            //   row.binding_period_end?.getMinutes() -
            //   this.binding_period_end_BUFFER_MINS
            // );
            return Object.assign(new DealModel(), row);
          });
          return p;
        })
      ),
  };

  private loadAvailableColumns(): Column[] {
    return [
      new Column('year').setWidth(70),
      new Column('quantityInMWh')
        .setWidth(140)
        .setTemplate(this.quantityInMWhTemplate),
      new Column('quantityInTonns')
        .setWidth(140)
        .setTemplate(this.quantityInTonnsTemplate),
      new Column('comment')
        .setTemplate(this.commentTemplate)
        .setSortable(false)
        .setLabel('comment', true),
      new Column('product_specification')
        .setTemplate(this.productSpecificationTemplate)
        .setSortable(false),
      new Column('binding_period_end')
        .setTemplate(this.bindingDeadlineTemplate)
        .setWidth(180),
      new Column('binding_period_start')
        .setTemplate(this.timeOfPricingTemplate)
        .setWidth(180),
      new Column('price')
        .setTemplate(this.priceTemplate)
        .setSortable(false)
        .setWidth(250),
    ];
  }

  ngOnInit(): void {
    this.counterpart_oid =
      this.msal.instance.getActiveAccount()?.localAccountId;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.availableColumns = this.loadAvailableColumns();
      this.selectedColumns = [
        'year',
        'quantityInMWh',
        'quantityInTonns',
        'product_specification',
        'binding_period_end',
        'binding_period_start',
        'comment',
        'price',
      ];
    });
  }

  submitPrice(row: any, price: string) {
    if (this.isSubmitDisabled(row, price)) {
      return;
    }

    row._submitting = true;

    let submittedPrice = price;

    if (submittedPrice?.includes(',')) {
      submittedPrice = submittedPrice.replace(',', '.');
    }

    this.dealService
      .createDealOffer(
        row.id,
        parseFloat(submittedPrice),
        this.counterpart_oid,
        false
      )
      .pipe(
        takeUntil(this._destroy$),
        tap(_ => this.toastService.showSavedToast())
      )
      .subscribe(() => {
        row._submitting = false;
        row._submitted = true;
      });
  }

  isSubmitDisabled(row: any, price: string): boolean {
    if (!price) {
      return true;
    }

    const match = price
      .toString()
      .match(/^(?:(?:\d[,.]|\d*)\d|\d*(?:\.\d|,\d+)|\d+\.\d+|\d)$/)?.[0];

    if (!match) {
      return true;
    }

    return (
      this.status(row).over_due ||
      price === row.offer?.price ||
      price === '' ||
      this.status(row).submitted
    );
  }

  status(row: any): {
    submitting: boolean;
    over_due: boolean;
    submitted: boolean;
  } {
    const overDue =
      Date.now() >
      new Date(row.binding_period_end).getTime() +
        this.binding_period_end_BUFFER_MINS * 60000;
    return {
      submitting: row._submitting,
      over_due: overDue,
      submitted: row._submitted,
    };
  }

  openComment(row: any) {
    this.commentModal.visible = true;
    this.commentModal.text = row.comment;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
