import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './components/input/input.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { RadioSelectGroupComponent } from './components/radio-select-group/radio-select-group.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SliderComponent } from './components/slider/slider.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SmallNumberInputComponent } from './components/small-number-input/small-number-input.component';
import { EonUiComponentsAngularModule } from '@eon-ui/eon-ui-components-angular';
import { CheckboxTreeComponent } from './components/checkbox-tree/checkbox-tree.component';

@NgModule({
  declarations: [
    DropdownComponent,
    DatePickerComponent,
    InputComponent,
    NumberInputComponent,
    RadioSelectGroupComponent,
    MultiSelectDropdownComponent,
    SliderComponent,
    CheckboxComponent,
    SmallNumberInputComponent,
    CheckboxTreeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbTooltipModule,
    EonUiComponentsAngularModule,
  ],
  exports: [
    DropdownComponent,
    DatePickerComponent,
    NumberInputComponent,
    InputComponent,
    RadioSelectGroupComponent,
    MultiSelectDropdownComponent,
    SliderComponent,
    CheckboxComponent,
    SmallNumberInputComponent,
    CheckboxTreeComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class GepFormsModule {}
