import { ProductKpiModel } from '../models/product-kpi.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { InventoryKpiModel } from '../models/inventory-kpi.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KpiService {
  constructor(private http: HttpClient) {}

  public getProductKpis$(productKey: string): Observable<ProductKpiModel[]> {
    let params = new HttpParams();
    params = params.append('product_key', productKey);
    return this.http.get<ProductKpiModel[]>(
      `${environment.API}v1/kpis/product`,
      {
        params: params,
      }
    );
  }

  /*
  Inventory KPIs
  */
  public getInventoryKpis$(year: number): Observable<InventoryKpiModel> {
    let params = new HttpParams();
    params = params.append('year', year);
    return this.http.get<InventoryKpiModel>(
      `${environment.API}v1/kpis/inventory`,
      {
        params: params,
      }
    );
  }
}
