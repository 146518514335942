import { DealModel } from './deal.model';

export class ModalWithData {
  visible: boolean = false;
  deal?: DealModel;
  oldValue?: any;
  newValue?: any;

  show() {
    this.visible = true;
  }

  reset() {
    this.visible = false;
    this.deal = undefined;
    this.oldValue = undefined;
    this.newValue = undefined;
  }

  close() {
    this.visible = false;
  }
}
