import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnquiryDefaultModel } from '../models/enquirydefault.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ProductConfigModel } from '../models/product-config.model';
import { LabelValueModel } from '../models/label-value.model';
import { GeneralSettingsModel } from '../models/general-settings.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private readonly http: HttpClient) {}

  // GET
  getGeneralSettings$(): Observable<any> {
    return this.http.get<GeneralSettingsModel>(
      `${environment.API}v1/config/settings`
    );
  }

  getAllTemplates$(): Observable<LabelValueModel[]> {
    return this.http
      .get<EnquiryDefaultModel[]>(
        `${environment.API}v1/config/enquiry_defaults`
      )
      .pipe(
        map(templates => {
          const result: LabelValueModel[] = [];

          templates.map(template => {
            const singleProduct: LabelValueModel = {
              label: template.contact_person!,
              value: template.id!,
            };

            result.push(singleProduct);
          });
          return result;
        })
      );
  }

  getTemplateById$(id: string): Observable<EnquiryDefaultModel | undefined> {
    return this.http.get<EnquiryDefaultModel>(
      `${environment.API}v1/config/enquiry_default/${id}`
    );
  }

  getAllProducts$(): Observable<LabelValueModel[]> {
    return this.http
      .get<ProductConfigModel[]>(`${environment.API}v1/config/products`)
      .pipe(
        map(products => {
          const result: LabelValueModel[] = [];

          products.map(product => {
            const singleProduct: LabelValueModel = {
              label: product.key,
              value: product.id!,
            };

            result.push(singleProduct);
          });
          return result;
        })
      );
  }

  getProductById$(id: string): Observable<ProductConfigModel | undefined> {
    return this.http.get<ProductConfigModel>(
      `${environment.API}v1/config/product/${id}`
    );
  }

  // POST
  saveGeneralSettings$(generalSettingsModel: GeneralSettingsModel) {
    return this.http.put<GeneralSettingsModel>(
      `${environment.API}v1/config/settings`,
      generalSettingsModel
    );
  }

  saveTemplate$(enquiryDefaultModel: EnquiryDefaultModel): Observable<any> {
    if (enquiryDefaultModel.id) {
      return this.http.put<EnquiryDefaultModel>(
        `${environment.API}v1/config/enquiry_default/${enquiryDefaultModel.id}`,
        enquiryDefaultModel
      );
    } else {
      return this.http.post<EnquiryDefaultModel>(
        `${environment.API}v1/config/enquiry_default`,
        enquiryDefaultModel
      );
    }
  }

  deleteTemplate$(id: string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.API}v1/config/enquiry_default/${id}`
    );
  }

  saveProduct$(productConfiguration: ProductConfigModel) {
    if (productConfiguration.id) {
      return this.http.put<EnquiryDefaultModel>(
        `${environment.API}v1/config/product/${productConfiguration.id}`,
        productConfiguration
      );
    } else {
      return this.http.post<EnquiryDefaultModel>(
        `${environment.API}v1/config/product`,
        productConfiguration
      );
    }
  }

  deleteProduct$(id: string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.API}v1/config/product/${id}`
    );
  }
}
