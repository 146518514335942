import { Injectable } from '@angular/core';
import { FeatureFlags } from './feature-flag-config';
import { AppConfigurationClient } from '@azure/app-configuration';
import { from, Observable } from 'rxjs';
import { AzureFeatureValue } from './azure-feature-flag-value.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsDataService {
  appConfigurationClient?: AppConfigurationClient;

  constructor() {
    if (environment.featureFlagsEnabled) {
      this.appConfigurationClient = new AppConfigurationClient(
        environment.appConfigurationConnectionString
      );
    }
  }

  async getAppConfig() {
    const settings = this.appConfigurationClient?.listConfigurationSettings();

    const featureFlags: FeatureFlags = {};

    if (settings) {
      for await (const setting of settings) {
        if (setting.key.includes('.featureflag')) {
          const featureSettingValue: AzureFeatureValue = JSON.parse(
            setting.value!
          );
          featureFlags[setting.key] = featureSettingValue.enabled;
        }
      }
    }

    return featureFlags;
  }

  getFeatureFlags(): Observable<FeatureFlags> {
    return from(this.getAppConfig());
  }
}
