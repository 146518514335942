<gep-uba-tab-header></gep-uba-tab-header>
<main>
  <div class="d-flex flex-row">
    <gep-generic-filter
      [filterProperties]="invalidationsFilterService.getFilterConfig()"
      [filterService]="invalidationsFilterService"
      (filterChanged)="setDataToTableAndReload()"
      [canBeClosed]="false"
    ></gep-generic-filter>
    <div style="overflow: hidden;">
      <div class="align-right">
        <div class="align-column">
          <div>
            <gep-button
              [label]="'table.selectAllButton' | translate"
              (clicked)="selectAll()"
            ></gep-button>
            <gep-button
              *ngIf="!saveAllDisabled"
              [label]="'create_cancellation_statement' | translate"
              (clicked)="redirectToOverviewPage()"
              [style]="Style.Highlight"
              [disabled]="saveAllDisabled"
            ></gep-button>
          </div>
          <div>
            {{ 'invalidations.selectedSum' | translate }}: {{ selectedSum }} MWh
          </div>
        </div>
      </div>
      <gep-pagination-table-wrapper
        #paginator
        [dataRetriever]="dataRetriever"
        [selectedColumns]="selectedColumns"
        [availableColumns]="availableColumns"
        [activatedFilters]="invalidationsFilterService.getActivatedFilters()"
        [defaultOrderBy]="defaultOrderBy"
      >
      </gep-pagination-table-wrapper>

    </div>
  </div>
</main>

<ng-template #checkTemplate let-column="column" let-row="row">
  <eon-ui-checkbox
    id="check"
    ngDefaultControl
    [disabled]="row._submitting"
    [checked]="row.quantity_cancelled"
    (valueChanged)="checkClicked(row, $any($event).detail, false)"
  ></eon-ui-checkbox>
</ng-template>

<ng-template #redistributorTemplate let-column="column" let-row="row">
  <eon-ui-checkbox
    id="redistributor"
    ngDefaultControl
    [checked]="row.redistributor"
    [disabled]="true"
    (valueChanged)="checkClicked(row, false, $any($event).detail)"
  ></eon-ui-checkbox>
</ng-template>

<ng-template #saveCheckTemplate let-column="column" let-row="row">
  <gep-button
    *ngIf="!row._submitting"
    [label]="'SAVE' | translate"
    [size]="ButtonSize.Small"
    (click)="setSelectedRow(row)"
  ></gep-button>
  <gep-loading-spinner [visible]="row._submitting"  [size]="ButtonSize.Small"></gep-loading-spinner>
</ng-template>

<ng-template #cancellationTemplate let-column="column" let-row="row">
  <div class="align-center">
    <gep-icon
      [iconType]="
        row.statement_cancellation ? Icon.CheckboxChecked : Icon.Checkbox
      "
      [style]="Style.IconOnlyMiddleGrey"
    ></gep-icon>
  </div>
</ng-template>

<ng-template #quantityTemplate let-column="column" let-row="row">
  <div class="quantity">{{ row.quantityInMWh | quantity: QuantityUnit.MWh }}</div>
</ng-template>

<ng-template #quantityInTonnsTemplate let-column="column" let-row="row">
  <div class="quantity">{{ row.quantityInTonns | quantity: QuantityUnit.t }}</div>
</ng-template>

<ng-template #closedDateTemplate let-column="column" let-row="row">
  <div class="closedDate">
    {{ row.closed_date | date: 'dd.MM.yyyy' }}
  </div>
</ng-template>

<gep-modal
  [visible]="dealModal.visible"
  [headline]="'invalidations.acceptCancellation' | translate"
  [description]="'invalidations.questionForInvalidateSingle' | translate"
  [positiveButtonStyle]="Style.Highlight"
  [closeButtonText]="'no' | translate"
  [positiveButtonText]="'yes' | translate"
  (positiveButtonClicked)="saveCheck(selectedRow)"
  (closedButtonClicked)="dealModal.close()"
  [showClosingX]="true"
  modalId="show-invalidation-confirmation"
></gep-modal>
