<div class="table-main-container">
  <table [class.small]="small">
    <thead>
      <tr>
        <th
          *ngFor="let column of columns; let i = index"
          [style.width]="column.width ? column.width + 'px' : 'auto'"
          [style.maxWidth]="column.width ? column.width + 'px' : 'auto'"
          npm
        >
          <gep-pagination-table-header-control
            [column]="column"
            (columnChanged)="columnChanged(i, $event)"
            [small]="small"
          >
          </gep-pagination-table-header-control>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of page.data">
        <td *ngFor="let column of columns" [style.text-align]="column.align">
          <ng-container *ngIf="!column.template">{{
            row[column.name]
          }}</ng-container>
          <ng-container *ngIf="column.template">
            <ng-container
              *ngTemplateOutlet="
                column.template;
                context: { column: column, row: row }
              "
            >
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>
