<eon-ui-dropdown
  #eonUiDropdown
  [disabled]="disabled"
  [height]="height"
  [info]="info"
  [info-message]="infoMessage"
  [label]="label"
  [name]="name"
  [required]="required"
  [scheme]="scheme"
  [selected]="selected"
  [size]="size"
  [value]="value"
  custom-required-message="This field is required."
  (inputBlur)="onInputBlur($event)"
  (inputFocus)="onInputFocus($event)"
  (valueChanged)="onValueChanged($event)"
>
  <ng-content></ng-content>
</eon-ui-dropdown>
