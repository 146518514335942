import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ButtonSize } from '../../modules/gep-controls/models/button-size';
import { Style } from '../../modules/gep-controls/models/style';
import {
  DealSearchFilter,
  DealSearchMode,
} from '../../modules/deal-search/model/deal-search-filter';
import { FilterProperty } from '../../models/filter-property.model';
import { IFilterService } from '../../services/filter/filter-service.interface';
import { FilterCreationService } from '../../services/filter/filter-creation.service';

@Component({
  selector: 'gep-generic-filter',
  templateUrl: './generic-filter.component.html',
  styleUrls: ['./generic-filter.component.scss'],
})
export class GenericFilterComponent implements OnDestroy {
  @Input() filterProperties!: FilterProperty<any>[];
  @Input() filterService!: IFilterService;
  @Input()
  public set canBeClosed(value: boolean) {
    this.filterCanBeClosed = value;
    if (!value) {
      this.isOpened = true;
    }
  }
  @Input() showSearchBar: boolean = false;
  @Input() searchMode: DealSearchMode = 'all';
  @Output() filterChanged = new EventEmitter<DealSearchFilter[]>();
  @Output() searchChanged = new EventEmitter<string>();
  @Output() searchModeChanged = new EventEmitter<DealSearchMode>();

  protected readonly ButtonSize = ButtonSize;
  protected readonly Style = Style;

  filterCanBeClosed: boolean = true;
  isOpened: boolean = false; // should be false by default, only for testing true

  constructor(private filterCreationService: FilterCreationService) {}

  ngOnDestroy(): void {
    this.filterService?.clearFilters();
  }

  identityStringTransformer(option: string): string {
    return String(option);
  }

  removeFilter(filterProperty: FilterProperty<any>) {
    filterProperty.value = undefined;
    filterProperty.rerender = Math.random();
    this.filterService.removeActivatedFilter(filterProperty.fieldName);
    this.filterChanged.emit(this.filterService.getActivatedFilters());
  }

  onChange(property: FilterProperty<any>, value: any) {
    property.value = value;

    // FIX: Workaround because the quantity field is a complex object now
    // and ACS is throwing an error since the actual number value is sitting
    // under quantity.value (ACS uses `/` as field access notation)
    let fieldName = property.fieldName;
    if (fieldName == 'quantity') {
      fieldName = 'quantity/value';
    }

    const filter: DealSearchFilter[] = this.filterCreationService.createFilter(
      fieldName,
      property.type,
      value
    );

    if (
      property.type === 'checkbox-list-foldable' ||
      property.type === 'checkbox-list-columns'
    ) {
      this.filterService.removeActivatedFilter(property.fieldName);

      if ((value as []).length > 0) {
        this.filterService.setActivatedFilters(property.fieldName, filter);
      }
    } else {
      this.filterService.setActivatedFilters(property.fieldName, filter);
    }

    this.filterChanged.emit(this.filterService.getActivatedFilters());
  }

  emitSearch(query: string) {
    this.filterService.setSearchInput(query);
    this.searchChanged.emit(query);
  }
  emitSearchMode(value: boolean) {
    const searchMode: DealSearchMode = value ? 'any' : 'all';
    this.filterService.setSearchMode(searchMode);
    this.searchModeChanged.emit(searchMode);
  }

  toggleDealFilter(): void {
    this.isOpened = !this.isOpened;
  }
}
