import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MemphisProducersService } from '../../services/memphis-producers.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gep-memphis-producers-upload',
  templateUrl: './memphis-producers-upload.component.html',
  styleUrls: ['./memphis-producers-upload.component.scss'],
})
export class MemphisProducersUploadComponent {
  public selectedFile: File | null = null;
  public status: 'ready' | 'uploading' | 'success' | 'failed' = 'ready';
  public error_message: string | null = null;

  readonly _destroy$ = new Subject<void>();

  constructor(private memphisProducersService: MemphisProducersService) {}

  fileChanged($event: any) {
    if ($event.detail.length === 0) {
      this.selectedFile = null;
    } else {
      this.selectedFile = $event.detail[0].file;
    }
  }

  uploadFile() {
    this.status = 'uploading';
    this.memphisProducersService
      .uploadMemphisProducersExcel(this.selectedFile!)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: res => {
          this.status = 'success';
        },
        error: error => {
          this.status = 'failed';
          if (error instanceof HttpErrorResponse) {
            if (error.error) {
              this.error_message = error.error.detail;
            } else {
              this.error_message = error.message;
            }
          } else {
            this.error_message = 'Unknown error!';
          }
        },
      });
  }
}
