import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject, ReplaySubject, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  controlHasRequiredValidator,
  mapOptionsToOptionsWithLabels,
} from '../../utils/dropdown.functions';
import { ButtonSize } from '../../../gep-controls/models/button-size';

@Component({
  selector: 'gep-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements ControlValueAccessor {
  private readonly options$ = new ReplaySubject<any[]>(1);

  /***
   * The options that should be displayed
   */
  @Input()
  set options(value: any[]) {
    this.options$.next(value);
  }

  readonly label$ = new ReplaySubject<string>();

  /***
   * The label of the dropdown
   */
  @Input()
  set label(value: string) {
    this.label$.next(value);
  }

  /***
   * Transforms every entry to a string that will be used as a label
   * @param option The option that will be transformed
   */
  private readonly optionLabelTransformer$ = new ReplaySubject<
    (option: any) => string
  >(1);
  @Input()
  set optionLabelTransformer(value: (option: any) => string) {
    this.optionLabelTransformer$.next(value);
  }

  /***
   * Transforms every option to a string that will be used as the option value.
   * Default is a function that returns JSON.stringify() on the option
   * @param option The option that will be transformed
   */
  private readonly optionValueTransformer$ = new BehaviorSubject<
    (option: any) => string
  >((option: any) => JSON.stringify(option));
  @Input()
  set optionValueTransformer(value: (option: any) => string) {
    this.optionValueTransformer$.next(value);
  }

  /***
   * Sets the size of the dropdown, default: normal
   */
  @Input()
  size: ButtonSize = ButtonSize.Normal;

  @Input()
  fixedHeight: boolean = true;

  @Input()
  set disabled(value: boolean | undefined) {
    if (value !== undefined) {
      this.disabled$.next(value);
    }
  }

  @Output()
  valueSelected = new EventEmitter<string>();

  readonly required$ = new BehaviorSubject<boolean>(false);

  readonly disabled$ = new BehaviorSubject<boolean>(false);

  private readonly writeValue$ = new ReplaySubject<any>(1);

  readonly optionsWithLabels$ = this.options$.pipe(
    withLatestFrom(this.optionValueTransformer$),
    withLatestFrom(this.optionLabelTransformer$),
    map(
      ([[option, valueTransformer], labelTransformer]: [
        [any, (option: any) => string],
        (option: any) => string
      ]): [any, (option: any) => string, (option: any) => string] => [
        option,
        valueTransformer,
        labelTransformer,
      ]
    ),
    mapOptionsToOptionsWithLabels()
  );

  readonly value$ = this.writeValue$.asObservable();

  private onChange?: (value: any) => void;
  private onTouch?: () => void;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
      this.required$.next(controlHasRequiredValidator(ngControl));
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any): void {
    this.onTouch = onTouch;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }

  writeValue(obj: any): void {
    this.writeValue$.next(obj);
  }

  onValueChanged(changedValue: any): void {
    this.writeValue$.next(changedValue.detail);

    if (changedValue.detail) {
      this.label$.next(changedValue.detail);
    }

    if (this.onChange) {
      this.onChange(changedValue.detail);
    }

    this.valueSelected.emit(changedValue.detail);
  }

  onBlur(_: Event) {
    if (this.onTouch) {
      this.onTouch();
    }
  }
}
