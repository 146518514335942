<section>
  <div class="heading">
    <eon-ui-headline
      [text]="'myDeals' | translate"
      size="h4"
      scheme="darkgrey"
    ></eon-ui-headline>
  </div>
</section>

<gep-ag-grid-table [colDefs]="colDefs" [rowData$]="rowData$"></gep-ag-grid-table>
