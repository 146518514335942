import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

export const CounterpartGuard: CanActivateChildFn = (childRoute, state) => {
  const router = inject(Router);
  const authService = inject(MsalService);

  const activeAccount = authService.instance.getActiveAccount();
  const roles = (activeAccount?.idTokenClaims as { roles: string[] })?.roles;

  const isExternalPartyOnly =
    roles?.length == 1 && roles[0] == 'EXTERNAL_PARTY';

  if (
    isExternalPartyOnly &&
    !childRoute.url[0].path.includes('submit-offer') &&
    !childRoute.url[0].path.includes('my-deals')
  ) {
    router.navigate(['/submit-offer']);
    return false;
  }

  return true;
};
