<div class="d-flex flex-row">
  <eon-ui-button
    icon="{{ !row!.notify_by_email ? 'checkbox' : 'checkbox_checked' }}"
    size="small"
    scheme="discreetDark"
    (click)="updateUser(row!)"
  ></eon-ui-button>
</div>


