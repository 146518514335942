import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {
  applyPolyfills,
  defineCustomElements,
} from '@eon-ui/eon-ui-components/loader';
import { browserTracingIntegration, init } from '@sentry/angular-ivy';
import { LicenseManager } from 'ag-grid-enterprise';

init({
  dsn: environment.sentryConfig.dsn,
  environment: environment.sentryConfig.environment,
  release: environment.sentryConfig.release,
  integrations: [browserTracingIntegration()],
  tracePropagationTargets: [
    'localhost',
    'localhost:4200',
    'https://qa.gep.eon.com',
    'https://gep.eon.com',
  ],
  tracesSampleRate: 1.0,
  enabled: environment.sentryConfig.enabled,
});

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey(
  // eslint-disable-next-line max-len
  'Using_this_{AG_Grid}_Enterprise_key_{AG-055423}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{E.ON_Digital_Technology_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GEP}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GEP}_need_to_be_licensed___{GEP}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{22_March_2025}____[v3]_[01]_MTc0MjYwMTYwMDAwMA==c398dd653829c8e6ee85adbcda6d470c'
);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

// If your app doesn't have to support IE/Edge(legacy), you can skip the applyPolyfills call
applyPolyfills().then(() => {
  defineCustomElements(window); // this registers the E.ON UI components in the browser
});
