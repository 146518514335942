import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Column } from '../../column';

@Component({
  selector: 'gep-pagination-table-header-control',
  templateUrl: './pagination-table-header-control.component.html',
  styleUrls: ['./pagination-table-header-control.component.scss'],
})
export class PaginationTableHeaderControlComponent {
  @Input()
  public column!: Column;

  @Input()
  public small = false;

  @Output()
  public columnChanged = new EventEmitter<Column>();

  public updateColumn() {
    this.columnChanged.emit(this.column);
  }

  toggleSort() {
    switch (this.column.sort) {
      case 'ASC':
        this.column.sort = 'DESC';
        break;
      case 'DESC':
        this.column.sort = null;
        break;
      default:
        this.column.sort = 'ASC';
    }
    this.updateColumn();
  }
}
