import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize } from '../../../gep-controls/models/button-size';

@Component({
  selector: 'gep-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
  @Input() checked: boolean = false;
  @Input() label: string = '';
  @Input() size: ButtonSize = ButtonSize.Normal;

  @Output() changeSelected = new EventEmitter<boolean>();

  changeChecked(event: any) {
    this.checked = event.target.checked;
    this.changeSelected.emit(this.checked);
  }

  setChecked(value: boolean) {
    this.checked = value;
  }
}
