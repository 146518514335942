import { Component, Input, Optional, Self } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'gep-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements ControlValueAccessor {
  @Input() timeInterval: number = 1;
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() showWeekends: boolean = true;

  form: FormGroup = new FormGroup<any>({
    selectedDate: new FormControl(undefined, Validators.required),
    selectedTime: new FormControl(undefined, Validators.required),
  });

  readonly value$ = new ReplaySubject<Date | undefined>(1);
  readonly disabled$ = new ReplaySubject<boolean>(1);

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  changeFn?: (change: Date | undefined) => void;
  private touchFn?: () => void;

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  writeValue(obj: Date): void {
    this.value$.next(obj);

    if (obj) {
      this.form.get('selectedDate')?.setValue(obj);
      this.form
        .get('selectedTime')
        ?.setValue({ hour: obj.getHours(), minute: obj.getMinutes() });
    }
  }

  onValueChange() {
    const date: Date = this.form.get('selectedDate')?.value;

    const time: { hour: string; minute: string } =
      this.form.get('selectedTime')?.value;

    if (time) {
      date.setHours(+time.hour);
      date.setMinutes(+time.minute);
    }

    this.value$.next(date);

    if (!!this.changeFn) {
      this.changeFn(date);
    }

    if (!!this.touchFn) {
      this.touchFn();
    }

    if (!!this.ngControl.control) {
      this.disabled$.next(this.ngControl.control.disabled);
    }
  }
}
