import { Component, OnInit } from '@angular/core';
import { DealModel } from '../../models/deal.model';
import { Observable } from 'rxjs';
import { ColDef, IDateFilterParams } from 'ag-grid-community';
import { DealSearchService } from '../../modules/deal-search/services/deal-search.service';
import { CheckboxRendererComponent } from '../../modules/ag-grid/renderer/checkbox-renderer/checkbox-renderer.component';
import { DateRendererComponent } from '../../modules/ag-grid/renderer/date-renderer/date-renderer.component';
import { QuantityRendererComponent } from '../../modules/ag-grid/renderer/quantity-renderer/quantity-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { PriceRendererComponent } from '../../modules/ag-grid/renderer/price-renderer/price-renderer.component';

@Component({
  selector: 'gep-my-deals-page',
  templateUrl: './my-deals-page.component.html',
  styleUrls: ['./my-deals-page.component.scss'],
})
export class MyDealsPageComponent implements OnInit {
  rowData$?: Observable<DealModel[]>;

  constructor(
    private dealSearchService: DealSearchService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.rowData$ = this.dealSearchService.getDealsForCounterpart();
  }

  colDefs: ColDef<DealModel>[] = [
    {
      field: 'id',
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'closed_date',
      headerName: this.translateService.instant('closed_date'),
      filter: 'agDateColumnFilter',
      filterParams: { closeOnApply: true } as IDateFilterParams,
      cellRenderer: DateRendererComponent,
      width: 200,
    },
    {
      field: 'year',
      headerName: this.translateService.instant('year'),
      filter: 'agSetColumnFilter',
      enableRowGroup: true,
      width: 100,
    },
    {
      field: 'quantityInMWh',
      headerName: this.translateService.instant('quantityInMWh'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      enableValue: true,
      cellRenderer: QuantityRendererComponent,
      type: 'rightAligned',
    },
    {
      field: 'quantityInTonns',
      headerName: this.translateService.instant('quantityInTonns'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      enableValue: true,
      cellRenderer: QuantityRendererComponent,
      cellRendererParams: {
        unit: 'T',
      },
      type: 'rightAligned',
    },
    {
      field: 'price',
      headerName: this.translateService.instant('price'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'avg',
      enableValue: true,
      cellRenderer: PriceRendererComponent,
      width: 120,
    },
    {
      field: 'counterpart_company',
      headerName: this.translateService.instant('counterpart_company'),
      hide: true,
    },
    {
      field: 'energy_source',
      headerName: this.translateService.instant('energy_source'),
      filter: 'agSetColumnFilter',
      width: 175,
    },
    {
      field: 'region',
      headerName: this.translateService.instant('region'),
      filter: 'agSetColumnFilter',
      width: 175,
    },
    {
      field: 'system_age',
      headerName: this.translateService.instant('system_age'),
      filter: 'agSetColumnFilter',
      width: 175,
    },
    {
      field: 'quantity_delivery_confirmed',
      headerName: this.translateService.instant('quantity_delivery_confirmed'),
      filter: 'agSetColumnFilter',
      cellRenderer: CheckboxRendererComponent,
    },
    {
      field: 'quantity_delivery_date',
      headerName: this.translateService.instant('quantity_delivery_date'),
      cellRenderer: DateRendererComponent,
      filter: 'agDateColumnFilter',
      filterParams: { closeOnApply: true } as IDateFilterParams,
      width: 200,
    },
    {
      field: 'binding_period_start',
      headerName: this.translateService.instant('binding_period_start'),
      cellRenderer: DateRendererComponent,
      filter: 'agDateColumnFilter',
      filterParams: { closeOnApply: true } as IDateFilterParams,
      width: 200,
    },
    {
      field: 'binding_period_end',
      headerName: this.translateService.instant('binding_period_end'),
      cellRenderer: DateRendererComponent,
      filter: 'agDateColumnFilter',
      filterParams: { closeOnApply: true } as IDateFilterParams,
      width: 200,
    },
    {
      field: 'comment',
      headerName: this.translateService.instant('comment'),
      width: 250,
    },
    {
      field: 'status',
      headerName: this.translateService.instant('status'),
      filter: 'agSetColumnFilter',
      hide: true,
    },
  ];
}
