import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { ButtonSize } from '../../gep-controls/models/button-size';

@Component({
  selector: 'gep-ag-grid-header',
  templateUrl: './ag-grid-header.component.html',
  styleUrls: ['./ag-grid-header.component.scss'],
})
export class AgGridHeaderComponent implements IHeaderAngularComp {
  public params!: IHeaderParams;

  public ascSort = 'inactive';
  public descSort = 'inactive';

  @ViewChild('menuButton', { read: ElementRef }) public menuButton!: ElementRef;

  agInit(params: IHeaderParams<any, any>): void {
    this.params = params;
    params.enableSorting = true;

    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );

    this.onSortChanged();
  }
  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onSortChanged() {
    this.ascSort = this.descSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    }
  }

  onSortRequested(order: 'asc' | 'desc' | null, event: any) {
    this.params.setSort(order, event.shiftKey);
    this.onSortChanged();
  }

  refresh(params: IHeaderParams) {
    return false;
  }

  protected readonly ButtonSize = ButtonSize;
}
