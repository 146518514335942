import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageModel } from '../models/page.model';
import { DealModel } from '../models/deal.model';
import { environment } from '../../environments/environment';
import { DealSearchSortOption } from '../modules/deal-search/model/deal-search-sort-option';

@Injectable({
  providedIn: 'root',
})
export class MemphisProducersService {
  constructor(private http: HttpClient) {}

  public findMemphisProducers(
    page = 0,
    page_size = 20,
    search: { [column: string]: string } = {},
    orderBy: DealSearchSortOption[]
  ): Observable<PageModel<DealModel>> {
    return this.http.get<PageModel<DealModel>>(
      `${
        environment.API
      }v1/deals?page=${page}&page_size=${page_size}&orderby=${this.stringifyOrderBy(
        orderBy
      )}&query=${encodeURI(
        JSON.stringify({ ...search, deal_type: 'MEMPHIS_BUY' })
      )}`
    );
  }

  public uploadMemphisProducersExcel(
    file: File
  ): Observable<{ uploaded_file: string; inserted: number; changed: number }> {
    const formData = new FormData();
    formData.append('xlsx_file', file);
    return this.http.post<{
      uploaded_file: string;
      inserted: number;
      changed: number;
    }>(environment.API + 'v1/import/memphis-producers', formData);
  }

  private stringifyOrderBy(orderBy: DealSearchSortOption[]) {
    return orderBy.map(x => x.field + ' ' + x.direction).join(',');
  }
}
