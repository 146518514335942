<gep-pagination-table-wrapper
  [dataRetriever]="dataRetriever"
  [selectedColumns]="selectedColumns"
  [availableColumns]="availableColumns"
  [defaultOrderBy]="defaultOrderBy"
>
</gep-pagination-table-wrapper>

<ng-template #customerTemplate let-column="column" let-row="row">
  <p>{{ row[column.name].name }}</p>
  <p>{{ row[column.name].address.city }}</p>
  <p>{{ row[column.name].address.number }}</p>
  <p>{{ row[column.name].address.street }}</p>
  <p>{{ row[column.name].address.zip }}</p>
</ng-template>

<ng-template #productTemplate let-column="column" let-row="row">
  <p>{{ row[column.name].key | translate }}</p>
  <p>{{ row[column.name].energy_source }}</p>
  <p>{{ row[column.name].origin }}</p>
  <p>{{ row[column.name].system_age }}</p>
</ng-template>
