<gep-date-picker
  [size]="size"
  [disabled]="disabled"
  (valueSelected)="onSelected('start', $event)"
></gep-date-picker>


{{ 'TO' | translate | lowercase }}

<gep-date-picker
  [size]="size"
  [disabled]="disabled"
  (valueSelected)="onSelected('end', $event)"
></gep-date-picker>
