<eon-ui-table-renderer
  content="
<table>
  <colgroup>
    <col />
    <col />
    <col />
  </colgroup>
  <thead>
    <tr>
      <th>{{ 'quantity' | translate }}</th>
      <th>{{ 'year' | translate }}</th>
      <th>{{ 'product' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ deal?.quantityInMWh ? deal?.quantityInMWh + ' MWh' :  deal?.quantityInTonns + ' t' }}</td>
      <td>{{ deal?.year }}</td>
      <td>{{ deal?.product_key }}</td>
    </tr>
  </tbody>
</table>"
>
</eon-ui-table-renderer>
