<eon-ui-modal
  [attr.close-button-text]="closeButtonText"
  [attr.close-button-rank]="closeButtonRank"
  [attr.copytext]="description"
  [attr.headline-text]="headline"
  [attr.modal-id]="modalId"
  [attr.visible]="visible"
  [attr.show-closing-x]="showClosingX"
  (modalClose)="clickCloseButton()"
>
  <ng-content></ng-content>
  <gep-button
    *ngIf="positiveButtonText"
    [label]="positiveButtonText"
    (click)="clickPositiveButton()"
    [style]="positiveButtonStyle"
    slot="button-right"
  ></gep-button>
</eon-ui-modal>
