import { Component } from '@angular/core';

@Component({
  selector: 'gep-psi-import-page',
  templateUrl: './psi-import-page.component.html',
  styleUrls: ['./psi-import-page.component.scss'],
})
export class PsiImportPageComponent {
  constructor() {}
}
