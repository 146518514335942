export enum AttributeDataType {
  STRING = 'STRING',
  DATETIME = 'DATETIME',
  BOOLEAN = 'BOOL',
  NUMBER = 'NUMBER',
  ENUM = 'ENUM',
  MULTISELECT = 'MULTISELECT',
}

export type DealAttribute = {
  type: AttributeDataType;
  fieldName: string;
  required: boolean;
  values?: string[];
};

export type AdminUpdateProperties = {
  dealAttributes: DealAttribute[];
  offerAttributes: DealAttribute[];
};
