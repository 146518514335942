<gep-settings-tab-header></gep-settings-tab-header>

<main class="d-flex flex-row w-100">
  <div class="d-flex w-100">
    <div style="flex: 1;" class="h-100 me-4">
      <h2>{{'settings.productConfig.selectProduct' | translate }}</h2>
      <gep-select-list *ngIf="products$ | async as products" [options]="products" (itemSelected)="selectProduct($event);" style="padding-right: 24px;"></gep-select-list>

      <eon-ui-button class="mt-2" [text]="'settings.productConfig.createNewProduct' | translate" (click)="createNewProduct()" fullWidth="true"></eon-ui-button>
    </div>
    <div style="flex: 4; border-left: 1px solid grey;" class="position-relative">
      <ng-container *ngIf="showForm else errorNoProductSelected">
        <eon-ui-button class="delete-product position-absolute" [icon]="Icon.Trash" [text]="'settings.productConfig.deleteProduct' | translate" (click)="showDeleteModal()" [disabled]="isNewProduct"></eon-ui-button>
        <h2>{{'settings.productConfig.editProduct' | translate }}</h2>
        <small class="d-block text-center"><i>{{'settings.mandatoryFields' | translate}}</i></small>
        <form [formGroup]="form">
          <fieldset>
            <gep-input [label]="'settings.productConfig.productKey' | translate"  [labelOutside]="true" [requiredStar]="true" formControlName="key"></gep-input>
            <gep-input [label]="'settings.productConfig.gdmNamefield' | translate" [labelOutside]="true" formControlName="price_gdm_namefield"></gep-input>
          </fieldset>

          <div style="padding: 12px;">
            <div formArrayName="labels" style="padding: 0.5rem;">
              <label>Labels</label>
              <table>
                <tr *ngFor="let label of labels.controls; index as i">
                  <td><gep-input [formControlName]="i" [label]="''" [labelOutside]="true"></gep-input></td>
                  <td><gep-button class="ms-lg-2" (click)="removeLabel(i)" [size]="ButtonSize.Small" [style]="Style.Highlight" [label]="'settings.productConfig.removeLabel' | translate"></gep-button></td>
                </tr>
              </table>
              <eon-ui-button [size]="ButtonSize.Small" [scheme]="Style.Gray" class="mt-2" (click)="addLabel()" [text]="'settings.productConfig.addLabel' | translate"></eon-ui-button>
            </div>
          </div>

          <fieldset>
            <gep-dropdown
              *ngIf="productService.getRegions$() | async as regions"
              [labelOutside]="true"
              [options]="regions"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [label]="'settings.productConfig.region' | translate"
              formControlName="region_id"
            ></gep-dropdown>
            <gep-radio-button-group
              [label]="'settings.productConfig.regionEditable' | translate"
              [requiredStar]="true"
              formControlName="region_updatable"
            >
              <gep-radio-button label="true" value="true"></gep-radio-button>
              <gep-radio-button label="false" value="false"></gep-radio-button>
            </gep-radio-button-group>
          </fieldset>

          <fieldset>
            <gep-dropdown
              *ngIf="productService.getSystemAges$() | async as systemAges"
              [options]="systemAges"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [labelOutside]="true"
              [label]="'settings.productConfig.systemAge' | translate"
              formControlName="system_age"
            ></gep-dropdown>
            <gep-radio-button-group
              [label]="'settings.productConfig.systemAgeEditable' | translate"
              [requiredStar]="true"
              formControlName="system_age_updatable"
            >
              <gep-radio-button label="true" value="true"></gep-radio-button>
              <gep-radio-button label="false" value="false"></gep-radio-button>
            </gep-radio-button-group>
          </fieldset>

          <fieldset>
            <gep-dropdown
              *ngIf="productService.getEnergySources$() | async as energySources"
              [options]="energySources"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [labelOutside]="true"
              [label]="'settings.productConfig.energySource' | translate"
              formControlName="energy_source"
            ></gep-dropdown>
            <gep-radio-button-group
              [label]="'settings.productConfig.energySourceEditable' | translate"
              [requiredStar]="true"
              formControlName="energy_source_updatable"
            >
              <gep-radio-button label="true" value="true"></gep-radio-button>
              <gep-radio-button label="false" value="false"></gep-radio-button>
            </gep-radio-button-group>
          </fieldset>


          <fieldset formGroupName="displayed_quantity">
            <gep-dropdown [label]="'settings.productConfig.displayedQuantityUnit' | translate" [options]="displayedQuantityUnits" [optionLabelTransformer]="OptionTransformers.transformStringOptions" [optionValueTransformer]="OptionTransformers.transformStringOptions" [labelOutside]="true" formControlName="unit"></gep-dropdown>
            <gep-number-input *ngIf="conversionFactorEnabled$ | async; else emptyField" [label]="'settings.productConfig.displayedQuantityFactor' | translate" [labelOutside]="true" formControlName="factor"></gep-number-input>
            <ng-template #emptyField><div></div></ng-template>
          </fieldset>

          <fieldset>
            <gep-number-input [label]="'settings.productConfig.position' | translate" [labelOutside]="true" formControlName="position"></gep-number-input>
            <gep-number-input [label]="'settings.productConfig.fixedPrice' | translate" [labelOutside]="true" formControlName="price_fixed"></gep-number-input>
          </fieldset>

          <fieldset>
            <gep-number-input [label]="'settings.productConfig.priceAddition' | translate" [labelOutside]="true" formControlName="price_addition"></gep-number-input>
            <gep-number-input [label]="'settings.productConfig.priceMultiplier' | translate" [labelOutside]="true" formControlName="price_multiplier"></gep-number-input>
          </fieldset>

          <div class="text-center">
            <eon-ui-button [text]="'settings.save' | translate" [disabled]="!this.form.valid"  (click)="saveProduct()"></eon-ui-button>
          </div>

        </form>
      </ng-container>

      <ng-template #errorNoProductSelected>
        <p style="margin: auto; width: 100%; text-align: center;">{{'settings.productConfig.noProductSelected' | translate}}</p>
      </ng-template>

      <gep-modal
        [visible]="deleteModalVisible"
        [headline]="'settings.productConfig.deleteModal.headline' | translate"
        [description]="'settings.productConfig.deleteModal.question' | translate"
        [positiveButtonStyle]="Style.Highlight"
        [closeButtonText]="'no' | translate"
        [positiveButtonText]="'yes' | translate"
        (positiveButtonClicked)="deleteProduct()"
        (closedButtonClicked)="deleteModalVisible = false;"
        [showClosingX]="true"
        modalId="delete-product-modal"
      ></gep-modal>
    </div>
  </div>
</main>
