import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BatchCancellationId } from '../models/batch-cancellation-id.model';

@Injectable({
  providedIn: 'root',
})
export class BatchIdService {
  constructor(private http: HttpClient) {}

  public getBatchId(): Observable<BatchCancellationId> {
    return this.http.get<BatchCancellationId>(
      `${environment.API}v1/deals/cancellations/batch-id`
    );
  }
}
